import React, { useState } from "react";
import { Button } from "@mui/material";
import { useDispatch } from "react-redux";
import {
  unachivedAccountDetail,
  achivedAccountDetail,
  viewAccountDetail,
} from "../../../redux/features/verifyPAN/verifyPAN";
import {
  beneTxnWaitingApproval,
  beneTxnAccountDetail,
  beneTxnViewAllAcc,
} from "../../../redux/features/beneficiary/beneTxnViewAccData/beneTxnViewAccData";
import { checkUsertoken } from "../../../redux/features/login/login";
import whiteLoader from "../../../assets/Approve transactions/WhiteLoaderImages.png";
import { toast, Bounce } from "react-toastify";
import { toast_position } from "../../../redux/features/Settings/Authorization";
import "./index.css";
import xpentraloader from "../../../assets/Beneficiary Icons/lodemore.gif";
import DeclinedPopup from "../../../containers/Accounts/BeneficiaryAccount/BeneAcountApproval/DeclinedPopup";
import RefreshToast from "../../_utils/RefreshToast";
import { useNavigate } from "react-router-dom";

const BeneDetailModal = ({
  close,
  id,
  type,
  message,
  note,
  title,
  accountData,
  updateAllAccounts,
  setRejectTxnPopup,
  setAchivedOpenPop,
  onClose,
  accountId,
  allAccounts,
  createEdit,
  txn_single_appr,
}) => {
  const dispatch = useDispatch();
  const [isButtonDisable, setIsButtonDisable] = useState(false);
  const [showLoader, setShowLoader] = useState(false);
  const Navigate = useNavigate();

  const handleConfirm = async () => {
    try {
      let thunkAction;
      switch (type) {
        case "approve transaction":
          const loginid = JSON.parse(localStorage.getItem("response"));
          thunkAction = beneTxnWaitingApproval;
          dispatch(checkUsertoken());
          const res = await dispatch(thunkAction(id));

          if (
            res?.payload?.status === false ||
            res.type === "beneTxnWaitingApproval/rejected"
          ) {
            let errorMessage = "";
            const updatedData = accountData?.map((item) => {
              if (item.txn_id === id) {
                if (
                  res?.payload?.errors?.base[0]?.errors &&
                  res?.payload?.errors?.base[0]?.errors[0]?.reason[0]
                ) {
                  errorMessage = res.payload.errors.base[0].errors[0].reason[0];
                } else if (
                  res?.payload?.errors?.base[0]?.errors &&
                  res?.payload?.errors?.base[0]?.errors[0]?.reason
                ) {
                  errorMessage = res.payload.errors.base[0].errors[0].reason;
                } else if (res?.payload?.errors?.base[0]?.reason[0]) {
                  errorMessage = res?.payload?.errors?.base[0]?.reason[0];
                } else if (res?.payload?.errors?.base[0]) {
                  errorMessage = res.payload.errors.base[0];
                }
                return {
                  ...item,
                  error:
                    item.error === false && res?.payload?.status === false
                      ? true
                      : item.error,
                  message:
                    item.message === "" && res?.payload?.status === false
                      ? errorMessage
                      : item.message,
                };
              }
              return item;
            });
            if (errorMessage) {
              toast.error(errorMessage, toast_position);
              setShowLoader(false);
            }
            updateAllAccounts(updatedData);
          } else {
            let newCycleStatus = "";
            //normal approval flow
            const updatedData = accountData.map((item) => {
              if (item.txn_id === id) {
                if (
                  res?.payload?.status === true &&
                  res?.payload?.transactions[0]?.lifecycle_status === "approved"
                ) {
                  newCycleStatus =
                    res?.payload?.transactions[0]?.lifecycle_status;
                } else if (
                  //dual approval flow
                  res?.payload?.status === true &&
                  res?.payload?.transactions[0]?.lifecycle_status === "pending"
                ) {
                  newCycleStatus = "pendingOne";
                }
                return {
                  ...item,
                  last_updated_status:
                    item.last_updated_status === "Created" &&
                    res?.payload?.status === true &&
                    res?.payload?.transactions[0]?.last_updated_status ===
                      "Approved"
                      ? "Approved"
                      : "Created",

                  lifecycle_status:
                    res?.payload?.status === true &&
                    item.lifecycle_status === "pending"
                      ? newCycleStatus
                      : "",

                  last_updated_user_id:
                    res?.payload?.status === true &&
                    res?.payload?.transactions[0]?.last_updated_user_id
                      ? res?.payload?.transactions[0]?.last_updated_user_id
                      : item.last_updated_user_id,

                  last_updated_user_id_check:
                    item.last_updated_user_id_check === false &&
                    res?.payload?.status === true &&
                    loginid?.employees?.id ===
                      res?.payload.transactions[0].last_updated_user_id
                      ? true
                      : false,

                  distribution_lifecycle_status:
                    res?.payload?.transactions[0]
                      ?.distribution_lifecycle_status || "",
                };
              }
              return item;
            });
            toast.success("Transaction approved", toast_position);
            updateAllAccounts(updatedData);

            if (txn_single_appr === "txn_single_approval") {
              Navigate(-1);
            }
          }

          close(false);

          // await dispatch(beneTxnAccountDetail(id));

          break;
        case "archived":
          thunkAction = achivedAccountDetail;
          dispatch(checkUsertoken());
          RefreshToast("archive");
          const archived_response = await dispatch(
            thunkAction({ id, Navigate })
          );
          if (
            archived_response.type !== "achivedAccountDetail/rejected" ||
            archived_response.payload === "Beneficiary account already archived"
          ) {
            dispatch(checkUsertoken());
            await dispatch(viewAccountDetail({ id }));
          }

          close(false);
          break;

        case "unarchived":
          thunkAction = unachivedAccountDetail;
          dispatch(checkUsertoken());
          RefreshToast("unarchive");
          const unarchived_response = await dispatch(
            thunkAction({ id, Navigate })
          );
          if (
            unarchived_response.type !== "unachivedAccountDetail/rejected" ||
            unarchived_response.payload ===
              "Beneficiary account already pending"
          ) {
            dispatch(checkUsertoken());
            await dispatch(viewAccountDetail({ id }));
          }
          close(false);
          break;
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const handleRejectTransactionChecker = async () => {
    setShowLoader(true);
    let queryParams = {
      page_no: 1,
      page_item_count: 50,
      lifecycle_status: "pending",
      beneficiary_account_id: id,
    };
    dispatch(checkUsertoken());
    // RefreshToast("archive");
    const response = await dispatch(beneTxnViewAllAcc(queryParams));
    const data = response?.payload?.transactions;

    if (data?.length > 0 && response?.payload?.status) {
      setRejectTxnPopup(true);
      setAchivedOpenPop(false);
    } else {
      handleConfirm();
    }
  };

  return (
    <>
      {type == "Reject-All-Transaction" ? (
        <>
          <div className="fixed top-0 left-0 w-full h-full bg-custom-backdrop bg-opacity-80 flex items-center justify-center z-[9998]">
            <DeclinedPopup
              status={type}
              onClose={onClose}
              accountId={accountId}
              archive_id={id}
              allAccounts={allAccounts}
              updateAllAccounts={updateAllAccounts}
              setRejectTxnPopup={setRejectTxnPopup}
              createEdit={createEdit}
              createArchive={"createArchive"}
            />
          </div>
        </>
      ) : (
        <>
          <div
            className="flex justify-center items-center m-auto top-1/2 left-1/2 fixed z-[9999] p-3"
            style={{ transform: "translate(-50%, -50%)" }}
          >
            <div
              className="h-fit w-70% flex flex-col gap-2 py-4 px-2 bg-custom-appBackLight rounded-2xl"
              // style={{ width: "400px", maxWidth: "600px" }}
              style={{
                width: window.innerWidth <= 768 ? "370px" : "400px",
                maxWidth: "600px",
              }}
            >
              <div className="mx-4">
                <div>
                  <h6 className="text-custom-orange pb-1 md:font-bold">
                    {title}
                  </h6>
                </div>
                <div className="flex items-center pb-2"></div>
                <div className={`${!title ? "mb-3" : ""} `}>
                  <span
                    className="text-white text-sm"
                    style={{ wordWrap: "break-word" }}
                  >
                    {message.split("\n").map((line, index) => (
                      <>
                        <p key={index}>{line}</p>
                        <br />
                      </>
                    ))}
                  </span>
                  {note ? (
                    <span className="text-xs text-[#C9C9C9] font-sans">
                      Note - {note}
                    </span>
                  ) : null}
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    gap: "6%",
                    margin: "0px 50px",
                    width: "100%",
                  }}
                  className={`${
                    title === "Transaction Approval" ? null : "pt-4"
                  } px-4 m-4`}
                >
                  {!showLoader && (
                    <Button
                      onClick={() => {
                        close(false);
                      }}
                      sx={{
                        borderColor: "none",
                        color: "#fff",
                        textTransform: "capitalize",
                        textDecoration: "underline",
                      }}
                    >
                      Cancel
                    </Button>
                  )}
                  {showLoader ? (
                    <>
                      <button className="expentra-Loader-class">
                        <img src={xpentraloader} alt="Loading..." />
                      </button>
                    </>
                  ) : (
                    <>
                      <Button
                        disabled={isButtonDisable}
                        sx={{
                          borderRadius: "20px",
                          color: "#fff !important",
                          boxShadow: "0px 3px 6px #00000033",
                          textTransform: "capitalize",
                          backgroundColor: "#69B76F",
                          border: "1px solid transparent",
                          "&:hover": {
                            backgroundColor: "#69B76F",
                          },
                          marginRight: "30px",
                          width: "110px",
                        }}
                        onClick={() => handleRejectTransactionChecker()}
                      >
                        Confirm
                      </Button>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default BeneDetailModal;
