import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  beneCategories,
  beneWaitingApproved,
} from "../../../redux/features/beneficiary/beneAllVerification/beneAllVerification";
import ArrowDown from "../../../assets/OwnerAccount/arrowdown5.png";

import cancel from "../../../assets/TransactionDashboardIcons/cancel.png";
import search from "../../../assets/ViewAllTransactions/search-white.png";
import arrowforward from "../../../assets/Beneficiary Icons/arrowForward.png";
import "./index.css";
import allChecker from "../../../assets/Beneficiary Icons/whitecheckmark.png";
import DatePickerComponent from "../../_utils/Calendar";
import { tooltipClasses, Tooltip, Button } from "@mui/material";
import BeneTxnViewModal from "../BeneTxnViewModal";
import { checkUsertoken } from "../../../redux/features/login/login";
import { ViewAgenda } from "@mui/icons-material";
import { toast } from "react-toastify";
import { toast_position } from "../../../redux/features/Settings/Authorization";

function BeneTxnFilterSidebar({
  ownerDetail,
  typepop,
  isOpen,
  onClose,
  sendCategory,
  setPage,
  setIsSidebarOpen,
  sendDateRange,
  initCategory,
  setInitCategory,
  sendPaymentMethod,
  setStartDateFilter,
  setEndDateFilter,
  startDate,
  setStartDate,
  endDate,
  setEndDate,
  setPaymentMethod,
  paymentMethod,
  setSelectedBeneAccId,
  beneAccountId,
  setBeneAccountId,
  accountName,
  setAccountName,
  showDatePicker,
  setShowDatePicker,
  setIsFilterApplied,
  categoryDropdownRef,
  settlementDropdownRef,
  paymentDropdownRef,
  dateRef,
  dropdownOpen,
  setDropdownOpen,
  isDropdownPaymentOpen,
  setDropdownPaymentOpen,
  isDropdownOpen,
  setIsDropdownOpen,
  handleOpenViewModal,
  isViewModalOpen,
  viewModalRef,
  setTxnPayLoad,
  txnPayLoad,
  handleOpenViewModalOwner,
  selectedOwnerAccId,
  selectedBeneAccId,
  setSelectedOwnerAccId,
  setAccountNumber,
  txnAmountFilter,
  setTxnAmountFilter,
  startDateFilter,
  endDateFilter,
  setTempEndDate,
  setTempStartDate,
  handleFilterDateRange,
  tempEndDate,
  tempStartDate,
  currentStatus,
  setCurrentStatus,
  isPaymentMethod,
  currentCategory,
  filterRef,
}) {
  const [settlementSearch, setSettlementSearch] = useState("");
  const [categorySearch, setCategorySearch] = useState("");
  const [sideDrawer, setSideDrawer] = useState(true);
  const [BeneTxnSideDrawerLoader, SetbeneTxnSideDrawerLoder] = useState(false);
  const [DrawerData, setDrawerData] = useState({});
  const [showDeclinedPopup, setshowDeclinedPopup] = useState(false);
  const [deniedTxnId, SetdeniedTxnId] = useState(null);
  const [refreshData, setRefreshData] = useState(false);
  const [AllBeneTxnAccounts, setAllBeneTxnAccounts] = useState([]);
  const [refreshId, SetrefreshId] = useState("");
  const [scrollAmount, setScrollAmount] = useState(0);
  const [isDateConfirmed, setIsDateConfirmed] = useState(false);

  const [activeSection, setActiveSection] = useState(null);

  const onChange = (dates) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
  };

  const categories = useSelector(
    (state) => state?.beneAllVerification.beneTransCategories?.categories
  );

  const dispatch = useDispatch();

  // const settlementDropdownRef = useRef(null);
  const searchInputRef = useRef(null);
  const searchRef = useRef(null);
  const yearDropdownRef = useRef(null);

  useEffect(() => {
    dispatch(checkUsertoken());
    dispatch(beneCategories());
  }, [dispatch]);

  const handleClick = (name) => {
    setInitCategory(name);
    setPage(1);
  };
  const handleSettlementClick = (settlement) => {
    setPaymentMethod(settlement == "All" ? "" : settlement);
    setPage(1);
  };
  const {
    viewAccountDetailData,
    viewAccountDetailLoading,
    achivedDataDetail,
    unachivedLoadingDetail,
    achivedLoadingDetail,
    unachivedDataDetail,
  } = useSelector((state) => state.verify);
  const { beneTxnAccountLoading, beneTxnAccountData } = useSelector(
    (state) => state?.beneTxnViewAccData
  );

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dateRef.current && !dateRef.current.contains(event.target)) {
        setShowDatePicker(false);
      }
      if (
        categoryDropdownRef.current &&
        !categoryDropdownRef.current.contains(event.target)
      ) {
        setDropdownOpen(false);
      }
      if (
        settlementDropdownRef.current &&
        !settlementDropdownRef.current.contains(event.target)
      ) {
        setIsDropdownOpen(false);
      }
      if (
        paymentDropdownRef.current &&
        !paymentDropdownRef.current.contains(event.target)
      ) {
        setDropdownPaymentOpen(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [categoryDropdownRef, settlementDropdownRef, dateRef, paymentDropdownRef]);

  useEffect(() => {
    if (viewAccountDetailData) {
      setDrawerData(viewAccountDetailData?.beneficiary_accounts[0]);
    }
  }, [viewAccountDetailData]);
  const handleScroll = (scrollAmount) => {
    window.scrollBy({
      top: scrollAmount,
      behavior: "smooth",
    });
  };
  useEffect(() => {
    const handleKeyDown = (e) => {
      if (e.keyCode === 87) {
        setScrollAmount(-30);
      } else if (e.keyCode === 83) {
        setScrollAmount(30);
      }
    };

    const handleKeyUp = () => {
      setScrollAmount(0);
    };

    document.addEventListener("keydown", handleKeyDown);
    document.addEventListener("keyup", handleKeyUp);

    const scrollInterval = setInterval(() => {
      if (scrollAmount !== 0) {
        handleScroll(scrollAmount);
      }
    }, 10);

    return () => {
      document.removeEventListener("keydown", handleKeyDown);
      document.removeEventListener("keyup", handleKeyUp);
      clearInterval(scrollInterval);
    };
  }, [scrollAmount]);
  const handleConfirm = () => {
    setShowDatePicker(false);
    setIsDateConfirmed(true);
  };

  const handleCancel = () => {
    setShowDatePicker(false);
    setIsDateConfirmed(false);
    setStartDate(null);
    setEndDate(null);
  };

  const clearFilter = () => {
    setInitCategory("");
    // setIsPaymentMethod("");
    setPaymentMethod("");
    setIsDropdownOpen(false);
    setCategorySearch("");
    setSettlementSearch("");
    // setIsSidebarOpen(false);
    setShowDatePicker(false);
    setStartDate(null);
    setEndDate(null);
    setTempEndDate("");
    setTempStartDate("");
    sendPaymentMethod("");
    sendCategory("");
    setStartDateFilter("");
    setEndDateFilter("");
    setTxnAmountFilter("");
    if (typepop !== "transactionHistorypopup" || ownerDetail) {
      setSelectedBeneAccId("");
      setBeneAccountId("");
      setAccountName("");
    }
    setIsFilterApplied(
      typepop === "transactionHistorypopup" || ownerDetail ? true : false
    );
    if (!ownerDetail) {
      setSelectedOwnerAccId("");
    }
    setTxnPayLoad({
      txnPayLoad,
      amount: "",
      ownerAccountName: "",
      ownerAccountId: ownerDetail ? txnPayLoad?.ownerAccountId : "",
      ownerbankAccountIFSC: "",
      ownerbankAccountNo: ownerDetail ? txnPayLoad?.ownerbankAccountNo : "",
    });
  };
  const convertDate = (dateString) => {
    const date = new Date(dateString);
    const timestamp = Math.floor(date.getTime() / 1000);

    return timestamp;
  };
  const filterCategory = () => {
    sendCategory(initCategory);
    setSelectedOwnerAccId(txnPayLoad?.ownerAccountId);
    setAccountNumber(txnPayLoad?.ownerbankAccountNo);

    setTxnAmountFilter(txnPayLoad?.amount);
    setPage(1);

    handleFilterDateRange(convertDate(startDate), convertDate(endDate));
    setTempStartDate(startDateFilter);

    setTempEndDate(endDateFilter);
    sendPaymentMethod(paymentMethod);
    setSelectedBeneAccId(beneAccountId);
    // setTxnAmount(txnAmount);
    setShowDatePicker(false);
    setIsSidebarOpen(false);
  };

  const handleTxnSearch = (text) => {
    const sanitizedText = text
      .replace(/[^0-9.]/g, "")
      .replace(/(\..*)\./g, "$1");

    if (sanitizedText === "") {
      if (txnAmountFilter.length === 0) {
        setTxnAmountFilter("");
      }
      setTxnPayLoad({
        ...txnPayLoad,
        amount: "",
      });
      return;
    }

    const amount = parseFloat(sanitizedText);
    if (!isNaN(amount)) {
      setTxnPayLoad({
        ...txnPayLoad,
        amount: sanitizedText,
      });
    }
  };

  const filteredSettlements = ["All", "NEFT", "RTGS"].filter((settlement) =>
    settlement.toLowerCase().includes(settlementSearch.toLowerCase())
  );
  const handleCategorySearchChange = (event) => {
    setCategorySearch(event.target.value);
  };

  const filteredCategories = categories.filter((category) =>
    category.name.toLowerCase().includes(categorySearch.toLowerCase())
  );
  const additional_Data = beneTxnAccountData?.transaction[0]?.additional_data;

  const handleApproval = async (id) => {
    SetbeneTxnSideDrawerLoder(true);
    dispatch(checkUsertoken());
    const data = await dispatch(beneWaitingApproved(id));

    if (data) {
      const updatedData = viewAccountDetailData?.beneficiary_accounts?.map(
        (item) => {
          if (item.id === id) {
            return {
              ...item,
              approval_status:
                // item.approval_status === "approved" ? "pending" : "approved",
                item.approval_status === "pending" ? "approved" : "pending",
              status: data?.payload.status,
            };
          }
          return item;
        }
      );
      setDrawerData(updatedData[0]);
    }

    setTimeout(() => {
      SetbeneTxnSideDrawerLoder(false);
      setRefreshData(true);
    }, 2000);

    setTimeout(() => {
      if (data.payload.status === true) {
        window.location.reload();
      }
    }, 2000);
  };

  useEffect(() => {
    setRefreshData(false);
  }, [refreshData, dispatch]);

  const handleDeclinedTxn = (id) => {
    setshowDeclinedPopup(true);
    SetdeniedTxnId(id);
  };
  const handleClosePopup = () => {
    setshowDeclinedPopup(false);
  };

  const handleSettlementSearchChange = (event) => {
    setSettlementSearch(event.target.value);
  };

  const toggleDrawer = (open) => (event) => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    onClose(open);
  };

  const formatDate = (date) => {
    return `${date.getDate()} ${date.toLocaleString("default", {
      month: "short",
    })}, ${date.getFullYear()}`;
  };
  const handleDateChange = (dates) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
    setIsDateConfirmed(false);
    console.log(startDate, endDate);
  };

  const [isMobile, setIsMobile] = useState(window.innerWidth <= 800);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 800);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [window.innerWidth]);

  const handleFilterApply = (type) => {
    if (
      (initCategory ||
        paymentMethod ||
        txnPayLoad?.amount.length > 0 ||
        txnAmountFilter.length > 0 ||
        txnPayLoad?.ownerAccountId ||
        beneAccountId ||
        currentCategory?.length > 0 ||
        isPaymentMethod?.length > 0 ||
        selectedOwnerAccId?.length > 0 ||
        selectedBeneAccId?.length > 0) &&
      startDate &&
      endDate &&
      isDateConfirmed
    ) {
      return true;
    }
    if (initCategory && !startDate && !endDate) {
      return true;
    }
    if (txnPayLoad?.amount.length > 0 && !startDate && !endDate) {
      return true;
    }
    if (txnAmountFilter.length > 0 && !startDate && !endDate) {
      return true;
    }
    if (txnPayLoad?.ownerAccountId && !startDate && !endDate) {
      return true;
    }
    if (beneAccountId && !startDate && !endDate) {
      return true;
    }
    if (currentCategory?.length > 0 && !startDate && !endDate) {
      return true;
    }
    if (paymentMethod && !startDate && !endDate) {
      return true;
    }
    if (isPaymentMethod?.length > 0 && !startDate && !endDate) {
      return true;
    }
    if (selectedOwnerAccId?.length > 0 && !startDate && !endDate) {
      return true;
    }
    if (selectedBeneAccId?.length > 0 && !startDate && !endDate) {
      return true;
    }
    if (startDateFilter && endDateFilter) {
      return true;
    }

    if (startDate && endDate && isDateConfirmed) {
      return true;
    }

    return false;
  };

  return (
    <>
      {isMobile ? (
        <>
          <div
            // onClose={toggleSidebar(false)}
            className={`beneTxnviewallsidebar ${isOpen ? "open" : "closed"}`}
            style={{
              backgroundColor: "#314c7f",
              pointerEvents: isViewModalOpen ? "none" : "",
              marginTop: "160px",
            }}
          >
            <div
              style={{
                height: "100vh",
                overflow: "hidden",
                backgroundColor: "#314c7f",
              }}
            >
              <div
                className="beneTxnviewallFilterDiv px-5"
                style={{
                  visibility: isOpen ? "visible" : "hidden",
                  transition: `opacity 0.2s ease-in ${isOpen ? "0s" : "0.2s"}`,
                  opacity: isOpen ? "1" : "0",
                  borderTop: "2px solid rgb(29, 58, 109)",
                  flexDirection: "column",
                  justifyContent: "unset",
                  // height: "65vh",
                  height: "72vh",
                  overflowY: "auto",
                }}
              >
                <span className="text-[#F05A6A] font-normal">Filter </span>
                <div className="relative w-[100%]">
                  <span
                    style={{
                      color: "#C9C9C9",
                      width: "35px",
                      height: "19px",
                      font: "normal normal 12px/19px Open Sans",
                    }}
                  >
                    Beneficiary account
                  </span>
                  <div
                    ref={viewModalRef}
                    className="relative flex flex-col items-center rounded-xl h-[35px] pl-[12px] pr-[7px] py-2.5 bg-[#667CA3] w-[100%] mt-[5px]"
                    style={{
                      opacity:
                        currentStatus === "require approval" ? "0.5" : "1",
                      cursor:
                        currentStatus === "require approval"
                          ? "default"
                          : "pointer",
                    }}
                  >
                    <button
                      className="flex items-center justify-between border-transparent active:border-white w-full mt-[-1px]"
                      onClick={(e) => {
                        e.stopPropagation();
                        handleOpenViewModal();
                      }}
                      disabled={
                        currentStatus === "require approval" ? true : false
                      }
                      style={{
                        cursor:
                          currentStatus === "require approval"
                            ? "default"
                            : "pointer",
                      }}
                    >
                      <span
                        className="bene_txn_filter_acc_selected text-xs truncate"
                        style={{ color: accountName ? "#f9f9f9" : "#CFCFCF" }}
                      >
                        {accountName === ""
                          ? "Select beneficiary account"
                          : accountName
                              .toLowerCase()
                              .split(" ")
                              ?.map(
                                (word) =>
                                  word.charAt(0).toUpperCase() + word.slice(1)
                              )
                              .join(" ")}
                      </span>
                      <img
                        className="txn_viewall_select_account h-[11px] w-[11px]"
                        src={ArrowDown}
                        alt=""
                      />
                    </button>
                  </div>
                </div>
                {!isDropdownOpen && (
                  <div
                    className="relative w-[100%] "
                    style={{ height: "64px" }}
                  >
                    <span
                      style={{
                        color: "#C9C9C9",
                        width: "35px",
                        height: "19px",
                        font: "normal normal 12px/19px Open Sans",
                      }}
                    >
                      Payment method
                    </span>

                    <div
                      className="bene_txn_payment_select mt-[10px]"
                      ref={paymentDropdownRef}
                      onClick={(e) => {
                        setDropdownPaymentOpen(!isDropdownPaymentOpen);
                        setIsDropdownOpen(false);
                        setShowDatePicker(false);
                        e.stopPropagation();
                      }}
                      style={{
                        width: "100%",
                        height: isDropdownPaymentOpen ? "160px" : "35px",
                        outline: isDropdownPaymentOpen
                          ? "1px solid #E25869"
                          : "",
                        transition: isDropdownPaymentOpen ? "none" : "",
                        opacity:
                          currentStatus === "require approval" ? "0.5" : "1",
                      }}
                      disabled={
                        currentStatus === "require approval" ? true : false
                      }
                    >
                      <div className="bene_txn_category_select_select">
                        <div
                          className="txn_category_select_scroll_line"
                          style={{
                            display: isDropdownPaymentOpen ? "block" : "none",
                            width: "97%",
                          }}
                        ></div>

                        <span
                          style={{
                            fontSize: "12px",
                            paddingLeft: isDropdownPaymentOpen ? "9px" : "15px",
                            color: paymentMethod ? "#f9f9f9" : "#dadada",
                          }}
                        >
                          {paymentMethod === "" ? "Select type" : paymentMethod}
                        </span>
                        <img
                          src={ArrowDown}
                          alt="down"
                          style={{
                            transform: isDropdownPaymentOpen
                              ? "rotate(180deg)"
                              : "rotate(0deg)",
                          }}
                        />
                      </div>

                      {isDropdownPaymentOpen && (
                        <div className="bg-[#667CA3] absolute top-12 flex flex-col items-start rounded-[10px] w-full h-[82px] px-1">
                          {["All", "NEFT", "RTGS"]?.map((settlement) => (
                            <div
                              key={settlement}
                              className="flex w-full h-[35px] hover:bg-[#506994] cursor-pointer rounded-[5px] border-l-transparent"
                              style={{
                                backgroundColor:
                                  paymentMethod === settlement && paymentMethod
                                    ? "#3A5686"
                                    : "",
                                padding: "0px 5px",
                              }}
                              onClick={(e) => {
                                handleSettlementClick(settlement);
                                setIsDropdownOpen(false);
                                // e.stopPropagation();
                              }}
                            >
                              <span className="pl-2 py-2.5 text-[#dadada] text-xs truncate w-[90%] ">
                                {settlement}
                              </span>
                              <div
                                style={{
                                  display:
                                    paymentMethod == settlement
                                      ? "flex"
                                      : "none",
                                  textAlign: "right",
                                  justifyContent: "end",
                                  paddingRight: "1px",
                                  marginTop: "10px",
                                }}
                              >
                                <img
                                  style={{
                                    height: "13px",
                                    width: "14px",
                                  }}
                                  src={allChecker}
                                  alt="check"
                                />
                              </div>
                            </div>
                          ))}
                        </div>
                      )}
                    </div>
                  </div>
                )}
                {!isDropdownOpen && (
                  <div className="relative w-[100%]" style={{ height: "64px" }}>
                    <span
                      style={{
                        color: "#C9C9C9",
                        width: "35px",
                        height: "19px",
                        font: "normal normal 12px/19px Open Sans",
                      }}
                    >
                      Beneficiary category
                    </span>

                    <div
                      className="bene_txn_category_select mt-[10px]"
                      ref={categoryDropdownRef}
                      onClick={(e) => {
                        setDropdownOpen(!dropdownOpen);
                        setIsDropdownOpen(false);
                        setShowDatePicker(false);
                        e.stopPropagation();
                      }}
                      style={{
                        width: "100%",
                        height: dropdownOpen ? "198px" : "35px",
                        outline: dropdownOpen ? "1px solid #E25869" : "",
                        transition: dropdownOpen ? "none" : "",
                        position: "relative",
                      }}
                    >
                      <div className="bene_txn_category_select_select">
                        <div
                          className="txn_category_select_scroll_line"
                          style={{
                            display: dropdownOpen ? "block" : "none",
                            width: "97%",
                          }}
                        ></div>

                        <span
                          style={{
                            fontSize: "12px",
                            paddingLeft: dropdownOpen ? "9px" : "15px",
                            color: initCategory ? "#f9f9f9" : "#dadada",
                          }}
                        >
                          {initCategory || "Select category"}
                        </span>
                        <img
                          src={ArrowDown}
                          alt="down"
                          style={{
                            transform: dropdownOpen
                              ? "rotate(180deg)"
                              : "rotate(0deg)",
                          }}
                        />
                      </div>

                      {dropdownOpen && (
                        <div
                          className="txn_category_select_scroll"
                          style={{ width: "100%" }}
                        >
                          <div
                            className="bene_input_div_element"
                            style={{
                              width: "97%",
                              justifyContent: "start",
                              position: "relative",
                            }}
                          >
                            <img
                              src={search}
                              alt="search"
                              className="search_owner_element"
                              onClick={(e) => {
                                e.stopPropagation();
                                searchRef.current.focus();
                              }}
                            />
                            <input
                              ref={searchRef}
                              type="text"
                              value={categorySearch}
                              onChange={handleCategorySearchChange}
                              placeholder="Search"
                              onClick={(e) => e.stopPropagation()}
                              className="placeholder-[#dadada] text-xs"
                            />
                            <img
                              src={cancel}
                              alt="cancel"
                              className="search_owner_element"
                              onClick={(e) => {
                                e.stopPropagation();
                                setCategorySearch("");
                              }}
                              style={{
                                cursor: "pointer",
                                position: "absolute",
                                right: "10px",
                              }}
                            />
                          </div>

                          {categorySearch === "" ? (
                            <div
                              className="bene_txn_sort_by_category_scroll"
                              style={{
                                width: "100%",
                                alignItems: "start",
                                paddingLeft: "10px",
                              }}
                            >
                              {categories?.map((category) => (
                                <div
                                  key={category.id}
                                  className={
                                    initCategory === category?.name
                                      ? "selected-category rounded-[5px] w-full"
                                      : "category-wrapper w-full"
                                  }
                                >
                                  <Tooltip
                                    key={category.id}
                                    componentsProps={{
                                      popper: {
                                        sx: {
                                          [`& .${tooltipClasses.tooltip}`]: {
                                            backgroundColor: (theme) =>
                                              theme.palette.common.black,
                                          },
                                        },
                                      },
                                    }}
                                    title={category.name}
                                  >
                                    <button
                                      className="bene_txn_category_select_scroll_com"
                                      onClick={(e) => {
                                        handleClick(category.name);
                                        // e.stopPropagation();
                                      }}
                                    >
                                      <span className="categoryTxnname">
                                        {category?.name}
                                      </span>

                                      <div
                                        style={{
                                          display:
                                            initCategory == category?.name
                                              ? "flex"
                                              : "none",
                                          textAlign: "right",

                                          position: "absolute",
                                          right: "8px",

                                          paddingRight: "1px",
                                        }}
                                      >
                                        <img
                                          style={{
                                            height: "13px",
                                            width: "14px",
                                          }}
                                          src={allChecker}
                                          alt="check"
                                        />
                                      </div>
                                    </button>
                                  </Tooltip>
                                </div>
                              ))}
                            </div>
                          ) : (
                            <div
                              className="bene_txn_filter_by_category_scroll"
                              style={{
                                width: "100%",
                                alignItems: "start",
                                paddingLeft: "10px",
                              }}
                            >
                              {filteredCategories?.map((category) => (
                                <div
                                  key={category.id}
                                  className="category-wrapper"
                                >
                                  <Tooltip
                                    key={category.id}
                                    componentsProps={{
                                      popper: {
                                        sx: {
                                          [`& .${tooltipClasses.tooltip}`]: {
                                            backgroundColor: (theme) =>
                                              theme.palette.common.black,
                                          },
                                        },
                                      },
                                    }}
                                    title={category.name}
                                  >
                                    <button
                                      className="bene_txn_category_select_scroll_com"
                                      onClick={() => {
                                        handleClick(category.name);
                                      }}
                                    >
                                      {category.name}
                                    </button>
                                  </Tooltip>
                                </div>
                              ))}
                            </div>
                          )}
                        </div>
                      )}
                    </div>
                  </div>
                )}
                <div className="relative w-[100%]" style={{ height: "64px" }}>
                  <span
                    style={{
                      color: "#C9C9C9",
                      width: "35px",
                      height: "19px",
                      font: "normal normal 12px/19px Open Sans",
                    }}
                  >
                    Source account
                  </span>
                  <div
                    className="relative flex flex-col items-center rounded-xl h-[35px] pl-[12px] pr-[7px] py-2.5 bg-[#667CA3] w-[100%] mt-[5px]"
                    style={{
                      opacity:
                        currentStatus === "require approval" || isMobile
                          ? "0.5"
                          : "1",
                    }}
                  >
                    <button
                      className="flex items-center justify-between border-transparent active:border-white w-full mt-[-1px] cu"
                      onClick={() => {
                        toast.info("Coming Soon...", toast_position);
                      }}
                      disabled={
                        currentStatus === "require approval" ? true : false
                      }
                      // style={{
                      //   cursor:
                      //     currentStatus === "require approval"
                      //       ? "default"
                      //       : "pointer",
                      // }}
                    >
                      <span
                        className="bene_txn_filter_acc_selected text-xs truncate"
                        style={{
                          color: txnPayLoad?.ownerbankAccountNo
                            ? "#f9f9f9"
                            : "#CFCFCF",
                        }}
                      >
                        {txnPayLoad?.ownerbankAccountNo === ""
                          ? "Select source account"
                          : txnPayLoad?.ownerbankAccountNo}
                      </span>
                      <img
                        className="txn_viewall_select_account h-[11px] w-[11px]"
                        src={ArrowDown}
                        alt=""
                      />
                    </button>
                  </div>
                </div>
                <div
                  className={`relative w-[100%]`}
                  style={{
                    height: "64px",
                  }}
                >
                  <span
                    style={{
                      color: "#C9C9C9",
                      width: "35px",
                      height: "19px",
                      font: "normal normal 12px/19px Open Sans",
                    }}
                  >
                    Amount
                  </span>

                  <div className="h-[35px] px-4 py-2.5 rounded-xl bg-[#667CA3] text-[#F9F9F9] placeholder-[#DADADA] text-xs mt-[5px] w-[100%]">
                    &#8377;
                    <input
                      className="bg-[#667CA3] text-[#F9F9F9] placeholder-[#DADADA] ml-[3px]"
                      type="text"
                      placeholder="Enter transaction amount"
                      value={
                        txnPayLoad?.amount.length > 0 ? txnPayLoad?.amount : ""
                      }
                      name="txnamount"
                      onChange={(e) => handleTxnSearch(e.target.value)}
                      onFocus={() => setActiveSection("txnamount")}
                      disabled={
                        currentStatus === "require approval" ? true : false
                      }
                      style={{
                        opacity:
                          currentStatus === "require approval" ? "0.5" : "1",
                      }}
                    />
                  </div>
                </div>
                <div>
                  <span
                    style={{
                      color: "#C9C9C9",
                      width: "62px",
                      height: "17px",
                      font: "normal normal 12px/19px Open Sans",
                      marginBottom: "5px",
                    }}
                  >
                    Date range
                  </span>
                  <div
                    className="bene_Txn_Calendar_main_div flex flex-col items-center bg-[#314D7E]"
                    // style={{ opacity: dropdownOpen ? 0 : 1 }}
                    // ref={dateRef}
                    style={{
                      width: "305px",
                      height: showDatePicker ? "" : "35px",
                      border: showDatePicker ? "1px solid #ff5860" : "none",
                      // borderRadius: showDatePicker ? "10px" : "auto",
                    }}
                  >
                    <div
                      className="flex flex-row items-center justify-between rounded-[10px] bg-[#314D7E]"
                      style={{
                        width: "305px",
                        height: showDatePicker ? "" : "35px",
                        border: showDatePicker ? "none" : "1px solid #ff5860",
                        opacity:
                          currentStatus === "require approval" ? "0.5" : "1",
                        cursor:
                          currentStatus === "require approval"
                            ? "default"
                            : "pointer",
                      }}
                      onClick={(e) => {
                        setShowDatePicker(!showDatePicker);
                        setDropdownOpen(false);
                        setIsDropdownOpen(false);
                        setActiveSection("date");
                        // e.stopPropagation();
                      }}
                      disabled={
                        currentStatus === "require approval" ? true : false
                      }
                      // style={{ opacity: dropdownOpen ? 0 : 1 }}
                    >
                      <div
                        className="beneTxn_date_heading_div cursor-pointer"
                        style={{
                          borderTopLeftRadius: showDatePicker ? "7px" : "none",
                          borderLeft: showDatePicker
                            ? "1px solid #ff5860"
                            : "none",
                        }}
                        // onClick={() => setShowDatePicker((prev) => !prev)}
                      >
                        <div className="beneTxn_date_heading_left">
                          {startDate
                            ? formatDate(startDate)
                            : "Select from date"}
                        </div>
                      </div>
                      <img
                        src={arrowforward}
                        className="bene_txn_viewall_calendar_icon"
                      />
                      <div
                        className="beneTxn_date_select_div cursor-pointer"
                        style={{
                          borderTopRightRadius: showDatePicker ? "7px" : "none",
                          borderRight: showDatePicker
                            ? "1px solid #ff5860"
                            : "none",
                        }}
                        // onClick={() => setShowDatePicker((prev) => !prev)}
                      >
                        <div className="beneTxn_date_heading_right">
                          {" "}
                          {endDate ? formatDate(endDate) : "Select to date"}
                        </div>
                      </div>
                    </div>
                    {showDatePicker && (
                      <DatePickerComponent
                        startDate={startDate}
                        endDate={endDate}
                        handleDateChange={handleDateChange}
                        handleCancel={handleCancel}
                        handleConfirm={handleConfirm}
                        showDatePicker={showDatePicker}
                        yearDropdownRef={yearDropdownRef}
                        dateRef={dateRef}
                        setShowDatePicker={setShowDatePicker}
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div
              className="beneTxnFilterbuttonContainer"
              style={{
                padding: "0",
                height: "50px",
                backgroundColor: "#506994",
                borderTopLeftRadius: "22px",
                borderTopRightRadius: "22px",
                bottom: "0",
                position: "fixed",
                width: "100%",
                zIndex: "99999",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <div
                className="acc-viewall-clear-filter border border-[#F9F9F9] text-[#FFFFFF] text-xs text-center hover:opacity-80 w-[87px] h-[25px]"
                onClick={() => {
                  if (
                    initCategory ||
                    paymentMethod ||
                    startDate ||
                    endDate ||
                    beneAccountId ||
                    txnPayLoad?.amount.length > 0 ||
                    txnPayLoad?.ownerAccountId
                  ) {
                    clearFilter();
                  }
                }}
                style={{
                  cursor:
                    initCategory ||
                    paymentMethod ||
                    txnPayLoad?.amount.length > 0 ||
                    txnPayLoad?.ownerAccountId ||
                    (startDate && endDate) ||
                    beneAccountId
                      ? "pointer"
                      : "default",
                  opacity:
                    initCategory ||
                    paymentMethod ||
                    txnPayLoad?.amount.length > 0 ||
                    txnPayLoad?.ownerAccountId ||
                    (startDate && endDate) ||
                    beneAccountId
                      ? "1"
                      : "0.6",
                }}
              >
                Clear
              </div>
              <div
                className="acc-viewall-apply-filter text-center text-[#FFFFFF] text-xs ml-[10px] w-[87px] h-[25px] hover:opacity-80"
                onClick={() => {
                  if (typepop === "transactionHistorypopup" && beneAccountId) {
                    if (
                      initCategory ||
                      paymentMethod ||
                      txnPayLoad?.amount.length > 0 ||
                      txnAmountFilter.length > 0 ||
                      txnPayLoad?.ownerAccountId ||
                      ((startDate || endDate) && isDateConfirmed) ||
                      currentCategory?.length > 0 ||
                      isPaymentMethod?.length > 0 ||
                      txnAmountFilter?.length > 0 ||
                      selectedOwnerAccId?.length > 0 ||
                      (startDateFilter && endDateFilter)
                    ) {
                      filterCategory();
                    }
                  } else if (handleFilterApply(typepop) ? true : false) {
                    filterCategory();
                  }
                }}
                style={{
                  backgroundColor:
                    typepop === "transactionHistorypopup"
                      ? (typepop === "transactionHistorypopup" &&
                          beneAccountId &&
                          (paymentMethod ||
                            txnPayLoad?.amount.length > 0 ||
                            txnAmountFilter.length > 0 ||
                            txnPayLoad?.ownerAccountId ||
                            ((startDate || endDate) && isDateConfirmed) ||
                            initCategory)) ||
                        currentCategory?.length > 0 ||
                        isPaymentMethod?.length > 0 ||
                        txnAmountFilter?.length > 0 ||
                        selectedOwnerAccId?.length > 0 ||
                        (startDateFilter && endDateFilter)
                        ? "#F05A6A"
                        : "#727272"
                      : (handleFilterApply(typepop) ? true : false)
                      ? "#F05A6A"
                      : "#727272",
                  cursor:
                    typepop === "transactionHistorypopup"
                      ? (typepop === "transactionHistorypopup" &&
                          beneAccountId &&
                          paymentMethod) ||
                        txnPayLoad?.amount.length >= 0 ||
                        txnAmountFilter.length > 0 ||
                        txnPayLoad?.ownerAccountId ||
                        ((startDate || endDate) && isDateConfirmed) ||
                        initCategory ||
                        currentCategory?.length > 0 ||
                        isPaymentMethod?.length > 0 ||
                        txnAmountFilter?.length > 0 ||
                        selectedOwnerAccId?.length > 0 ||
                        (startDateFilter && endDateFilter)
                        ? "pointer"
                        : "default"
                      : (handleFilterApply(typepop) ? true : false)
                      ? "pointer"
                      : "default",
                }}
              >
                Apply
              </div>
            </div>
          </div>
        </>
      ) : (
        <>
          <div
            // ref={filterRef}
            // onClose={toggleSidebar(false)}
            className={`beneTxnviewallsidebar ${isOpen ? "open" : "closed"}`}
            style={{
              // backgroundColor: isViewModalOpen ? "#000" : "#3a5686",
              pointerEvents: isViewModalOpen ? "none" : "",
              position:
                typepop === "transactionHistorypopup" ? "absolute" : "fixed",
              marginTop:
                typepop === "transactionHistorypopup" ? "60px" : "175px",
            }}
          >
            <div
              className="beneTxnviewallFilterDiv pl-5"
              style={{
                visibility: isOpen ? "visible" : "hidden",
                transition: `opacity 0.2s ease-in ${isOpen ? "0s" : "0.2s"}`,
                opacity: isOpen ? "1" : "0",
                borderTop: "2px solid rgb(29, 58, 109)",
              }}
            >
              <div className="beneTxnFilterSection1">
                <span className="text-[#F05A6A] font-normal">Filter </span>

                <div className="relative w-[200px] mt-[7px]">
                  <span
                    style={{
                      color: "#C9C9C9",
                      width: "35px",
                      height: "19px",
                      font: "normal normal 12px/19px Open Sans",
                    }}
                  >
                    Beneficiary account
                  </span>
                  <div
                    ref={viewModalRef}
                    className="relative flex flex-col items-center rounded-xl h-[35px] pl-[12px] pr-[7px] py-2.5 bg-[#667CA3] w-[200px] mt-[5px]"
                    style={{
                      opacity: ownerDetail
                        ? "1"
                        : typepop === "transactionHistorypopup"
                        ? "0.5"
                        : currentStatus === "require approval"
                        ? "0.5"
                        : "1",
                      cursor: ownerDetail
                        ? "1"
                        : typepop === "transactionHistorypopup"
                        ? "default"
                        : currentStatus === "require approval"
                        ? "default"
                        : "pointer",
                    }}
                  >
                    <button
                      className="flex items-center justify-between border-transparent active:border-white w-full mt-[-1px]"
                      // onClick={(handleOpenViewModal)}
                      onClick={(e) => {
                        if (
                          typepop !== "transactionHistorypopup" ||
                          ownerDetail
                        ) {
                          handleOpenViewModal();
                          e.stopPropagation();
                        }
                      }}
                      disabled={
                        currentStatus === "require approval" ? true : false
                      }
                      style={{
                        cursor:
                          typepop === "transactionHistorypopup"
                            ? "default"
                            : currentStatus === "require approval"
                            ? "default"
                            : "pointer",
                      }}
                    >
                      <span
                        className="bene_txn_filter_acc_selected text-xs truncate"
                        style={{ color: accountName ? "#f9f9f9" : "#CFCFCF" }}
                      >
                        {accountName === ""
                          ? "Select beneficiary account"
                          : accountName
                              .toLowerCase()
                              .split(" ")
                              ?.map(
                                (word) =>
                                  word.charAt(0).toUpperCase() + word.slice(1)
                              )
                              .join(" ")}
                      </span>
                      <img
                        className="txn_viewall_select_account h-[11px] w-[11px]"
                        src={ArrowDown}
                        alt=""
                      />
                    </button>
                  </div>
                </div>

                <div className="relative w-[200px] mt-[20px]">
                  <span
                    style={{
                      color: "#C9C9C9",
                      width: "35px",
                      height: "19px",
                      font: "normal normal 12px/19px Open Sans",
                    }}
                  >
                    Source account
                  </span>
                  <div
                    className="relative flex flex-col items-center rounded-xl h-[35px] pl-[12px] pr-[7px] py-2.5 bg-[#667CA3] w-[200px] mt-[5px]"
                    style={{
                      opacity:
                        currentStatus === "require approval" || ownerDetail
                          ? "0.5"
                          : "1",
                    }}
                  >
                    <button
                      className="flex items-center justify-between border-transparent active:border-white w-full mt-[-1px]"
                      onClick={() => {
                        if (ownerDetail) {
                          return;
                        }
                        handleOpenViewModalOwner();
                      }}
                      disabled={
                        currentStatus === "require approval" ? true : false
                      }
                      style={{
                        cursor:
                          currentStatus === "require approval"
                            ? "default"
                            : "pointer",
                      }}
                    >
                      <span
                        className="bene_txn_filter_acc_selected text-xs truncate"
                        style={{
                          color: txnPayLoad?.ownerbankAccountNo
                            ? "#f9f9f9"
                            : "#CFCFCF",
                        }}
                      >
                        {txnPayLoad?.ownerbankAccountNo === ""
                          ? "Select owner account"
                          : txnPayLoad?.ownerbankAccountNo}
                      </span>
                      <img
                        className="txn_viewall_select_account h-[11px] w-[11px]"
                        src={ArrowDown}
                        alt=""
                      />
                    </button>
                  </div>
                </div>
              </div>

              <div className="beneTxnFilterSection2 pl-[30px] mt-[32px]">
                {!isDropdownOpen && (
                  <div className="relative w-[200px] ">
                    <span
                      style={{
                        color: "#C9C9C9",
                        width: "35px",
                        height: "19px",
                        font: "normal normal 12px/19px Open Sans",
                      }}
                    >
                      Payment method
                    </span>

                    <div
                      className="bene_txn_payment_select mt-[10px]"
                      ref={paymentDropdownRef}
                      onClick={(e) => {
                        setDropdownPaymentOpen(!isDropdownPaymentOpen);
                        setIsDropdownOpen(false);
                        setShowDatePicker(false);
                        e.stopPropagation();
                      }}
                      style={{
                        height: isDropdownPaymentOpen ? "160px" : "35px",
                        outline: isDropdownPaymentOpen
                          ? "1px solid #E25869"
                          : "",
                        transition: isDropdownPaymentOpen ? "none" : "",
                        opacity:
                          currentStatus === "require approval" ? "0.5" : "1",
                        position: "absolute",
                      }}
                      disabled={
                        currentStatus === "require approval" ? true : false
                      }
                    >
                      <div className="bene_txn_category_select_select">
                        <div
                          className="txn_category_select_scroll_line"
                          style={{
                            display: isDropdownPaymentOpen ? "block" : "none",
                          }}
                        ></div>

                        <span
                          style={{
                            fontSize: "12px",
                            paddingLeft: isDropdownPaymentOpen ? "9px" : "15px",
                            color: paymentMethod ? "#f9f9f9" : "#dadada",
                          }}
                        >
                          {paymentMethod === "" ? "Select type" : paymentMethod}
                        </span>
                        <img
                          src={ArrowDown}
                          alt="down"
                          style={{
                            transform: isDropdownPaymentOpen
                              ? "rotate(180deg)"
                              : "rotate(0deg)",
                          }}
                        />
                      </div>

                      {isDropdownPaymentOpen && (
                        <div className="bg-[#667CA3] absolute top-12 flex flex-col items-start rounded-[10px] w-[200px] h-[82px]">
                          {["All", "NEFT", "RTGS"]?.map((settlement) => (
                            <div
                              key={settlement}
                              className="flex w-[190px] h-[35px] hover:bg-[#506994] cursor-pointer rounded-[5px] border-l-transparent ml-1"
                              style={{
                                backgroundColor:
                                  paymentMethod === settlement && paymentMethod
                                    ? "#3A5686"
                                    : "",
                                padding: "0px 10px",
                              }}
                              onClick={() => {
                                handleSettlementClick(settlement);
                                setIsDropdownOpen(false);
                              }}
                            >
                              <span className="pl-2 py-2.5 text-[#dadada] text-xs truncate w-[170px] ">
                                {settlement}
                              </span>
                              <div
                                style={{
                                  display:
                                    paymentMethod == settlement
                                      ? "flex"
                                      : "none",
                                  textAlign: "right",
                                  justifyContent: "end",
                                  paddingRight: "1px",
                                  marginTop: "10px",
                                }}
                              >
                                <img
                                  style={{
                                    height: "13px",
                                    width: "14px",
                                  }}
                                  src={allChecker}
                                  alt="check"
                                />
                              </div>
                            </div>
                          ))}
                        </div>
                      )}
                    </div>
                  </div>
                )}
                {!isDropdownOpen && (
                  <div className="relative w-[200px] mt-[60px]">
                    <span
                      style={{
                        color: "#C9C9C9",
                        width: "35px",
                        height: "19px",
                        font: "normal normal 12px/19px Open Sans",
                      }}
                    >
                      Beneficiary category
                    </span>

                    <div
                      className="bene_txn_category_select mt-[10px]"
                      ref={categoryDropdownRef}
                      onClick={(e) => {
                        setDropdownOpen(!dropdownOpen);
                        setIsDropdownOpen(false);
                        setShowDatePicker(false);
                        e.stopPropagation();
                      }}
                      style={{
                        height: dropdownOpen ? "198px" : "35px",
                        outline: dropdownOpen ? "1px solid #E25869" : "",
                        transition: dropdownOpen ? "none" : "",
                        position: "relative",
                      }}
                    >
                      <div className="bene_txn_category_select_select">
                        <div
                          className="txn_category_select_scroll_line"
                          style={{ display: dropdownOpen ? "block" : "none" }}
                        ></div>

                        <span
                          style={{
                            fontSize: "12px",
                            paddingLeft: dropdownOpen ? "9px" : "15px",
                            color: initCategory ? "#f9f9f9" : "#dadada",
                          }}
                        >
                          {initCategory || "Select category"}
                        </span>
                        <img
                          src={ArrowDown}
                          alt="down"
                          style={{
                            transform: dropdownOpen
                              ? "rotate(180deg)"
                              : "rotate(0deg)",
                          }}
                        />
                      </div>

                      {dropdownOpen && (
                        <div className="txn_category_select_scroll">
                          <div className="bene_input_div_element">
                            <img
                              src={search}
                              alt="search"
                              className="search_owner_element"
                              onClick={(e) => {
                                e.stopPropagation();
                                searchRef.current.focus();
                              }}
                            />
                            <input
                              ref={searchRef}
                              type="text"
                              value={categorySearch}
                              onChange={handleCategorySearchChange}
                              placeholder="Search"
                              onClick={(e) => e.stopPropagation()}
                              className="placeholder-[#dadada] text-xs"
                            />
                            <img
                              src={cancel}
                              alt="cancel"
                              className="search_owner_element"
                              onClick={(e) => {
                                e.stopPropagation();
                                setCategorySearch("");
                              }}
                              style={{ cursor: "pointer" }}
                            />
                          </div>

                          {categorySearch === "" ? (
                            <div className="bene_txn_sort_by_category_scroll">
                              {categories?.map((category) => (
                                <div
                                  key={category.id}
                                  className={
                                    initCategory === category?.name
                                      ? "selected-category rounded-[5px]"
                                      : "category-wrapper"
                                  }
                                >
                                  <Tooltip
                                    key={category.id}
                                    componentsProps={{
                                      popper: {
                                        sx: {
                                          zIndex: 99999,
                                          [`& .${tooltipClasses.tooltip}`]: {
                                            backgroundColor: (theme) =>
                                              theme.palette.common.black,
                                          },
                                        },
                                      },
                                    }}
                                    title={category.name}
                                  >
                                    <button
                                      className="bene_txn_category_select_scroll_com"
                                      onClick={() => {
                                        handleClick(category.name);
                                      }}
                                    >
                                      <span className="categoryTxnname">
                                        {category?.name}
                                      </span>

                                      <div
                                        style={{
                                          display:
                                            initCategory == category?.name
                                              ? "flex"
                                              : "none",
                                          textAlign: "right",

                                          position: "absolute",
                                          right: "8px",

                                          paddingRight: "1px",
                                        }}
                                      >
                                        <img
                                          style={{
                                            height: "13px",
                                            width: "14px",
                                          }}
                                          src={allChecker}
                                          alt="check"
                                        />
                                      </div>
                                    </button>
                                  </Tooltip>
                                </div>
                              ))}
                            </div>
                          ) : (
                            <div className="bene_txn_filter_by_category_scroll">
                              {filteredCategories?.map((category) => (
                                <div
                                  key={category.id}
                                  className="category-wrapper"
                                >
                                  <Tooltip
                                    key={category.id}
                                    componentsProps={{
                                      popper: {
                                        sx: {
                                          [`& .${tooltipClasses.tooltip}`]: {
                                            backgroundColor: (theme) =>
                                              theme.palette.common.black,
                                          },
                                        },
                                      },
                                    }}
                                    title={category.name}
                                  >
                                    <button
                                      className="bene_txn_category_select_scroll_com"
                                      onClick={() => {
                                        handleClick(category.name);
                                      }}
                                    >
                                      {category.name}
                                    </button>
                                  </Tooltip>
                                </div>
                              ))}
                            </div>
                          )}
                        </div>
                      )}
                    </div>
                  </div>
                )}
              </div>

              <div className="beneTxnFilterSection3 pl-[30px] mt-[42px]">
                <span
                  style={{
                    color: "#C9C9C9",
                    width: "62px",
                    height: "17px",
                    font: "normal normal 12px/19px Open Sans",
                    marginBottom: "5px",
                  }}
                >
                  Date range
                </span>
                <div
                  className="bene_Txn_Calendar_main_div absolute flex flex-col items-center bg-[#314D7E] top-[86px]"
                  // style={{ opacity: dropdownOpen ? 0 : 1 }}
                  // ref={dateRef}
                  style={{
                    width: "305px",
                    height: showDatePicker ? "" : "35px",
                    border: showDatePicker ? "1px solid #ff5860" : "none",
                    // borderRadius: showDatePicker ? "10px" : "auto",
                  }}
                >
                  <div
                    className="flex flex-row items-center justify-between rounded-[10px] bg-[#314D7E]"
                    style={{
                      width: "305px",
                      height: showDatePicker ? "" : "35px",
                      border: showDatePicker ? "none" : "1px solid #ff5860",
                      opacity:
                        currentStatus === "require approval" ? "0.5" : "1",
                      cursor:
                        currentStatus === "require approval"
                          ? "default"
                          : "pointer",
                    }}
                    onClick={(e) => {
                      setShowDatePicker(!showDatePicker);
                      setDropdownOpen(false);
                      setIsDropdownOpen(false);
                      setActiveSection("date");
                      // e.stopPropagation();
                    }}
                    disabled={
                      currentStatus === "require approval" ? true : false
                    }
                    // style={{ opacity: dropdownOpen ? 0 : 1 }}
                  >
                    <div
                      className="beneTxn_date_heading_div cursor-pointer"
                      style={{
                        borderTopLeftRadius: showDatePicker ? "7px" : "none",
                        borderLeft: showDatePicker
                          ? "1px solid #ff5860"
                          : "none",
                      }}
                      // onClick={() => setShowDatePicker((prev) => !prev)}
                    >
                      <div className="beneTxn_date_heading_left">
                        {startDate ? formatDate(startDate) : "Select from date"}
                      </div>
                    </div>
                    <img
                      src={arrowforward}
                      className="bene_txn_viewall_calendar_icon"
                    />
                    <div
                      className="beneTxn_date_select_div cursor-pointer"
                      style={{
                        borderTopRightRadius: showDatePicker ? "7px" : "none",
                        borderRight: showDatePicker
                          ? "1px solid #ff5860"
                          : "none",
                      }}
                      // onClick={() => setShowDatePicker((prev) => !prev)}
                    >
                      <div className="beneTxn_date_heading_right">
                        {" "}
                        {endDate ? formatDate(endDate) : "Select to date"}
                      </div>
                    </div>
                  </div>
                  {showDatePicker && (
                    <DatePickerComponent
                      startDate={startDate}
                      endDate={endDate}
                      handleDateChange={handleDateChange}
                      handleCancel={handleCancel}
                      handleConfirm={handleConfirm}
                      showDatePicker={showDatePicker}
                      yearDropdownRef={yearDropdownRef}
                      dateRef={dateRef}
                      setShowDatePicker={setShowDatePicker}
                    />
                  )}
                </div>

                <div
                  className="relative w-[200px] mt-[3.4rem]"
                  style={{
                    visibility: showDatePicker ? "hidden" : "",
                  }}
                >
                  <span
                    style={{
                      color: "#C9C9C9",
                      width: "35px",
                      height: "19px",
                      font: "normal normal 12px/19px Open Sans",
                    }}
                  >
                    Amount
                  </span>

                  <div
                    className="w-[305px] h-[35px] px-4 py-2.5 rounded-xl bg-[#667CA3] text-[#F9F9F9] placeholder-[#DADADA] text-xs mt-[5px]"
                    style={{
                      opacity:
                        currentStatus === "require approval" ? "0.3" : "1",
                      outline: "none",
                    }}
                  >
                    &#8377;
                    <input
                      className="ml-[3px] bg-[#667CA3] text-[#F9F9F9] placeholder-[#DADADA] w-[250px]"
                      type="text"
                      placeholder="Enter transaction amount"
                      value={
                        txnPayLoad?.amount.length > 0 ? txnPayLoad?.amount : ""
                      }
                      name="txnamount"
                      onChange={(e) => handleTxnSearch(e.target.value)}
                      onFocus={() => setActiveSection("txnamount")}
                      disabled={
                        currentStatus === "require approval" ? true : false
                      }
                    />
                  </div>
                </div>
              </div>

              <div className="beneTxnFilterbuttonContainer">
                <div
                  className="acc-viewall-clear-filter border border-[#F9F9F9] text-[#FFFFFF] text-xs text-center hover:opacity-80 w-[87px] h-[25px]"
                  onClick={() => {
                    if (
                      (typepop === "transactionHistorypopup" &&
                        ownerDetail &&
                        selectedOwnerAccId &&
                        (initCategory ||
                          paymentMethod ||
                          startDate ||
                          endDate ||
                          txnPayLoad?.amount.length > 0 ||
                          txnPayLoad?.beneAccountId)) ||
                      currentCategory?.length > 0 ||
                      isPaymentMethod?.length > 0 ||
                      txnAmountFilter?.length > 0 ||
                      beneAccountId?.length > 0 ||
                      (startDateFilter && endDateFilter)
                    ) {
                      clearFilter();
                    } else if (
                      (typepop === "transactionHistorypopup" &&
                        beneAccountId &&
                        (initCategory ||
                          paymentMethod ||
                          startDate ||
                          endDate ||
                          txnPayLoad?.amount.length > 0 ||
                          txnPayLoad?.ownerAccountId)) ||
                      currentCategory?.length > 0 ||
                      isPaymentMethod?.length > 0 ||
                      txnAmountFilter?.length > 0 ||
                      selectedOwnerAccId?.length > 0 ||
                      (startDateFilter && endDateFilter)
                    ) {
                      clearFilter();
                    } else if (
                      initCategory ||
                      paymentMethod ||
                      startDate ||
                      endDate ||
                      beneAccountId ||
                      txnPayLoad?.amount.length > 0 ||
                      txnPayLoad?.ownerAccountId ||
                      ((currentCategory?.length > 0 ||
                        isPaymentMethod?.length > 0 ||
                        txnAmountFilter?.length > 0 ||
                        selectedOwnerAccId?.length > 0 ||
                        selectedBeneAccId?.length > 0 ||
                        (startDateFilter && endDateFilter)) &&
                        typepop !== "transactionHistorypopup")
                    ) {
                      clearFilter();
                    }
                  }}
                  style={{
                    cursor:
                      typepop === "transactionHistorypopup"
                        ? typepop === "transactionHistorypopup" && ownerDetail
                          ? typepop === "transactionHistorypopup" &&
                            ownerDetail &&
                            selectedOwnerAccId &&
                            (paymentMethod ||
                              txnPayLoad?.amount.length > 0 ||
                              txnPayLoad?.beneAccountId ||
                              (startDate && endDate) ||
                              initCategory ||
                              currentCategory?.length > 0 ||
                              isPaymentMethod?.length > 0 ||
                              txnAmountFilter?.length > 0 ||
                              beneAccountId?.length > 0 ||
                              (startDateFilter && endDateFilter))
                            ? "pointer"
                            : "default"
                          : typepop === "transactionHistorypopup" &&
                            beneAccountId &&
                            (paymentMethod ||
                              txnPayLoad?.amount.length > 0 ||
                              txnPayLoad?.ownerAccountId ||
                              (startDate && endDate) ||
                              initCategory ||
                              currentCategory?.length > 0 ||
                              isPaymentMethod?.length > 0 ||
                              txnAmountFilter?.length > 0 ||
                              selectedOwnerAccId?.length > 0 ||
                              (startDateFilter && endDateFilter))
                          ? "pointer"
                          : "default"
                        : initCategory ||
                          paymentMethod ||
                          txnPayLoad?.amount.length > 0 ||
                          txnPayLoad?.ownerAccountId ||
                          (startDate && endDate) ||
                          beneAccountId ||
                          currentCategory?.length > 0 ||
                          isPaymentMethod?.length > 0 ||
                          txnAmountFilter?.length > 0 ||
                          selectedOwnerAccId?.length > 0 ||
                          selectedBeneAccId?.length > 0 ||
                          (startDateFilter && endDateFilter)
                        ? "pointer"
                        : "default",
                    opacity:
                      typepop === "transactionHistorypopup"
                        ? typepop === "transactionHistorypopup" && ownerDetail
                          ? typepop === "transactionHistorypopup" &&
                            ownerDetail &&
                            selectedOwnerAccId &&
                            (paymentMethod ||
                              txnPayLoad?.amount.length > 0 ||
                              txnPayLoad?.beneAccountId ||
                              (startDate && endDate) ||
                              initCategory ||
                              currentCategory?.length > 0 ||
                              isPaymentMethod?.length > 0 ||
                              txnAmountFilter?.length > 0 ||
                              beneAccountId?.length > 0 ||
                              (startDateFilter && endDateFilter))
                            ? "1"
                            : "0.6"
                          : (typepop === "transactionHistorypopup" &&
                              beneAccountId &&
                              (paymentMethod ||
                                txnPayLoad?.amount.length > 0 ||
                                txnPayLoad?.ownerAccountId ||
                                (startDate && endDate) ||
                                initCategory)) ||
                            currentCategory?.length > 0 ||
                            isPaymentMethod?.length > 0 ||
                            txnAmountFilter?.length > 0 ||
                            selectedOwnerAccId?.length > 0 ||
                            (startDateFilter && endDateFilter)
                          ? "1"
                          : "0.6"
                        : initCategory ||
                          paymentMethod ||
                          txnPayLoad?.amount.length > 0 ||
                          txnPayLoad?.ownerAccountId ||
                          (startDate && endDate) ||
                          beneAccountId ||
                          currentCategory?.length > 0 ||
                          isPaymentMethod?.length > 0 ||
                          txnAmountFilter?.length > 0 ||
                          selectedOwnerAccId?.length > 0 ||
                          selectedBeneAccId?.length > 0 ||
                          (startDateFilter && endDateFilter)
                        ? "1"
                        : "0.6",
                  }}
                >
                  Clear
                </div>
                <div
                  className="acc-viewall-apply-filter text-center text-[#FFFFFF] text-xs ml-[10px] w-[87px] h-[25px]"
                  onClick={() => {
                    if (
                      typepop === "transactionHistorypopup" &&
                      selectedOwnerAccId &&
                      ownerDetail
                    ) {
                      if (
                        initCategory ||
                        paymentMethod ||
                        txnPayLoad?.amount.length > 0 ||
                        txnAmountFilter.length > 0 ||
                        txnPayLoad?.beneAccountId ||
                        ((startDate || endDate) && isDateConfirmed) ||
                        currentCategory?.length > 0 ||
                        isPaymentMethod?.length > 0 ||
                        txnAmountFilter?.length > 0 ||
                        beneAccountId?.length > 0 ||
                        (startDateFilter && endDateFilter)
                      ) {
                        filterCategory();
                      }
                    } else if (
                      typepop === "transactionHistorypopup" &&
                      beneAccountId
                    ) {
                      if (
                        initCategory ||
                        paymentMethod ||
                        txnPayLoad?.amount.length > 0 ||
                        txnAmountFilter.length > 0 ||
                        txnPayLoad?.ownerAccountId ||
                        ((startDate || endDate) && isDateConfirmed) ||
                        currentCategory?.length > 0 ||
                        isPaymentMethod?.length > 0 ||
                        txnAmountFilter?.length > 0 ||
                        selectedOwnerAccId?.length > 0 ||
                        (startDateFilter && endDateFilter)
                      ) {
                        filterCategory();
                      }
                    } else if (handleFilterApply(typepop) ? true : false) {
                      filterCategory();
                    }
                  }}
                  style={{
                    backgroundColor:
                      typepop === "transactionHistorypopup"
                        ? typepop === "transactionHistorypopup" && ownerDetail
                          ? (typepop === "transactionHistorypopup" &&
                              selectedOwnerAccId &&
                              (paymentMethod ||
                                txnPayLoad?.amount.length > 0 ||
                                txnAmountFilter.length > 0 ||
                                txnPayLoad?.beneAccountId ||
                                ((startDate || endDate) && isDateConfirmed) ||
                                initCategory)) ||
                            currentCategory?.length > 0 ||
                            isPaymentMethod?.length > 0 ||
                            txnAmountFilter?.length > 0 ||
                            beneAccountId?.length > 0 ||
                            (startDateFilter && endDateFilter)
                            ? "#F05A6A"
                            : "#727272"
                          : (typepop === "transactionHistorypopup" &&
                              beneAccountId &&
                              (paymentMethod ||
                                txnPayLoad?.amount.length > 0 ||
                                txnAmountFilter.length > 0 ||
                                txnPayLoad?.ownerAccountId ||
                                ((startDate || endDate) && isDateConfirmed) ||
                                initCategory)) ||
                            currentCategory?.length > 0 ||
                            isPaymentMethod?.length > 0 ||
                            txnAmountFilter?.length > 0 ||
                            selectedOwnerAccId?.length > 0 ||
                            (startDateFilter && endDateFilter)
                          ? "#F05A6A"
                          : "#727272"
                        : (handleFilterApply(typepop) ? true : false)
                        ? "#F05A6A"
                        : "#727272",
                    cursor:
                      typepop === "transactionHistorypopup"
                        ? typepop === "transactionHistorypopup" && ownerDetail
                          ? (typepop === "transactionHistorypopup" &&
                              selectedOwnerAccId &&
                              (paymentMethod ||
                                txnPayLoad?.amount.length > 0 ||
                                txnAmountFilter.length > 0 ||
                                txnPayLoad?.beneAccountId ||
                                ((startDate || endDate) && isDateConfirmed) ||
                                initCategory)) ||
                            currentCategory?.length > 0 ||
                            isPaymentMethod?.length > 0 ||
                            txnAmountFilter?.length > 0 ||
                            beneAccountId?.length > 0 ||
                            (startDateFilter && endDateFilter)
                            ? "pointer"
                            : "default"
                          : (typepop === "transactionHistorypopup" &&
                              beneAccountId &&
                              paymentMethod) ||
                            txnPayLoad?.amount.length >= 0 ||
                            txnAmountFilter.length > 0 ||
                            txnPayLoad?.ownerAccountId ||
                            ((startDate || endDate) && isDateConfirmed) ||
                            initCategory ||
                            currentCategory?.length > 0 ||
                            isPaymentMethod?.length > 0 ||
                            txnAmountFilter?.length > 0 ||
                            selectedOwnerAccId?.length > 0 ||
                            (startDateFilter && endDateFilter)
                          ? "pointer"
                          : "default"
                        : (handleFilterApply(typepop) ? true : false)
                        ? "pointer"
                        : "default",
                  }}
                >
                  Apply
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
}

export default BeneTxnFilterSidebar;
