import React, { useState, useEffect, useRef } from "react";
import arrow_down_red from "../../../../src/assets/TransactionDashboardIcons/arrow_down_red.png";
import "./index.css";

export const BeneMultiDropDrown = ({
  ddlValue,
  optionalLabel,
  isFieldRequired,
  fieldname,
  handleInputChange,
  data_type,
  type,
  title,
  selectedValue,
  setSelectedValue,
  setShowDatePicker,
  setAdditionalData,
  setTxnPayLoad,
  additionalData,
}) => {
  const [dropdownOpen, setdropdownOpen] = useState(false);

  useEffect(() => {
    if (
      selectedValue?.from !== "Select" &&
      selectedValue?.to !== "Select" &&
      Number(selectedValue?.from) < Number(selectedValue?.to)
    ) {
      handleInputChange({
        target: {
          name: fieldname,
          value: selectedValue,
          data_typeChan: data_type,
        },
      });
    } else if (Number(selectedValue?.from) >= Number(selectedValue?.to)) {
      setSelectedValue({ ...selectedValue, from: "Select", to: "Select" });
      const { [fieldname]: _, ...updatedData } = additionalData;
      setAdditionalData(updatedData);
      setTxnPayLoad((prevState) => ({
        ...prevState,
        mergeCategory: prevState.mergeCategory?.map((item) =>
          item.data_type === "toggle" ? { ...item, value: "" } : item
        ),
      }));
    }
  }, [selectedValue]);

  const handleSelect = (val) => {
    if (title === "From Year") {
      setSelectedValue({ ...selectedValue, from: val });
    } else if (title === "To Year") {
      setSelectedValue({ ...selectedValue, to: val });
    }

    setdropdownOpen(false);
  };

  const dropdownRef = useRef();

  useEffect(() => {
    function handleClickOutside(event) {
      if (dropdownRef.current && !dropdownRef.current?.contains(event.target)) {
        setdropdownOpen(false);
      }
    }

    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  return (
    <div className="w-[100%]" ref={dropdownRef}>
      <div
        className="headerText"
        id="uploadId"
        style={{
          color:
            selectedValue?.from !== "Select" &&
            selectedValue?.to !== "Select" &&
            Number(selectedValue?.from) < Number(selectedValue?.to)
              ? "#00FF00"
              : "#DADADA",
        }}
      >
        {`${title}${isFieldRequired ? "*" : ""}${optionalLabel}`}
      </div>
      <div
        className={`selectDrp shadow-lg ${dropdownOpen ? "open" : ""}`}
        onClick={() => {
          setShowDatePicker(false);
          setdropdownOpen(!dropdownOpen);
        }}
      >
        <div className="selectDrp_select">
          <span
            style={{
              color:
                selectedValue?.from === "Select" &&
                selectedValue?.to === "Select"
                  ? "#DADADA"
                  : "#FFFFFF",
            }}
          >
            {title === "From Year" && selectedValue?.from !== "Select"
              ? selectedValue?.from
              : title === "To Year" && selectedValue?.to !== "Select"
              ? selectedValue?.to
              : title === "From Year"
              ? selectedValue?.from
              : selectedValue?.to}
          </span>
          <img
            src={arrow_down_red}
            alt="down"
            style={{
              transform: dropdownOpen ? "rotate(180deg)" : "rotate(0deg)",
            }}
          />
        </div>
        <div
          className="selectDrp_scroll_line"
          style={{ display: dropdownOpen ? "block" : "none" }}
        ></div>

        {dropdownOpen && (
          <div className="scroll_content shadow-lg cursor-pointer">
            {type ? (
              <ul>
                {ddlValue.map((val, index) => (
                  <li
                    key={`rd${index}`}
                    className="category-wrapper"
                    onClick={() => handleSelect(val, fieldname, data_type)}
                  >
                    <label>{val}</label>
                    <input
                      type={type}
                      name="quantity"
                      onChange={() => handleSelect(val, fieldname, data_type)}
                      required={isFieldRequired}
                    />
                  </li>
                ))}
              </ul>
            ) : (
              ddlValue.map((val, index) => (
                <div
                  key={`rd${index}`}
                  className="category-wrapper"
                  onClick={() => handleSelect(val, fieldname, data_type)}
                >
                  {val}
                </div>
              ))
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default BeneMultiDropDrown;
