import React, { useState, useEffect, useRef } from "react";
import "./index.css";
import { Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  beneCreateTxn,
  resetTxnError,
} from "../../../redux/features/beneficiary/beneTxnAccounts/beneTransactionAccounts";
import { checkUsertoken } from "../../../redux/features/login/login";
import { uploadImages } from "../../../redux/features/beneficiary/beneAllVerification/beneAllVerification";

const BeneTxnStepper = ({
  activeStep,
  handleBack,
  steps,
  handleNext,
  nextButtonEnabled,
  isClicked,
  isButtonDisabled,
  amount,
  beneAccountId,
  ownerId,
  categories,
  additionalData,
  settleType,
  transactionDisable,
  setTransactionDisable,
  files,
  txnPayload,
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const spaceKeyPressed = useRef(false);

  const { beneCreateTxnError } = useSelector(
    (state) => state.beneTransactionAccounts
  );

  useEffect(() => {
    if (beneCreateTxnError === 401) {
      setTimeout(() => {
        navigate("/feature_not_assigned", {
          state: {
            unavailableService: "VBeneficiaryTxn",
          },
        });
      }, 1000);
    }

    return () => {
      dispatch(resetTxnError());
    };
  }, [beneCreateTxnError]);

  const throttle = (func, delay) => {
    let inThrottle = false;
    return function (...args) {
      if (!inThrottle) {
        func.apply(this, args);
        inThrottle = true;
        setTimeout(() => (inThrottle = false), delay);
      }
    };
  };

  const handleEnterKeyPress = (e) => {
    if (
      e.keyCode === 32 &&
      e.target.tagName !== "INPUT" &&
      e.target.tagName !== "TEXTAREA"
    ) {
      e.preventDefault();
    }
    if (e.code === "Space") {
      spaceKeyPressed.current = true;
      return;
    }

    if (e.key === "Enter" && activeStep === 3 && !spaceKeyPressed.current) {
      throttledHandleCreateTransaction();
    }

    if (e.key !== "Space") {
      spaceKeyPressed.current = false;
    }
  };

  const handleCreateTransaction = async () => {
    setTransactionDisable(true);
    dispatch(checkUsertoken());

    let haveImage = txnPayload?.mergeCategory?.some((val) => {
      return val?.data_type === "upload" && val?.value !== "Not Given";
    });

    let storeData;

    if (haveImage) {
      const responseUpload = await dispatch(uploadImages(files));
      if (responseUpload) {
        storeData = categories?.configuration?.data?.map((val) => {
          if (additionalData[val?.name]) {
            const dataValue = additionalData[val?.name];
            if (val?.data_type === "numeric") {
              const integerValue = parseInt(dataValue, 10);
              if (!isNaN(integerValue)) {
                return { ...val, value: integerValue };
              } else {
                throw new Error(
                  `Value '${dataValue}' is not a valid integer for category '${val?.name}'`
                );
              }
            } else if (val?.data_type === "upload") {
              const { description, ...rest } = val;
              return { ...rest, value: responseUpload?.payload };
            } else if (val?.data_type === "toggle") {
              const { description, ...rest } = val;
              return { ...rest, value: dataValue };
            } else {
              return { ...val, value: dataValue };
            }
          } else if (val?.data_type === "toggle") {
            const { description, ...rest } = val;
            return { ...rest };
          }
          return val;
        });
      }
    } else {
      storeData = categories?.configuration?.data?.map((val) => {
        if (additionalData[val?.name]) {
          const dataValue = additionalData[val?.name];
          if (val?.data_type === "numeric") {
            const integerValue = parseInt(dataValue, 10);
            if (!isNaN(integerValue)) {
              return { ...val, value: integerValue };
            } else {
              throw new Error(
                `Value '${dataValue}' is not a valid integer for category '${val?.name}'`
              );
            }
          } else if (val?.data_type === "toggle") {
            const { description, ...rest } = val;
            return { ...rest, value: dataValue };
          } else {
            return { ...val, value: dataValue };
          }
        } else if (val?.data_type === "toggle") {
          const { description, ...rest } = val;
          return { ...rest };
        }
        return val;
      });
    }

    const response1 = await dispatch(
      beneCreateTxn({
        amount: amount,
        owner_account_id: ownerId,
        beneficiary_account_id: beneAccountId,
        category: categories?.name,
        additional_data: storeData,
        payment_method: settleType.toUpperCase(),
      })
    );

    if (response1?.error?.message !== "Rejected") {
      let transactionId = response1.payload?.transaction[0]?.txn_id;
      navigate(
        `/transaction/beneficiary_transaction/success/${transactionId}`,
        {
          replace: true,
        }
      );
    }
    setTimeout(() => {
      setTransactionDisable(false);
    }, 1000);
  };

  const throttledHandleCreateTransaction = throttle(
    handleCreateTransaction,
    5000
  );

  useEffect(() => {
    document.addEventListener("keydown", handleEnterKeyPress);
    return () => {
      document.removeEventListener("keydown", handleEnterKeyPress);
    };
  }, []);

  const [isMobile, setIsMobile] = useState(window.innerWidth <= 800);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 800);
      setWindowWidth(window.innerWidth);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [window.innerWidth]);

  return (
    <div
      className="bene_Stepper_Main"
      style={{ width: `${windowWidth}px`, maxWidth: "1024px" }}
    >
      <Button
        sx={{
          borderRadius: 0,
        }}
        disabled={activeStep === 0 || isButtonDisabled}
        className="custom_txn"
        style={{
          opacity: activeStep === 0 ? "0.4" : "1",
        }}
        onClick={handleBack}
      >
        Previous
      </Button>
      <div className="stepperline_Div_txn">
        {steps?.map((val, index) => {
          return (
            <div className="stepinnerline" key={index}>
              <div
                className={
                  activeStep === index && isClicked
                    ? "stepDetails growing-element"
                    : "stepDetails"
                }
                style={{
                  opacity: activeStep === index ? "1" : "0",
                }}
              >
                {activeStep === index ? val : "Hello"}
              </div>
              <div
                className={
                  activeStep === index && isClicked
                    ? "main_Line growing-element"
                    : "main_Line"
                }
                style={{
                  width: isMobile
                    ? activeStep === index
                      ? "100%"
                      : "50%"
                    : activeStep === index
                    ? "220px"
                    : "100px",
                  opacity: activeStep === index ? "1" : "0.5",
                }}
              ></div>
            </div>
          );
        })}
      </div>
      {activeStep === 3 ? (
        <Button
          disabled={transactionDisable}
          className="create_Button"
          sx={{
            borderRadius: 0,
          }}
          onClick={throttledHandleCreateTransaction}
        >
          Create
        </Button>
      ) : (
        <Button
          sx={{
            borderRadius: 0,
          }}
          disabled={nextButtonEnabled || isButtonDisabled}
          style={{
            backgroundColor: nextButtonEnabled ? "#656565" : "#F9F9F9",
            color: nextButtonEnabled ? "#FFFFFF" : "#1D3A6D",
          }}
          className="custom_txn_next"
          onClick={handleNext}
        >
          Next
        </Button>
      )}
    </div>
  );
};
export default BeneTxnStepper;
