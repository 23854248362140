import React, { useState, useEffect, useRef, useCallback } from "react";
import { InputAdornment, TextField, Button } from "@mui/material";
import "./index.css";
import AssociateInfo from "../../../assets/InfoIcons/info_filled.svg";
import { useSelector } from "react-redux";
import ViewDetails from "../../../assets/GeneralIcons/detail_page_edit detail.svg";
import Validation from "../../../utils/validation";
import { NumericFormat } from "react-number-format";
import AmountToWords from "../../_utils/AmountToWords";
import IfscCodeMapper from "../../_utils/IfscLogoMapper";
import ActionDrpDown from "../BeneAdditional";
import CloseIcon from "../../../assets/CloseIcons/close.svg";
import brower_file from "../../../../src/assets/FileIcons/browse_file.svg";
import TimePicker from "../BeneAdditionalTimepicker";
import arrowdown_filled from "../../../assets/ArrowsIcons/arrow_down_blue_filled.svg";
import arrowup_filled from "../../../assets/ArrowsIcons/arrow_up_blue_filled.svg";
import BeneMultiDropDrown from "../BeneMultiDropDown";
import BeneMonthYearSelect from "../BeneMonthYearSelect";
import DatePickerComponent from "../../_utils/Calendar";
import arrowforward from "../../../assets/ArrowsIcons/arrow_right_white – 1.svg";
import CircleCancel from "../../../assets/CloseIcons/close_with_white_circle.svg";
import FileFormatMapper from "./FileFormatMapper";
import { toast } from "react-toastify";
import { toast_position } from "../../../redux/features/Settings/Authorization";

const BeneTxnAmount = (props) => {
  const dropdownRefs = useRef(null);
  const [showInvoiceInfo, setShowInvoiceInfo] = useState(false);
  const [showRupeeSymbol, setShowRupeeSymbol] = useState(false);
  const [dropdownOpen, setdropdownOpen] = useState(false);
  const [dropdowns, setDropdowns] = useState({});

  const { beneLookData } = useSelector((state) => state.verify);
  const [hour, setHour] = useState("hh");
  const [minute, setMinute] = useState("mm");
  const [meridiem, setMeridiem] = useState("am");
  const [hourShow, setHourShow] = useState("hh");
  const [minuteShow, setMinuteShow] = useState("mm");
  const [meridiemShow, setMeridiemShow] = useState("am");
  const [dateRangeEnabled, setDateRangeEnabled] = useState("");
  const [isSingleDateSelected, setIsSingleDateSelected] = useState(false);
  const [isIncMouseDown, setIsIncMouseDown] = useState(false);
  const [isDecMouseDown, setIsDecMouseDown] = useState(false);
  const [hyperLink, setHyperLink] = useState("");

  const increment = (data_type, fieldname) => {
    props?.setQuantity(Number(props?.quantity) + 1);
    handleInputChange({
      target: {
        name: fieldname,
        value: Number(props?.quantity) + 1,
        data_typeChan: data_type,
      },
    });
  };

  function formatBytes(bytes, decimals = 2) {
    if (bytes === 0) return "0 Bytes";

    const k = 1024;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
    const i = Math.floor(Math.log(bytes) / Math.log(k));

    const formatted = parseFloat((bytes / Math.pow(k, i)).toFixed(decimals));
    return `${formatted} ${sizes[i]}`;
  }
  const decrement = (data_type, fieldname) => {
    if (props?.quantity > 0) {
      props?.setQuantity(Number(props?.quantity) - 1);
      handleInputChange({
        target: {
          name: fieldname,
          value: Number(props?.quantity) - 1,
          data_typeChan: data_type,
        },
      });
    }
  };

  const formatDate = (date) => {
    return `${date.getDate()} ${date.toLocaleString("default", {
      month: "short",
    })}, ${date.getFullYear()}`;
  };
  const handleDateChange = (dates) => {
    const [start, end] = dates;
    props?.setStartDate(start);
    props?.setEndDate(end);
  };

  const handleDateChangeCal = (dates) => {
    props?.setStartDateCal(dates);
  };

  const handleCancel = (fieldname, data_type) => {
    handleInputChange({
      target: {
        name: fieldname,
        value: "",
        data_typeChan: data_type,
      },
    });
    props?.setDateRangeEnabled(false);
    props?.setShowDatePicker(false);
    props?.setStartDate(null);
    props?.setEndDate(null);
  };

  const handleConfirm = (fieldname, data_type) => {
    handleInputChange({
      target: {
        name: fieldname,
        value: {
          start_date: convertDate(props?.startDate),
          end_date: convertDate(props?.endDate),
        },
        data_typeChan: data_type,
      },
    });
    props?.setDateRangeEnabled(true);
    props?.setShowDatePicker(false);
  };

  const handleCancelCal = (fieldname, data_type) => {
    handleInputChange({
      target: {
        name: fieldname,
        value: "",
        data_typeChan: data_type,
      },
    });
    props?.setShowDatePickerCal(false);
    props?.setStartDateCal("");
    props?.setIsSingleDateSelected(false);
  };

  const handleConfirmCal = (fieldname, data_type) => {
    handleInputChange({
      target: {
        name: fieldname,
        value: formatDate(props?.startDateCal),
        data_typeChan: data_type,
      },
    });
    props?.setIsSingleDateSelected(true);
    props?.setShowDatePickerCal(false);
  };

  const isValidHttpUrl = (url) => {
    const httpRegex =
      /^(https?:\/\/)([a-zA-Z0-9.-]+\.[a-zA-Z]{2,})(:[0-9]{1,5})?(\/.*)?$/;
    return httpRegex.test(url);
  };

  const allowedValue = (value) => {
    return Validation.allowedAmount(value);
  };

  const yearDropdownRef = useRef(null);

  const yearDropdownRefCal = useRef(null);

  const handleAmountChange = (e) => {
    e.preventDefault();
    const { name, value } = e.target;

    if (value[0] != 0) {
      const numericValue = value.replace(/[^0-9.]/g, "");

      if (name === "amount") {
        if (allowedValue(numericValue) || numericValue.length === 0) {
          const amountNumber = parseFloat(numericValue);
          const amountWords =
            numericValue.trim() === "" ? "" : AmountToWords(amountNumber);

          const adjustedAmountWords =
            amountNumber >= 1 && amountNumber <= 9
              ? amountWords.replace("rupees", "rupee")
              : amountWords;

          props.setTxnPayLoad((prevState) => ({
            ...prevState,
            amount: numericValue,
            amountWords: adjustedAmountWords,
          }));

          setShowRupeeSymbol(numericValue.trim() !== "");
          props?.setAmountInWords(adjustedAmountWords);
        }
      }
    }
  };

  const handleInputChange = (e, data_type) => {
    if (data_type === "numeric" && e.target.value.length > 15) {
      return;
    }
    if (e.preventDefault) e.preventDefault();
    const { name, value, data_typeChan } = e.target || {
      name: e.name,
      value: e.value,
    };

    const field = selectedCategory?.configuration?.data?.find(
      (field) => field.name === name
    );

    let updatedData = { ...props.additionalData };

    const trimmedValue =
      data_typeChan === "date" ||
      data_typeChan === "year" ||
      data_typeChan === "toggle" ||
      data_typeChan === "time" ||
      data_typeChan === "quantity" ||
      data_typeChan === "from_year_to_year" ||
      data_typeChan === "month_year" ||
      data_typeChan === "date_range" ||
      data_typeChan === "multi_select_list" ||
      data_typeChan === "upload"
        ? value
        : value.replace(/^\s+/, "");

    if (field?.data_type === "numeric") {
      if (trimmedValue === "" || /^\d+$/.test(trimmedValue)) {
        updatedData[name] = trimmedValue;
        props.setAdditionalData(updatedData);
      }
    } else {
      updatedData[name] = trimmedValue;
      props.setAdditionalData(updatedData);
    }

    if (field?.data_type === "list") {
      setDropdowns((prev) => ({
        ...prev,
        [name]: {
          ...prev[name],
          selectedOption: trimmedValue,
        },
      }));
    }

    if (name === "invoiceNumber" && trimmedValue.length > 0) {
      setShowInvoiceInfo(true);
    } else {
      setShowInvoiceInfo(false);
    }
  };

  const closeDropdowns = () => {
    setDropdowns((prev) => {
      const newDropdowns = Object.keys(prev).reduce((acc, key) => {
        acc[key] = { ...prev[key], isOpen: false };
        return acc;
      }, {});
      return newDropdowns;
    });
  };

  const convertDate = (dateString) => {
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
  };

  useEffect(() => {
    const handleClickOutside2 = (event) => {
      if (
        dropdownRefs.current &&
        !dropdownRefs.current.contains(event.target)
      ) {
        let obj = {
          ...dropdowns,
          isOpen: false,
        };
        setDropdowns(obj);
      }
    };

    document.addEventListener("mousedown", handleClickOutside2);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside2);
    };
  }, []);

  const handleQuantityChange = (e, fieldname, data_type) => {
    if (e.target.value.length > 15) {
      return;
    }
    props?.setQuantity(e.target.value);

    handleInputChange({
      target: {
        name: fieldname,
        value: Number(e.target.value),
        data_typeChan: data_type,
      },
    });
  };

  const handleTimeConfirm = (fieldname, data_type) => {
    if (hourShow === "hh") {
      setHour("01");
    } else {
      setHour(hourShow);
    }

    if (minuteShow === "mm") {
      setMinute("00");
    } else {
      setMinute(minuteShow);
    }
    setMeridiem(meridiemShow);
    handleInputChange({
      target: {
        name: fieldname,
        value: `${hourShow === "hh" ? "01" : hourShow}:${
          minuteShow === "mm" ? "00" : minuteShow
        } ${
          meridiemShow === "AM" || meridiemShow === "am" ? "am" : meridiemShow
        } `,
        data_typeChan: data_type,
      },
    });
  };

  useEffect(() => {
    props.BenEnableNextButton(true);
  }, []);

  useEffect(() => {
    if (!props.additionalData) return;

    const storeData =
      props.txnPayLoad.selectedCategory?.configuration?.data?.map((val) => {
        const dataValue = props.additionalData[val?.name];

        if (dataValue !== undefined && dataValue !== "") {
          if (val?.data_type === "numeric") {
            const integerValue = parseInt(dataValue, 10);
            if (!isNaN(integerValue)) {
              return { ...val, value: integerValue };
            } else {
              return val;
            }
          } else {
            return { ...val, value: dataValue };
          }
        } else if (val?.data_type === "multi_select_list") {
          return { ...val, value: [] };
        } else if (val?.data_type === "toggle") {
          return { ...val, value: false };
        } else if (val?.data_type === "hyperlink") {
          return { ...val, value: "" };
        } else if (val?.data_type === "time") {
        } else if (val?.mandatory !== "Y") {
          return { ...val, value: "Not Given" };
        }

        return val;
      });

    props.setTxnPayLoad({
      ...props.txnPayLoad,
      mergeCategory: storeData,
    });

    closeDropdowns();
  }, [props.additionalData, props.txnPayLoad.selectedCategory]);

  let haveApproved = beneLookData?.beneficiary_accounts.find((val) => {
    return val?.approval_status === "approved";
  });

  const { selectedCategory } = props;

  const days = [
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
    "Sunday",
  ];

  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const currentYear = new Date().getFullYear();
  const years = Array.from(
    { length: currentYear - 1975 + 1 },
    (_, i) => 1975 + i
  );

  const handleClick = (name) => {
    setHyperLink("");
    const { [name]: _, ...updatedData } = props?.additionalData;
    props?.setAdditionalData(updatedData);
    props?.setTxnPayLoad((prevState) => ({
      ...prevState,
      mergeCategory: prevState.mergeCategory?.map((item) =>
        item.data_type === "hyperlink" ? { ...item, value: "" } : item
      ),
    }));
  };

  const handleClearTime = (name) => {
    const { [name]: _, ...updatedData } = props?.additionalData;
    props?.setAdditionalData(updatedData);
    props?.setTxnPayLoad((prevState) => ({
      ...prevState,
      mergeCategory: prevState.mergeCategory?.map((item) =>
        item.data_type === "time" ? { ...item, value: "" } : item
      ),
    }));
    setHourShow("hh");
    setMinuteShow("mm");
    setMeridiemShow("am");
    setHour("hh");
    setMinute("mm");
    setMeridiem("am");
  };

  const handleToggle = (fieldName, data_type, value, name) => {
    let newStates = value || false;
    newStates = !newStates;

    handleInputChange({
      target: {
        name: fieldName,
        value: newStates,
        data_typeChan: data_type,
      },
    });
    if (newStates === false) {
      const { [name]: _, ...updatedData } = props?.additionalData;
      props?.setAdditionalData(updatedData);
    }
  };

  const handleDrop = useCallback((event, field) => {
    event.preventDefault();
    const selectedFiles = event.dataTransfer.files[0];
    const maxSize = 5 * 1024 * 1024;
    if (selectedFiles.size < maxSize) {
      props?.setFiles(selectedFiles);
      if (selectedFiles) {
        const reader = new FileReader();
        reader.onload = () => {
          props?.setShowImages(reader.result);
        };
        reader.readAsDataURL(selectedFiles);
      }
      handleInputChange({
        target: {
          name: field?.name,
          value: event?.dataTransfer?.files[0],
          data_typeChan: field?.data_type,
        },
      });
    } else {
      toast.error("Maximum file size is 5mb", toast_position);
    }
  }, []);

  const handleDragOver = (event) => {
    event.preventDefault();
  };

  const isTimeSelectedShow =
    hourShow !== "hh" || minuteShow !== "mm" || meridiemShow !== "am";

  const renderInputFields = () => {
    if (
      !selectedCategory ||
      !selectedCategory.configuration ||
      !selectedCategory.configuration.data ||
      selectedCategory.configuration.data.length === 0
    ) {
      return (
        <div className="text-left text-white text-base antialiased">
          Additional details are not supported for the selected category.
        </div>
      );
    }

    return props.txnPayLoad?.mergeCategory?.map((field, index) => {
      const isFieldRequired = field.mandatory === "Y";
      const optionalLabel = isFieldRequired ? "" : " (optional)";
      const fieldValue = props.additionalData[field.name] || "";
      const isMultiline = fieldValue.length > 30;
      switch (field.data_type) {
        case "string":
        case "numeric":
          return (
            <TextField
              key={index}
              className="w-full antialiased text-xl"
              size="small"
              variant="outlined"
              label={`${field.name}${optionalLabel}`}
              name={field.name}
              autoComplete="off"
              value={fieldValue}
              onChange={(e) => handleInputChange(e, field?.data_type)}
              required={isFieldRequired}
              multiline
              minRows={1}
              maxRows={10}
              sx={{
                "& fieldset": {
                  borderRadius: "10px",
                  borderColor: fieldValue
                    ? "#00FF00 !important"
                    : "#dadada !important",
                  borderWidth: "2px",
                },
                "& label": {
                  color: fieldValue
                    ? "#00FF00 !important"
                    : "#dadada !important",
                  background: "#314C7F",
                  padding: "0 5px",
                },
                "& input": {
                  color: "#dadada !important",
                },
                "& label.Mui-focused": {
                  color: "#00FFF5 !important",
                },
                "& .MuiInputBase-input": {
                  color: "#dadada !important",
                },
                "& .MuiInputBase-inputMultiline": {
                  color: "#dadada !important",
                },
                "& .Mui-focused": {
                  "& fieldset": {
                    borderColor: "#00FFF5 !important",
                  },
                },
                borderRadius: "10px",
              }}
            />
          );
        case "list":
          return (
            <ActionDrpDown
              ddlValue={field?.data}
              mandatory={field?.mandatory}
              optionalLabel={optionalLabel}
              isFieldRequired={isFieldRequired}
              fieldname={field?.name}
              handleInputChange={handleInputChange}
              data_type={field?.data_type}
              setShowDatePicker={props?.setShowDatePicker}
              value={field?.value || "Select"}
            />
          );

        case "hyperlink":
          return (
            <article hyperlink>
              <label
                id="hyperlinkLBl"
                htmlFor="hyperLinkid"
                style={{
                  color: isValidHttpUrl(hyperLink) ? "#00FF00" : "#DADADA",
                }}
              >
                {`${field.name}${isFieldRequired ? "*" : ""}${optionalLabel}`}
              </label>
              <div
                className="relative"
                style={{
                  backgroundColor: "#667CA3",
                  borderRadius: "10px",
                  marginTop: "4px",
                }}
              >
                <input
                  type="text"
                  value={hyperLink}
                  onChange={(e) => {
                    setHyperLink(e.target.value);
                    if (isValidHttpUrl(e.target.value)) {
                      handleInputChange({
                        target: {
                          name: field?.name,
                          value: e.target.value,
                          data_typeChan: field?.data_type,
                        },
                      });
                    }
                  }}
                  className="input_Box"
                  placeholder="Enter URL"
                  spellCheck={false}
                  style={{
                    width: "90%",
                    border: "none",
                    textOverflow: "ellipsis",
                  }}
                  id="hyperLinkid"
                  autoComplete="off"
                />
                <div className="absolute border-l-[2px] border-[#304b7d] h-[20px] top-[30%] right-[35px]"></div>
                <button
                  className="absolute top-[30%] right-[15px] h-[20px]"
                  disabled={field?.value?.length < 1}
                >
                  <img
                    src={CloseIcon}
                    alt=""
                    className="w-[14px] h-[14px] ml-[3px]"
                    onClick={() => handleClick(field?.name)}
                    style={{
                      opacity: field?.value?.length < 1 ? "0.4" : "1",
                      cursor: field?.value?.length < 1 ? "default" : "pointer",
                    }}
                  />
                </button>
              </div>
            </article>
          );
        case "time":
          return (
            <div className="w-[100%]">
              <h1
                id="uploadId"
                style={{
                  color: field?.value ? "#00FF00" : "#DADADA",
                }}
              >
                {`${field.name}${isFieldRequired ? "*" : ""}${optionalLabel}`}
              </h1>
              <TimePicker
                handleTimeConfirm={handleTimeConfirm}
                hourShow={hourShow}
                minuteShow={minuteShow}
                meridiemShow={meridiemShow}
                setHourShow={setHourShow}
                setMinuteShow={setMinuteShow}
                setMeridiemShow={setMeridiemShow}
                setHour={setHour}
                setMinute={setMinute}
                setMeridiem={setMeridiem}
                isTimeSelected={isTimeSelectedShow}
                dropdownOpen={dropdownOpen}
                setdropdownOpen={setdropdownOpen}
                data_type={field?.data_type}
                fieldname={field.name}
                setShowDatePicker={props?.setShowDatePicker}
                value={field?.value || "Set time"}
                handleClearTime={() => handleClearTime(field?.name)}
              />
            </div>
          );
        case "day":
          return (
            <ActionDrpDown
              ddlValue={days}
              mandatory={field?.mandatory}
              optionalLabel={optionalLabel}
              isFieldRequired={isFieldRequired}
              fieldname={field.name}
              handleInputChange={handleInputChange}
              data_type={field?.data_type}
              setShowDatePicker={props?.setShowDatePicker}
              value={field?.value || "Select"}
            />
          );
        case "toggle":
          return (
            <article>
              <h3
                id="uploadId"
                style={{
                  marginBottom: "5px",
                  color:
                    (field?.value || false) == false ? "#DADADA" : "#00FF00",
                }}
              >
                {`${field.name}${isFieldRequired ? "*" : ""}${optionalLabel}`}
              </h3>
              <div
                className={`switch-section ${
                  field?.value || false ? "" : "darker"
                }`}
                style={{ width: "auto" }}
              >
                <p>
                  <h4 className={`${field?.value || false ? "" : "darkTxt"}`}>
                    {field?.name}
                  </h4>
                  <h5 className={`${field?.value || false ? "" : "darkTxt"}`}>
                    {field?.description}
                  </h5>
                </p>
                <label className="switch">
                  <input
                    type="checkbox"
                    checked={field?.value || false}
                    onClick={() =>
                      handleToggle(
                        field?.name,
                        field?.data_type,
                        field?.value,
                        field?.name
                      )
                    }
                  />
                  <span className="slider round"></span>
                </label>
              </div>
            </article>
          );
        case "month":
          return (
            <ActionDrpDown
              ddlValue={months}
              mandatory={field?.mandatory}
              optionalLabel={optionalLabel}
              isFieldRequired={isFieldRequired}
              fieldname={field.name}
              handleInputChange={handleInputChange}
              data_type={field?.data_type}
              setShowDatePicker={props?.setShowDatePicker}
              value={field?.value || "Select"}
            />
          );
        case "upload":
          return (
            <article>
              <label
                id="uploadId"
                style={{
                  color: props?.files?.length === 0 ? "#DADADA" : "#00FF00",
                }}
              >
                {`${field.name}${isFieldRequired ? "*" : ""}${optionalLabel}`}
              </label>
              <article
                className="flex gap-[15px] items-center main"
                onDrop={(event) => {
                  event.preventDefault();
                  const selectedFiles = event.dataTransfer.files[0];
                  const maxSize = 5 * 1024 * 1024;
                  if (selectedFiles.size < maxSize) {
                    props?.setFiles(selectedFiles);
                    if (selectedFiles) {
                      const reader = new FileReader();
                      reader.onload = () => {
                        props?.setShowImages(reader.result);
                      };
                      reader.readAsDataURL(selectedFiles);
                    }
                    handleInputChange({
                      target: {
                        name: field?.name,
                        value: event?.dataTransfer?.files[0],
                        data_typeChan: field?.data_type,
                      },
                    });
                  } else {
                    toast.error("Maximum file size is 5mb", toast_position);
                  }
                }}
                onDragOver={handleDragOver}
                style={{
                  border: "2px dashed #fff",
                  borderRadius: "5px",
                  padding: "10px 20px",
                  margin: "5px 0 0px 0px",
                  position: "relative",
                }}
              >
                <img
                  src={
                    props?.files?.type?.split("/").pop().toUpperCase() ===
                      "PNG" ||
                    props?.files?.type?.split("/").pop().toUpperCase() ===
                      "BMP" ||
                    props?.files?.type?.split("/").pop().toUpperCase() ===
                      "JPEG" ||
                    props?.files?.type?.split("/").pop().toUpperCase() === "JPG"
                      ? props?.showImages
                      : props?.files?.length === 0
                      ? brower_file
                      : FileFormatMapper(
                          props?.files?.type?.split("/").pop().toUpperCase()
                        )
                  }
                  alt="file"
                  style={{ width: "50px", height: "50px" }}
                />
                {props?.files?.length === 0 ? (
                  <label
                    htmlFor="file-input"
                    style={{ color: "#fff", display: "block" }}
                  >
                    Drag and Drop your file here,
                    <br />
                    OR{" "}
                    <span
                      style={{
                        color: "#FF7F33",
                        textDecoration: "underline",
                        cursor: "pointer",
                      }}
                    >
                      Browse file
                    </span>
                  </label>
                ) : (
                  <div style={{ color: "#FF7F33" }} className="top_div_upload">
                    <div className="upload_img_summary">
                      <p className="upload_img_summary_p1 w-[200px] truncate">
                        {props?.files?.name}
                      </p>
                      <p className="upload_img_summary_p2">
                        {" "}
                        Uploaded file size : {formatBytes(props?.files?.size)}
                      </p>
                    </div>
                    <img
                      src={CircleCancel}
                      className="upload_cancel"
                      alt="cancel"
                      onClick={() => {
                        props?.setFiles([]);
                        props?.setShowImages("");
                        const { [field?.name]: _, ...updatedData } =
                          props?.additionalData;
                        props?.setAdditionalData(updatedData);
                        props?.setTxnPayLoad((prevState) => ({
                          ...prevState,
                          mergeCategory: prevState.mergeCategory?.map((item) =>
                            item.data_type === "upload"
                              ? { ...item, value: "" }
                              : item
                          ),
                        }));
                      }}
                    />
                  </div>
                )}
                <input
                  type="file"
                  onClick={(event) => {
                    event.target.value = null;
                  }}
                  onChange={(e) => {
                    const selectedFiles = e.target.files[0];
                    const maxSize = 5 * 1024 * 1024;
                    if (selectedFiles.size < maxSize) {
                      props?.setFiles(selectedFiles);
                      if (selectedFiles) {
                        const reader = new FileReader();
                        reader.onload = () => {
                          props?.setShowImages(reader.result);
                        };
                        reader.readAsDataURL(selectedFiles);
                      }
                      handleInputChange({
                        target: {
                          name: field?.name,
                          value: e.target.files[0],
                          data_typeChan: field?.data_type,
                        },
                      });
                    } else {
                      toast.error("Maximum file size is 5mb", toast_position);
                    }
                  }}
                  style={{ display: "none" }}
                  id="file-input"
                />
              </article>
            </article>
          );
        case "date":
          return (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "5px",
              }}
            >
              <span
                id="uploadId"
                style={{
                  color: props?.isSingleDateSelected ? "#00FF00" : "#DADADA",
                }}
              >
                {`${field.name}${isFieldRequired ? "*" : ""}${optionalLabel}`}
              </span>
              <div
                className="bene_acc_Calendar_main_div relative flex flex-col items-center cursor-pointer"
                style={{
                  // width: "305px",
                  height: props?.showDatePickerCal ? "360px" : "35px",
                  border: props?.showDatePickerCal
                    ? "1px solid #ff5860"
                    : "none",
                }}
              >
                <div
                  className="flex flex-row items-center justify-between rounded-[10px] cursor-pointer"
                  style={{
                    width: "100%",
                    height: props?.showDatePickerCal ? "" : "35px",
                    // border: props?.showDatePickerCal
                    //   ? "none"
                    //   : "1px solid #ff5860",
                  }}
                  onClick={(e) => {
                    props?.setShowDatePickerCal((prevState) => !prevState);
                    setdropdownOpen(false);
                    e.stopPropagation();
                  }}
                >
                  <div
                    className="beneAcc_date_heading_div cursor-pointer"
                    style={{
                      borderTopLeftRadius: props?.showDatePickerCal
                        ? "7px"
                        : "none",
                      borderLeft: props?.showDatePickerCal
                        ? "1px solid #ff5860"
                        : "none",
                    }}
                  >
                    <div className="beneAcc_date_heading_leftm">
                      {" "}
                      {props?.startDateCal
                        ? formatDate(props?.startDateCal)
                        : "Select date"}
                    </div>
                  </div>
                </div>
                {props?.showDatePickerCal && (
                  <DatePickerComponent
                    startDate={props?.startDateCal}
                    handleDateChange={handleDateChangeCal}
                    handleCancel={() =>
                      handleCancelCal(field?.name, field?.data_type)
                    }
                    handleConfirm={() =>
                      handleConfirmCal(field?.name, field?.data_type)
                    }
                    showDatePicker={props?.showDatePickerCal}
                    yearDropdownRef={yearDropdownRefCal}
                    setShowDatePicker={props?.setShowDatePickerCal}
                    type={"single"}
                  />
                )}
              </div>
            </div>
          );
        case "multi_select_list":
          return (
            <ActionDrpDown
              type={"checkbox"}
              ddlValue={field?.data}
              mandatory={field?.mandatory}
              optionalLabel={optionalLabel}
              isFieldRequired={isFieldRequired}
              fieldname={field?.name}
              handleInputChange={handleInputChange}
              data_type={field?.data_type}
              setShowDatePicker={props?.setShowDatePicker}
              value={field?.value || "Select"}
            />
          );
        case "year":
          return (
            <ActionDrpDown
              ddlValue={years}
              mandatory={field?.mandatory}
              optionalLabel={optionalLabel}
              isFieldRequired={isFieldRequired}
              handleInputChange={handleInputChange}
              data_type={field?.data_type}
              fieldname={field?.name}
              setShowDatePicker={props?.setShowDatePicker}
              value={field?.value || "Select"}
            />
          );

        case "quantity":
          return (
            <div className="w-[100%]">
              <div
                id="uploadId"
                style={{ color: props?.quantity <= 0 ? "#DADADA" : "#00FF00" }}
              >
                {" "}
                {`${field.name}${isFieldRequired ? "*" : ""}${optionalLabel}`}
              </div>
              <div className="relative mt-[5px]">
                <input
                  type="number"
                  className="quantityInput w-[100%] shadow-lg"
                  value={props?.quantity}
                  placeholder="0"
                  min="0"
                  step="1"
                  onChange={(e) =>
                    handleQuantityChange(e, field?.name, field?.data_type)
                  }
                />
                <span className="inputBtn flex flex-col absolute gap-[1px]">
                  <button
                    className="flex incrementBtn justify-center rounded-tr-[10px]"
                    style={{
                      boxShadow: isIncMouseDown ? "2px 2px #868686 inset" : "",
                    }}
                    onClick={() => increment(field?.data_type, field.name)}
                    onMouseDown={() => setIsIncMouseDown(true)}
                    onMouseUp={() => setIsIncMouseDown(false)}
                  >
                    <img
                      src={arrowup_filled}
                      alt="arrow-up"
                      className="w-[20px]"
                    />
                  </button>
                  <button
                    className={`decrementBtn flex justify-center rounded-br-[10px]`}
                    style={{
                      boxShadow: isDecMouseDown ? "2px 2px #868686 inset" : "",
                    }}
                    onClick={() => decrement(field?.data_type, field.name)}
                    onMouseDown={() => setIsDecMouseDown(true)}
                    onMouseUp={() => setIsDecMouseDown(false)}
                  >
                    <img
                      src={arrowdown_filled}
                      alt="arrow-down"
                      className="w-[20px]"
                    />
                  </button>
                </span>
              </div>
            </div>
          );

        case "from_year_to_year":
          return (
            <div
              style={{
                display: "flex",
                flexDirection: isMobile ? "column" : "row",
                gap: "20px",
              }}
            >
              <BeneMultiDropDrown
                title={`From Year`}
                ddlValue={years}
                mandatory={field?.mandatory}
                optionalLabel={optionalLabel}
                isFieldRequired={isFieldRequired}
                handleInputChange={handleInputChange}
                data_type={field?.data_type}
                fieldname={field?.name}
                selectedValue={props?.selectedValue}
                setSelectedValue={props?.setSelectedValue}
                setShowDatePicker={props?.setShowDatePicker}
                setAdditionalData={props.setAdditionalData}
                setTxnPayLoad={props.setTxnPayLoad}
                additionalData={props.additionalData}
              />
              <BeneMultiDropDrown
                title={`To Year`}
                ddlValue={years}
                mandatory={field?.mandatory}
                optionalLabel={optionalLabel}
                isFieldRequired={isFieldRequired}
                handleInputChange={handleInputChange}
                data_type={field?.data_type}
                fieldname={field?.name}
                selectedValue={props?.selectedValue}
                setSelectedValue={props?.setSelectedValue}
                setShowDatePicker={props?.setShowDatePicker}
                setAdditionalData={props.setAdditionalData}
                setTxnPayLoad={props.setTxnPayLoad}
                additionalData={props.additionalData}
              />
            </div>
          );

        case "month_year":
          return (
            <div
              style={{
                display: "flex",
                flexDirection: isMobile ? "column" : "row",
                gap: "20px",
              }}
            >
              <BeneMonthYearSelect
                title={`Month`}
                ddlValue={months}
                mandatory={field?.mandatory}
                optionalLabel={optionalLabel}
                isFieldRequired={isFieldRequired}
                handleInputChange={handleInputChange}
                data_type={field?.data_type}
                fieldname={field?.name}
                selectedValue={props?.selectedValueMonth}
                setSelectedValue={props?.setSelectedValueMonth}
                setShowDatePicker={props?.setShowDatePicker}
              />
              <BeneMonthYearSelect
                title={`Year`}
                ddlValue={years}
                mandatory={field?.mandatory}
                optionalLabel={optionalLabel}
                isFieldRequired={isFieldRequired}
                handleInputChange={handleInputChange}
                data_type={field?.data_type}
                fieldname={field?.name}
                selectedValue={props?.selectedValueMonth}
                setSelectedValue={props?.setSelectedValueMonth}
                setShowDatePicker={props?.setShowDatePicker}
              />
            </div>
          );

        case "date_range":
          return (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "5px",
              }}
            >
              <span
                id="uploadId"
                style={{
                  color: props?.dateRangeEnabled ? "#00FF00" : "#DADADA",
                }}
              >
                {`${field.name}${isFieldRequired ? "*" : ""}${optionalLabel}`}
              </span>
              <div
                className="bene_acc_Calendar_main_div relative flex flex-col items-center cursor-pointer"
                style={{
                  // width: "305px",
                  height: props?.showDatePicker ? "360px" : "35px",
                  border: props?.showDatePicker ? "1px solid #ff5860" : "none",
                }}
              >
                <div
                  className="flex flex-row items-center justify-between rounded-[10px] bg-[#667CA3] cursor-pointer"
                  style={{
                    width: "100%",
                    height: props?.showDatePicker ? "" : "35px",
                    // border: props?.showDatePicker
                    //   ? "none"
                    //   : "1px solid #ff5860",
                    // borderRadius: showDatePicker ? "10px" : "auto",
                  }}
                  onClick={(e) => {
                    props?.setShowDatePicker((prevState) => !prevState);
                    setdropdownOpen(false);
                    e.stopPropagation();
                  }}
                  // style={{ opacity: dropdownOpen ? 0 : 1 }}
                >
                  <div
                    className="beneAcc_date_heading_div cursor-pointer"
                    style={{
                      borderTopLeftRadius: props?.showDatePicker
                        ? "7px"
                        : "none",
                      borderLeft: props?.showDatePicker
                        ? "1px solid #ff5860"
                        : "none",
                    }}
                    // onClick={() => setShowDatePicker((prev) => !prev)}
                  >
                    <div className="beneAcc_date_heading_leftm">
                      {" "}
                      {props?.startDate
                        ? formatDate(props?.startDate)
                        : "Select from date"}
                    </div>
                  </div>
                  <img
                    src={arrowforward}
                    className="bene_acc_viewall_calendar_icon"
                  />
                  <div
                    className="beneAcc_date_select_div cursor-pointer"
                    style={{
                      borderTopRightRadius: props?.showDatePicker
                        ? "7px"
                        : "none",
                      borderRight: props?.showDatePicker
                        ? "1px solid #ff5860"
                        : "none",
                    }}
                  >
                    <div className="beneAcc_date_heading_rightm">
                      {props?.endDate
                        ? formatDate(props?.endDate)
                        : "Select to date"}
                    </div>
                  </div>
                </div>
                {props?.showDatePicker && (
                  <DatePickerComponent
                    startDate={props?.startDate}
                    endDate={props?.endDate}
                    handleDateChange={handleDateChange}
                    handleCancel={() =>
                      handleCancel(field?.name, field?.data_type)
                    }
                    handleConfirm={() =>
                      handleConfirm(field?.name, field?.data_type)
                    }
                    showDatePicker={props?.showDatePicker}
                    yearDropdownRef={yearDropdownRef}
                    setShowDatePicker={props?.setShowDatePicker}
                  />
                )}
              </div>
            </div>
          );
        default:
          return null;
      }
    });
  };

  const [isMobile, setIsMobile] = useState(window.innerWidth <= 800);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 800);
      setWindowWidth(window.innerWidth);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [window.innerWidth]);

  return (
    <>
      <div className="beneContact_Main">
        <div
          className="divideAmount"
          style={{ width: isMobile ? `${windowWidth}px` : "" }}
        >
          <div className="w-1/2 h-fit contactright">
            <div className="flex flex-col relative">
              <div className="py-6 h-full bg-[#338A3E] flex flex-col rounded-t-4xl rounded-b-4xl">
                <div className="w-full flex flex-col pb-2 gap-7">
                  <NumericFormat
                    name="amount"
                    customInput={TextField}
                    thousandSeparator={true}
                    thousandsGroupStyle="lakh"
                    onChange={handleAmountChange}
                    value={props.txnPayLoad.amount}
                    decimalScale={2}
                    isAllowed={(values) => {
                      if (!values.value) return true;
                      const { floatValue, value } = values;
                      if (value[0] == 0) {
                        return false;
                      }
                      return allowedValue(String(floatValue));
                    }}
                    inputProps={{
                      placeholder: "Enter amount",
                      style: {
                        padding: "4px",
                        fontSize: "32px",
                        outline: "none",
                        font: "normal normal normal 32px/43px Open Sans",
                        color: "#FFFFFF",
                      },
                      className: "text-center ",
                    }}
                    InputProps={{
                      disableUnderline: true,
                      startAdornment: showRupeeSymbol && (
                        <InputAdornment position="start">
                          <span
                            style={{
                              fontSize: "32px",
                              color: "#ffffff",
                              marginRight: "-30px",
                            }}
                          >
                            &#8377;
                          </span>
                        </InputAdornment>
                      ),
                      style: {
                        outline: "none",
                        boxShadow: "none",
                        borderBottom: "2px solid #DADADA",
                        borderRadius: "0px",
                      },
                    }}
                    sx={{
                      border: "none",
                      outline: "none",
                      mx: "auto",
                      width: "70%",
                      "& input::placeholder": {
                        color: "white !important",
                      },
                      "& input": {
                        height: "auto",
                        color: "white",
                      },
                      "& .MuiOutlinedInput-root": {
                        "& fieldset": {
                          border: "none",
                        },
                        "&.Mui-focused fieldset": {
                          border: "none",
                        },
                      },
                    }}
                  />
                </div>
                <div className="text-[#f9f9f9] text-sm mt-2 w-full flex flex-col px-[75px] capitalize ">
                  {props?.amountInWords && (
                    <>
                      <div className="capitalize">
                        {props?.amountInWords}
                        <br />
                      </div>
                      <span className="text-[#DADADA] text-xs mt-[5px]">
                        In Words
                      </span>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
          {showInvoiceInfo && (
            <div className="w-1/2 contactright">
              <div className="flex flex-col">
                <div className="bg-[#314C7F] text-[#DADADA] font-semibold text-base flex flex-row justify-between py-4 pb-0 mt-4 px-8 rounded-t-4xl 4xl:rounded-t-large items-center">
                  <div className="text-[#DADADA] font-semibold text-base flex flex-row items-center gap-2">
                    <img src={AssociateInfo} alt="" className="w-4 h-4" />
                    <p>Invoice number in use</p>
                  </div>
                </div>
                <p className="py-2 pb-0 px-8 bg-[#314C7F] text-[#DADADA] font-normal text-xs">
                  Looks like this invoice number is already in use for some
                  other transaction/s.
                </p>
              </div>
              <div className="rounded-b-4xl rounded-large 4xl:rounded-customXl 5xl:rounded-custom2Xl">
                <div
                  className="bg-[#314C7F] py-5 h-full flex flex-col rounded-b-4xl"
                  style={{ height: "240px" }}
                >
                  <div className="w-full flex flex-col px-8 gap-4 overflow-auto h-72 custom-scroll-container">
                    <div className="text-justify flex flex-col gap-6">
                      {haveApproved ? (
                        <>
                          {haveApproved && (
                            <div className="">
                              {beneLookData?.beneficiary_accounts.map(
                                (val, i) => {
                                  if (val?.approval_status === "approved") {
                                    return (
                                      <Button
                                        style={{
                                          backgroundColor: "#1D3A6D",
                                        }}
                                        className="child_scroll"
                                        key={i}
                                      >
                                        <div className="bank_name">
                                          <img
                                            src={require("../../../assets/IfscLogo/" +
                                              IfscCodeMapper(val?.ifsc_code))}
                                            alt="bank"
                                          />
                                        </div>
                                        <div className="client_name">
                                          <p
                                            className="name_client2"
                                            style={{
                                              color: "#4DDD37",
                                            }}
                                          >
                                            hello
                                          </p>
                                          <p className="bank_client2">
                                            Bank account
                                            <span className="bank_no">
                                              - Bank account number
                                            </span>
                                          </p>
                                        </div>
                                        <div className="viewProfile">
                                          <button
                                          // onClick={(e) => {
                                          //   e.stopPropagation();
                                          //   navigate(
                                          //     `/accounts/beneficiary_account/details/${val?.id}`
                                          //   );
                                          // }}
                                          >
                                            <img
                                              src={ViewDetails}
                                              alt="viewdetails"
                                            />
                                          </button>
                                          <p>View details</p>
                                        </div>
                                      </Button>
                                    );
                                  }
                                }
                              )}
                            </div>
                          )}
                        </>
                      ) : null}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
        <div
          className="divideAmount hide-scroll-bar"
          style={{ width: isMobile ? `${windowWidth}px` : "" }}
        >
          <div className=" rounded-b-4xl rounded-large">
            <div className="bg-[#314C7F] py-7 h-full flex flex-col px-5 rounded-b-4xl rounded-t-4xl items-start">
              <div className="text-[#DADADA] font-semibold text-base flex flex-row justify-between">
                <p className="text-center pb-5 px-4">Additional Details</p>
              </div>

              <div
                className=" w-full flex flex-col gap-5 px-4 pb-2 pt-1"
                // style={{ overflowY: "auto" }}
              >
                {renderInputFields()}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default BeneTxnAmount;
