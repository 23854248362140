import React, { useEffect, useState } from "react";
import "./index.css";
import { beneCreateAccount } from "../../../redux/features/accounts/BeneficiaryAccount/createAccount";
import { Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { beneCategories } from "../../../redux/features/beneficiary/beneAllVerification/beneAllVerification";
import { toast } from "react-toastify";
import { toast_position } from "../../../redux/features/Settings/Authorization";
import { checkUsertoken } from "../../../redux/features/login/login";

const BeneficiaryStepper = ({
  activeStep,
  handleBack,
  steps,
  handleNext,
  nextButtonEnabled,
  isClicked,
  payload,
  selectedFields,
  isButtonDisabled,
  beneLookError,
  setShowModalPopup,
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(checkUsertoken());
    dispatch(beneCategories());
  }, [dispatch]);

  const [createDisabled, setCreateDisabled] = useState(false);
  const categories = useSelector(
    (state) => state.beneAllVerification.beneTransCategories.categories
  );
  const benebankLookData = useSelector(
    (state) => state.beneAllVerification.beneLookData
  );

  const handleOpen = () => {
    setShowModalPopup(true);
  };

  const handleCreateAccount = async () => {
    setCreateDisabled(true);
    try {
      let updatedPayload = { ...payload, navigate };
      if (
        selectedFields.length === categories.length ||
        selectedFields.length === 0
      ) {
        updatedPayload.categories = null;
      } else {
        updatedPayload = {
          ...updatedPayload,
          categories: selectedFields.toString(),
        };
      }
      dispatch(checkUsertoken());
      const { payload: data } = await dispatch(
        beneCreateAccount(updatedPayload)
      );
      const accountId = data.beneficiary_accounts[0].id;
      navigate(`/accounts/beneficiary_account/success/${accountId}`, {
        replace: true,
      });
    } catch (error) {
      console.error("Error creating account:", error);
      toast.error(error.base, toast_position);
    }
    setTimeout(() => {
      setCreateDisabled(false);
    }, 500);
  };

  const { beneLookData } = useSelector((state) => state.verify);

  const bankData = useSelector(
    (state) => state.beneAllVerification.beneLookData
  );

  let haveApproved = beneLookData?.beneficiary_accounts.find((val) => {
    return val?.approval_status === "approved";
  });

  let havePending = beneLookData?.beneficiary_accounts.find((val) => {
    return val?.approval_status === "pending";
  });
  let haveBankApproved = bankData?.beneficiary_accounts.find((val) => {
    return val?.approval_status === "approved";
  });

  let haveBankPending = bankData?.beneficiary_accounts.find((val) => {
    return val?.approval_status === "pending";
  });

  const handleMainCreate = () => {
    if (benebankLookData?.status === undefined && beneLookError === true) {
      handleCreateAccount();
      sessionStorage.setItem("Create BeneAcc Fresh PAN", false);
      sessionStorage.setItem("Create BeneAcc Fresh Bank", false);
      sessionStorage.removeItem("Create BeneAcc Approve and Decline status");
    } else if (
      !haveApproved &&
      !havePending &&
      !haveBankApproved &&
      !haveBankPending
    ) {
      handleCreateAccount();
      sessionStorage.setItem("Create BeneAcc Fresh PAN", false);
      sessionStorage.setItem("Create BeneAcc Fresh Bank", false);
      sessionStorage.removeItem("Create BeneAcc Approve and Decline status");
    } else {
      handleOpen();
      sessionStorage.removeItem("Create BeneAcc Approve and Decline status");
    }
  };

  const handleEnterKeyPress = (e) => {
    if (e.key === "Enter" && activeStep === 3) {
      handleMainCreate();
    }
    if (e.code == "Space") {
      // alert("inside space");
      setShowModalPopup(false);
    }
  };

  useEffect(() => {
    document.addEventListener("keydown", handleEnterKeyPress);
    return () => {
      document.removeEventListener("keydown", handleEnterKeyPress);
    };
  }, []);

  const [isMobile, setIsMobile] = useState(window.innerWidth <= 800);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 800);
      setWindowWidth(window.innerWidth);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [window.innerWidth]);

  return (
    <div
      className="bene_Stepper_Main"
      style={{ width: `${windowWidth}px`, maxWidth: "1024px" }}
    >
      <Button
        disabled={activeStep === 0 || isButtonDisabled}
        className={`custom_create ${
          activeStep === 0 || isButtonDisabled ? "inactive" : "active"
        }`}
        style={{
          backgroundColor: "#506994",
          color: "#FFFFFF",
          opacity: activeStep === 0 ? "0.4" : "1",
        }}
        sx={{ borderRadius: 0 }}
        onClick={handleBack}
      >
        Previous
      </Button>
      <div className="stepperline_Div_Create">
        {steps?.map((val, index) => {
          return (
            <div className="stepinnerline" key={index}>
              <div
                className={
                  activeStep === index && isClicked
                    ? "stepDetails growing-element"
                    : "stepDetails"
                }
                style={{
                  opacity: activeStep === index ? "1" : "0",
                }}
              >
                {activeStep === index ? val : "Hello"}
              </div>
              <div
                className={
                  activeStep === index && isClicked
                    ? "main_Line growing-element"
                    : "main_Line"
                }
                style={{
                  width: isMobile
                    ? activeStep === index
                      ? "100%"
                      : "50%"
                    : activeStep === index
                    ? "220px"
                    : "100px",
                  opacity: activeStep === index ? "1" : "0.5",
                }}
              ></div>
            </div>
          );
        })}
      </div>
      {activeStep === 3 ? (
        <Button
          className={`create_Button ${
            activeStep === 3 ? "active" : "inactive"
          }`}
          disabled={createDisabled}
          onClick={handleMainCreate}
          sx={{ borderRadius: 0 }}
        >
          Create
        </Button>
      ) : (
        <Button
          disabled={nextButtonEnabled || isButtonDisabled}
          className={`custom_create_next ${
            nextButtonEnabled || isButtonDisabled ? "inactive" : "active"
          }`}
          style={{
            backgroundColor: nextButtonEnabled ? "#656565" : "#F9F9F9",
            color: nextButtonEnabled ? "#FFFFFF" : "#1D3A6D",
          }}
          sx={{ borderRadius: 0 }}
          onClick={handleNext}
        >
          Next
        </Button>
      )}
    </div>
  );
};

export default BeneficiaryStepper;
