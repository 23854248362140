import { useRef } from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import MenuItemCard from "../MenuItemCard";
import { Typography } from "@mui/material";
import "./index.css";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import checkIfEnable from "../../../utils/checkIfEnable";
import ServiceIDByLabel from "../../../services/ServiceIDByLabel";
import { setCurrentMenu } from "../../../redux/features/hideBreadCrumb/hideBreadCrumb";

const NavbarMenuPopover = (props) => {
  const dispatch = useDispatch();
  const services_list = useSelector((state) => state.refreshToken.services);
  const elementRef = useRef(null);
  const setOpenPopover = props.setOpenPopover;
  const setArrowUp = props.setArrowUp;

  const handleTogglePopover = () => {
    setOpenPopover(false);
    setArrowUp(false);
  };

  return (
    <div
      ref={elementRef}
      className={`popover-main ${props.open ? "open" : "close"}`}
    >
      <Box
        sx={{
          height: "100%",
          maxWidth: "1024px",
          flexGrow: 1,
          boxShadow: "none",
          margin: "auto",
        }}
      >
        <Grid container spacing={2}>
          {props.data.map((transaction, index) =>
            transaction.title !== "Admin Transactions" ? (
              <Grid key={index} item xs={4}>
                <Box sx={{ paddingTop: "5%", paddingLeft: "" }}>
                  <Typography
                    sx={{
                      /* UI Properties */
                      textAlign: "left",
                      fontWeight: "600",
                      letterSpacing: "0px",
                      color: "#042E77",
                      // color:"red",
                      textTransform: "uppercase",
                      opacity: 1,
                    }}
                  >
                    {transaction.title}
                  </Typography>
                  <Box
                    sx={{
                      padding: "2%",
                    }}
                  >
                    {transaction.menuItems.map((menuItem, index) => (
                      <Link
                        to={`/${menuItem.route}`}
                        style={{
                          textDecoration: "none",
                          pointerEvents: checkIfEnable(
                            ServiceIDByLabel,
                            menuItem.label,
                            services_list
                          )
                            ? "auto"
                            : "none",
                        }}
                        onClick={() => {
                          dispatch(setCurrentMenu(menuItem.menu));
                          handleTogglePopover();
                        }}
                      >
                        {" "}
                        <MenuItemCard
                          key={index}
                          icon={menuItem.icon}
                          label={menuItem.label}
                        />
                      </Link>
                    ))}
                  </Box>
                </Box>
              </Grid>
            ) : null
          )}
        </Grid>
        <Typography
          sx={{
            top: "-10%",
            textAlign: "right",
            font: "normal normal normal 10px/12px Open Sans",
            letterSpacing: "0px",
            color: "#202020",
            paddingRight: "5%",
            position: "relative",
            paddingBottom: "10px",
          }}
        >
          *If disabled - the feature is not available for your ID.
        </Typography>
        {props.data[props.data.length - 1].title === "Admin Transactions" && (
          <Box sx={{ paddingTop: "2%", paddingLeft: "2%" }}>
            <Typography
              variant="h5"
              sx={{
                textAlign: "left",
                font: "normal normal 600 16px Open Sans, sans-serif",
                letterSpacing: "0px",
                color: "#042E77",
                textTransform: "uppercase",
                opacity: 1,
              }}
            >
              Admin Transactions
            </Typography>
            <Grid container columns={12} spacing={2}>
              {props.data[props.data.length - 1].menuItems.map(
                (transaction, index) => {
                  return (
                    <Grid key={index} item xs={4}>
                      <Link
                        to={`/${transaction.route}`}
                        style={{
                          textDecoration: "none",
                          pointerEvents: checkIfEnable(
                            ServiceIDByLabel,
                            transaction.label,
                            services_list
                          )
                            ? "auto"
                            : "none",
                        }}
                        onClick={() => {
                          dispatch(setCurrentMenu(transaction.menu));
                          handleTogglePopover();
                        }}
                      >
                        <MenuItemCard
                          key={index}
                          icon={transaction.icon}
                          label={transaction.label}
                        />
                      </Link>
                    </Grid>
                  );
                }
              )}
            </Grid>
          </Box>
        )}
      </Box>
    </div>
  );
};

export default NavbarMenuPopover;
