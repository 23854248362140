import React, { useEffect, useRef, useState } from "react";
import "./index.css";
import { InputAdornment, TextField } from "@mui/material";
import { toast } from "react-toastify";
import Validation from "../../../../utils/validation";
import skippedVerification from "../../../../assets/ErrorIcons/error_with_white_outlined_circle.svg";
import WhiteMail from "../../../../assets/MailIcons/gmail_with_white_circle.svg";
import Mail from "../../../../assets/MailIcons/email_green_verified.svg";
import GreenPhone from "../../../../assets/ContactIcons/contact_green_verified.svg";
import whitePhone from "../../../../assets/ContactIcons/contact_with_white_circle.svg";
import user from "../../../../assets/UserProfileIcons/user.svg";
import { useNavigate, useParams } from "react-router-dom";
import { checkUsertoken } from "../../../../redux/features/login/login";
import { useDispatch, useSelector } from "react-redux";
import { viewAccountDetail } from "../../../../redux/features/verifyPAN/verifyPAN";
import { toast_position } from "../../../../redux/features/Settings/Authorization";
import BeneEditStepper from "../../../../components/BeneEditAccount/BeneEditStepper";
import BenePanEditModal from "../../../../components/BeneEditAccount/BenePanEditModel";
import { getRefreshTokenNew } from "../../../../redux/features/refreshToken/refreshToken";
import User from "../../../../assets/UserProfileIcons/user.svg";
import selectedGreen from "../../../../assets/CheckmarksIcons/selected_with_green_circle.svg";
import notSelectedGrey from "../../../../assets/CheckmarksIcons/not_selected_with_grey_circle.svg";

const BeneEditContact = (props) => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const navigate = useNavigate();
  const steps = ["Edit Contact details"];
  const [contactNumberValidation, setContactNumberValidation] = useState(false);
  const [openEditContactPop, setOpenEditContactPop] = useState(false);
  const [nextButtonEnabled, setNextButtonEnabled] = useState(true);
  const [emailValidation, setEmailValidation] = useState(false);
  const [accountNameValidation, setAccountNameValidation] = useState(true);

  const [customErrors, setCustomErrors] = useState({});

  const [focusState, setFocusState] = useState({});
  const [isblur, setisblur] = useState(false);
  const [payload, setPayload] = useState({
    contact_no: "",
    email_id: "",
    account_name: "",
  });

  const { viewAccountDetailData, viewAccountDetailLoading } = useSelector(
    (state) => state.verify
  );

  const { services, isLoading } = useSelector((state) => state.refreshToken);
  useEffect(() => {
    dispatch(checkUsertoken());

    const fetchContactData = async () => {
      let response = await dispatch(viewAccountDetail({ id: id }));
      if (response?.error?.message === "Request failed with status code 404") {
        navigate("*");
      }
    };
    fetchContactData();
  }, [dispatch, id, navigate]);

  const handleBack = () => {
    if (!nextButtonEnabled) {
      setOpenEditContactPop(true);
    } else {
      navigate(
        `/accounts/${
          props?.internalAccount === "internalAccount"
            ? "internal_account"
            : "beneficiary_account"
        }/details/${id}`
      );
    }
  };

  useEffect(() => {
    if (!viewAccountDetailLoading && viewAccountDetailData?.status === true) {
      const beneficiaryData = viewAccountDetailData?.beneficiary_accounts[0];
      if (beneficiaryData) {
        setPayload((prevPayload) => ({
          ...prevPayload,
          contact_no: beneficiaryData.contact_no,
          email_id: beneficiaryData.email_id,
          account_name: beneficiaryData.name,
        }));
      }
    }
  }, [viewAccountDetailData, viewAccountDetailLoading, dispatch]);

  useEffect(() => {
    setContactNumberValidation(
      Validation.allowedContactNum(payload.contact_no)
    );
    setEmailValidation(Validation.allowedEmail(payload.email_id));
  }, [payload.contact_no, payload.email_id]);

  const emailRegex = /^[\w-\.]+@([\w-]+\.)+[\w-]+$/;

  const modifyValue = (type, value) => {
    if (type === "name") {
      return value?.replace(/(\w)(\w*)/g, function (g1, g2) {
        return g1.toUpperCase() + g2.toLowerCase();
      });
    } else if (type === "gstin") {
      return value?.toUpperCase();
    } else if (type === "account_name") {
      return value;
    } else {
      return value.trim();
    }
  };

  const showErrors = (name) => {
    let errorMessage = customErrors[name];
    if (typeof errorMessage === "string") {
      errorMessage = { "Wrong input": [errorMessage] };
    }
    Object.entries(errorMessage)?.forEach(([errorType, errorMsg]) => {
      toast.error(errorMsg[0], toast_position);
    });
  };

  const allowedValue = (name, value) => {
    if (name === "contact_no") return Validation.allowedContactNum(value);
    if (name === "email_id") return Validation.allowedEmail(value);

    return true;
  };

  const validateAndRecordError = (name, value) => {
    let errors = { ...customErrors };
    if (name === "email_id")
      errors = Validation.validateEmail(name, value, errors);
    if (name === "contact_no")
      errors = Validation.validateContactNum(name, value, errors);
    if (name === "account_name")
      errors = Validation.validateAccountName(name, value, errors);
    setCustomErrors(errors);
  };

  const checkAllValidations = () => {
    return contactNumberValidation && emailValidation;
  };

  const handleUpdateLegalName = () => {
    const beneficiaryData = viewAccountDetailData?.beneficiary_accounts[0];
    setPayload((prevPayload) => ({
      ...prevPayload,
      account_name: beneficiaryData.pan_legal_name,
    }));
  };

  useEffect(() => {
    if (!viewAccountDetailLoading && viewAccountDetailData?.status === true) {
      const beneficiaryData = viewAccountDetailData?.beneficiary_accounts[0];
      if (
        beneficiaryData?.contact_no !== payload.contact_no ||
        beneficiaryData?.email_id !== payload.email_id.toLowerCase() ||
        (beneficiaryData?.name.trim().toLowerCase() !=
          payload.account_name.trim().toLowerCase() &&
          payload.account_name.length !== 0)
      ) {
        if (
          !(checkAllValidations() && payload.email_id?.length !== 0) ||
          !(checkAllValidations() && payload.contact_no?.length !== 0) ||
          !payload.account_name.trim().length > 0
        ) {
          setNextButtonEnabled(true);
        } else {
          setNextButtonEnabled(false);
        }
      } else {
        setNextButtonEnabled(true);
      }
    }
  }, [payload.account_name, payload.contact_no, payload.email_id]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    const newValue = modifyValue(
      name,
      name !== "account_name" ? value.replace(/[^a-zA-Z0-9@.]/g, "") : value
    );

    if (allowedValue(name, value)) {
      validateAndRecordError(name, newValue);
      setPayload((prevState) => ({
        ...prevState,
        [name]: newValue,
      }));

      if (value.trim() === "" && value.length === 1) {
        return;
      }
      if (name === "contact_no") {
        if (
          name === "contact_no" &&
          (newValue.length === 0 || newValue.length < 10)
        ) {
          setContactNumberValidation(false);
        } else {
          setContactNumberValidation(true);
        }
      }
      if (name === "email_id") {
        if (
          name === "email_id" &&
          (newValue.length === 0 || !emailRegex.test(newValue))
        ) {
          setEmailValidation(false);
        } else {
          setEmailValidation(true);
        }
      }
      if (name === "account_name") {
        if (name === "account_name" && newValue.length === 0) {
          setAccountNameValidation(false);
        } else {
          setAccountNameValidation(true);
        }
      }
    }
  };

  useEffect(() => {
    if (customErrors["contact_no"]) setContactNumberValidation(false);
    if (!customErrors["contact_no"] && payload.contact_no?.length === 10)
      setContactNumberValidation(true);
    if (customErrors["email_id"]) setEmailValidation(false);
    if (
      !customErrors["email_id"] &&
      payload.email_id?.length > 0 &&
      emailRegex.test(payload.email_id)
    )
      setEmailValidation(true);
    if (customErrors["account_name"]) setAccountNameValidation(false);
    if (!customErrors["account_name"] && payload.account_name.length > 0)
      setAccountNameValidation(true);
  }, [customErrors]);

  useEffect(() => {
    dispatch(checkUsertoken());
    dispatch(getRefreshTokenNew());
  }, []);

  useEffect(() => {
    if (!isLoading && services != null) {
      if (services?.includes("BUAccount") || services?.includes("Admin")) {
        dispatch(checkUsertoken());
      } else {
        navigate("/feature_not_assigned", {
          state: { unavailableService: "BUAccount" },
        });
      }
    }
  }, [services, isLoading]);

  const getContactInputElementDiv = (
    name,
    status,
    label,
    value,
    maxLength,
    skip
  ) => {
    const getColor = () => {
      if (value?.length > 0) {
        if (status) return "#00FF14";
        if (!status) return "#FF5860";
      }
      if (focusState[name]) return "#31E5E5";
      return "#DADADA";
    };

    const color = getColor();

    const handleFocus = () => {
      setFocusState((prev) => ({ ...prev, [name]: true }));
    };

    const handleBlur = () => {
      setFocusState((prev) => ({ ...prev, [name]: false }));
      setisblur(true);
    };

    return (
      <div className="w-full flex flex-row items-center self-center text-center gap-4">
        <TextField
          className="w-full antialiased text-xl"
          id={`outlined-size-small-${name}`}
          size="small"
          variant="outlined"
          sx={{
            "& label.Mui-focused": {
              color: color,
            },
            "& label": {
              color: color,
            },
            "& .MuiOutlinedInput-root": {
              "& fieldset": {
                borderColor: color,
                outline: "none !important",
              },
              "&:hover fieldset": {
                borderColor: color,
              },
              "&.Mui-focused fieldset": {
                borderColor: color,
              },
            },
          }}
          inputProps={{
            style: { color: "#DADADA" },
            maxLength: maxLength,
          }}
          InputLabelProps={{ style: { color: color } }}
          InputProps={{
            startAdornment: name === "contact_no" && (
              <InputAdornment position="start">
                <div className="text-[#dadada]">+91</div>
              </InputAdornment>
            ),
            endAdornment: (
              <InputAdornment position="end">
                <div className="w-4 h-4">
                  {name === "contact_no" && (
                    <>
                      {value?.length === 0 && (
                        <img
                          src={whitePhone}
                          alt="default-icon"
                          onClick={() => showErrors(name)}
                          className="cursor-pointer w-4 h-4"
                        />
                      )}
                      {value?.length > 0 && !status && (
                        <img
                          src={skippedVerification}
                          alt="Skipped Verification Icon"
                          onClick={() => showErrors(name)}
                          className="cursor-pointer w-4 h-4"
                        />
                      )}
                      {status && (
                        <img
                          src={GreenPhone}
                          alt="Green Check Icon"
                          className="w-4 h-4"
                        />
                      )}
                    </>
                  )}
                </div>
              </InputAdornment>
            ),
          }}
          required
          label={label}
          name={name}
          onBlurCapture={handleBlur}
          onFocus={handleFocus}
          onChange={handleChange}
          value={value}
          autoComplete="off"
        />
      </div>
    );
  };
  const getEmailInputElementDiv = (
    name,
    status,
    label,
    value,
    maxLength,
    skip
  ) => {
    const getColor = () => {
      if (value?.length > 0) {
        if (status) return "#00FF14";
        if (!status) return "#FF5860";
      }
      if (focusState[name]) return "#31E5E5";
      return "#DADADA";
    };

    const color = getColor();

    const handleFocus = () => {
      setFocusState((prev) => ({ ...prev, [name]: true }));
    };

    const handleBlur = () => {
      setFocusState((prev) => ({ ...prev, [name]: false }));
      setisblur(true);
    };

    return (
      <div className="w-full flex flex-row items-center self-center text-center gap-4">
        <TextField
          className="w-full antialiased text-xl"
          id={`outlined-size-small-${name}`}
          size="small"
          variant="outlined"
          sx={{
            "& label.Mui-focused": {
              color: color,
            },
            "& label": {
              color: color,
            },
            "& .MuiOutlinedInput-root": {
              "& fieldset": {
                borderColor: color,
                outline: "none !important",
              },
              "&:hover fieldset": {
                borderColor: color,
              },
              "&.Mui-focused fieldset": {
                borderColor: color,
              },
            },
          }}
          inputProps={{
            style: { color: "#DADADA" },
            maxLength: maxLength,
          }}
          InputLabelProps={{ style: { color: color } }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <div className="w-4 h-4">
                  {name === "email_id" && (
                    <>
                      {value?.length === 0 && (
                        <img
                          src={WhiteMail}
                          alt="White Email Icon"
                          className="w-4 h-4"
                        />
                      )}
                      {value?.length > 0 && !status && (
                        <img
                          src={skippedVerification}
                          alt="Skipped Verification Icon"
                          onClick={() => showErrors(name)}
                          className="cursor-pointer w-4 h-4"
                        />
                      )}
                      {status && (
                        <img
                          src={Mail}
                          alt="Green Check Icon"
                          className="w-4 h-4"
                        />
                      )}
                    </>
                  )}
                </div>
              </InputAdornment>
            ),
          }}
          required
          label={label}
          name={name}
          onBlurCapture={handleBlur}
          onFocus={handleFocus}
          onChange={handleChange}
          value={value}
          autoComplete="off"
        />
      </div>
    );
  };

  const getAccountNameInputElementDiv = (
    name,
    status,
    label,
    value,
    maxLength,
    skip
  ) => {
    const getColor = () => {
      if (value?.length > 0) {
        if (status) return "#00FF14";
        if (!status) return "#00FF14";
      }
      if (focusState[name]) return "#31E5E5";
      return "#DADADA";
    };

    const color = getColor();

    const handleFocus = () => {
      setFocusState((prev) => ({ ...prev, [name]: true }));
    };

    const handleBlur = () => {
      setFocusState((prev) => ({ ...prev, [name]: false }));
      setisblur(true);
    };

    return (
      <div className="w-full flex flex-row items-center self-center text-center gap-4">
        <TextField
          className="w-full antialiased text-xl"
          id={`outlined-size-small-${name}`}
          size="small"
          variant="outlined"
          sx={{
            "& label.Mui-focused": {
              color: color,
            },
            "& label": {
              color: color,
            },
            "& .MuiOutlinedInput-root": {
              "& fieldset": {
                borderColor: color,
                outline: "none !important",
              },
              "&:hover fieldset": {
                borderColor: color,
              },
              "&.Mui-focused fieldset": {
                borderColor: color,
              },
            },
          }}
          inputProps={{
            style: { color: "#DADADA" },
            maxLength: maxLength,
          }}
          InputLabelProps={{ style: { color: color } }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <div className="w-4 h-4">
                  {name === "account_name" && (
                    <>
                      {/* {value?.length === 0 && ( */}
                      <img src={User} alt="Account Name" className="w-4 h-4" />
                      {/* )} */}
                      {/* {value?.length > 0 && !status && (
                        <img
                          src={skippedVerification}
                          alt="Skipped Verification Icon"
                          onClick={() => showErrors(name)}
                          className="cursor-pointer w-4 h-4"
                        />
                      )}
                      {status && (
                        <img
                          src={User}
                          alt="Account Name"
                          className="w-4 h-4"
                        />
                      )} */}
                    </>
                  )}
                </div>
              </InputAdornment>
            ),
          }}
          required
          label={label}
          name={name}
          onBlurCapture={handleBlur}
          onFocus={handleFocus}
          onChange={handleChange}
          value={value}
          autoComplete="off"
        />
      </div>
    );
  };

  return (
    <div className="edit_contactDetails_main">
      {!nextButtonEnabled && openEditContactPop && (
        <BenePanEditModal
          message2={"Are you sure you want to leave this page?"}
          message={"Leaving this page will not update the changes you made"}
          id={id}
          close={setOpenEditContactPop}
          internalAccount={props?.internalAccount}
        />
      )}
      <div className="main_edit_stepper_div">
        <BeneEditStepper
          type={"single"}
          steps={steps}
          activeStep={0}
          id={id}
          nextButtonEnabled={nextButtonEnabled}
          useFor="contact"
          ifsc={viewAccountDetailData?.beneficiary_accounts[0]?.ifsc_code}
          bank={viewAccountDetailData?.beneficiary_accounts[0]?.bank_acc_no}
          pan={viewAccountDetailData?.beneficiary_accounts[0]?.pan_no}
          contact={payload.contact_no}
          email={payload?.email_id?.toLowerCase()}
          account_name={payload?.account_name}
          internalAccount={props?.internalAccount}
          disType={"gst"}
          handleBackContact={handleBack}
        />
      </div>
      <div className="flex flex-row">
        <div className="dividecontactDetails">
          <div className="w-1/2 h-fit contactright pb-4">
            <div className={` flex flex-col relative rounded-[31px]`}>
              <div className="py-5 h-full bg-[#314C7F] flex flex-col rounded-[30px]">
                <div className=" w-full flex flex-col px-4 md:px-6 lg:px-8 pb-1 gap-5">
                  <div className="text-[#DADADA] font-semibold text-base">
                    {props?.internalAccount === "internalAccount"
                      ? `Contact Details`
                      : `Beneficiary's Contact Details`}
                  </div>

                  <>
                    {getAccountNameInputElementDiv(
                      "account_name",
                      accountNameValidation,
                      "Account name",
                      payload.account_name
                    )}
                    <div className="flex flex-row align-center text-[12px] text-[#DADADA] font-normal gap-2.5 mt-[-15px]">
                      <img
                        onClick={() => handleUpdateLegalName()}
                        className="w-[15px] h-[15px] mt-[1px] cursor-pointer"
                        src={
                          viewAccountDetailData?.beneficiary_accounts[0]?.pan_legal_name.toLowerCase() ===
                            payload.account_name.toLowerCase() &&
                          payload.account_name.length !== 0
                            ? selectedGreen
                            : notSelectedGrey
                        }
                        alt="Select Green"
                      />{" "}
                      Using Legal Name
                    </div>
                  </>

                  {getContactInputElementDiv(
                    "contact_no",
                    contactNumberValidation,
                    "Contact number",
                    payload.contact_no,
                    12
                  )}
                  {getEmailInputElementDiv(
                    "email_id",
                    emailValidation,
                    "Email-ID",
                    payload?.email_id?.toLowerCase()
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="dividecontactDetails"></div>
      </div>
    </div>
  );
};

export default BeneEditContact;
