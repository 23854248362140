import React, { useEffect, useRef, useState } from "react";
import BeneTxnViewall from "../../containers/BeneficiaryTransactions/BeneTxnViewall/index";
import InternalTransactionViewAll from "../../containers/InternalTransactionMain/InternalTransactionViewAll";
import closeIcon from "../../assets/CloseIcons/close.svg";
import ArrowDown from "../../assets/ArrowsIcons/arrow_down_white_filled.svg";
import CheckIcon from "../../assets/CheckmarksIcons/check_mark_white.svg";
import "./index.css";

const TransactionHistory = ({
  bene_account_id,
  bene_account_name,
  owner_account_id,
  owner_account_bank_acc_no,
  txnHistorytype,
  onClose,
  Owner_account_id,
  Owner_account_number,
  OwnerDetail,
  BeneAccId,
}) => {
  const [type, setType] = useState("Beneficiary transaction");
  const [balanceType, setBalanceType] = useState("credit");
  const [typeOpen, setTypeOpen] = useState(false);
  const accountBalanceRef = useRef(null);
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        accountBalanceRef.current &&
        !accountBalanceRef.current.contains(event.target)
      ) {
        setTypeOpen(false);
      }
    };
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);
  return (
    <>
      <div className="transactionHistory_pop_div fixed overflow-hidden rounded-[20px] z-[99999] bg-[#1D3A6D]">
        <div className="h-full w-full">
          <div
            style={{
              display: OwnerDetail ? "flex" : "none",
            }}
            className={`transactionHistoryPop_topDiv ${"flex flex-row justify-between items-center p-5"}`}
          >
            <div className="flex justify-between w-full">
              <div className="flex flex-col space-y-1">
                <p className="font-[16px] leading-[21px] text-[#dadada] font-sans font-semibold">
                  Transaction History :
                </p>
                <p className="text-xs text-[#F9F9F9] font-sans font-normal">
                  All transactions related to the owner account are listed
                  below.
                </p>
              </div>
              <div className="flex gap-x-[20px] w-[400px] relative items-center">
                <div
                  style={{
                    top: "5px",
                    right:
                      type === "Internal transaction" || type === ""
                        ? "190px"
                        : "20px",
                    transition: "0.3s all ease-in-out",
                  }}
                  className="owner_sort_by_acc_balance w-[180px] absolute"
                >
                  <div
                    ref={accountBalanceRef}
                    className="bank_high_to_low_select relative itxn"
                    onClick={() => {
                      setTypeOpen(!typeOpen);
                    }}
                    style={{
                      height: typeOpen ? "108px" : "30px",
                      width: "180px",
                      outline: typeOpen ? "1px solid #E25869" : "",
                      cursor: "pointer",
                    }}
                  >
                    {/* overlay */}
                    <div
                      style={{
                        width: "180px",
                        height: "30px",
                      }}
                      className="itxn_card_hover_overlay"
                    ></div>
                    <div
                      style={{ paddingRight: "15px", height: "29px" }}
                      className="bank_high_to_low_select_select"
                    >
                      <div
                        className="bank_high_to_low_select_scroll_line"
                        style={{
                          display: typeOpen ? "block" : "none",
                          width: "171px",
                        }}
                      ></div>

                      <span
                        style={{
                          color: "#f9f9f9",
                          fontSize: "12px",
                          paddingLeft: "15px",
                        }}
                      >
                        {type == "" ? "Internal transaction" : type}
                      </span>
                      <img
                        src={ArrowDown}
                        alt="down"
                        style={{
                          height: "12px",
                          transform: typeOpen
                            ? "rotate(180deg)"
                            : "rotate(0deg)",
                        }}
                      />
                    </div>
                    <div
                      style={{ padding: "5px 5px 0 5px", cursor: "pointer" }}
                      className="bank_high_to_low_select_scroll"
                    >
                      <div
                        className="bank_high_to_low_select_scroll_com hover:bg-[#506994]"
                        style={{
                          padding: "0px 10px",
                          width: "170px",
                          backgroundColor:
                            type === "Beneficiary transaction" ? "#3A5686" : "",
                        }}
                        onClick={(e) => {
                          e.stopPropagation();

                          setType("Beneficiary transaction");

                          setTypeOpen(false);
                        }}
                      >
                        Beneficiary transaction
                        <div
                          style={{
                            display:
                              type === "Beneficiary transaction"
                                ? "flex"
                                : "none",
                            textAlign: "right",
                            justifyContent: "end",
                            paddingRight: "1px",
                          }}
                        >
                          <img
                            style={{
                              height: "13px",
                              width: "11px",
                            }}
                            src={CheckIcon}
                            alt="check"
                          />
                        </div>
                      </div>
                      <div
                        className="bank_high_to_low_select_scroll_com hover:bg-[#506994]"
                        style={{
                          padding: "0px 10px",
                          width: "170px",
                          backgroundColor:
                            type === "Internal transaction" ? "#3A5686" : "",
                        }}
                        onClick={(e) => {
                          e.stopPropagation();

                          setType("Internal transaction");

                          setTypeOpen(false);
                        }}
                      >
                        Internal transaction
                        <div
                          style={{
                            display:
                              type === "Internal transaction" ? "flex" : "none",
                            textAlign: "right",
                            justifyContent: "end",
                            paddingRight: "1px",
                          }}
                        >
                          <img
                            style={{
                              height: "13px",
                              width: "11px",
                            }}
                            src={CheckIcon}
                            alt="check"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {type === "Internal transaction" && (
                  <div
                    style={{
                      borderRadius: "20px",
                      backgroundColor: "#314D7E",
                      right: "20px",
                      transition: "0.3s all ease-in-out",
                    }}
                    className="absolute "
                  >
                    <div className="flex justify-end items-center w-[183px] pr-[10px] gap-x-[2px] h-[30px] rounded-[10px] bg-[#314D7E] relative cursor-pointer">
                      <div
                        style={{
                          top: "5px",
                          left: balanceType === "credit" ? "22px" : "100px",
                          transition: "0.2s all ease-in-out",
                        }}
                        className="absolute w-[74px] h-[20px] rounded-[5px] bg-[#f9f9f9]"
                      ></div>

                      <div
                        onClick={() => {
                          setBalanceType("credit");
                          setTimeout(() => {
                            setType("");
                          }, 100);
                          setTimeout(() => {
                            setType("Internal transaction");
                          }, 110);
                        }}
                        style={{
                          fontWeight: balanceType == "credit" ? "600" : "400",
                          color:
                            balanceType == "credit" ? "#1D1D1D" : "#f9f9f9",
                        }}
                        className="relative w-[74px] h-[20px] rounded-[5px] text-[12px] flex justify-center items-center z-[1] itxn"
                      >
                        Credit
                        <div className="itxn_card_hover_overlay"></div>
                      </div>
                      <div
                        onClick={() => {
                          setBalanceType("debit");
                          setTimeout(() => {
                            setType("");
                          }, 100);
                          setTimeout(() => {
                            setType("Internal transaction");
                          }, 110);
                        }}
                        style={{
                          fontWeight: balanceType == "debit" ? "600" : "400",
                          color: balanceType == "debit" ? "#1D1D1D" : "#f9f9f9",
                        }}
                        className=" relative w-[74px] h-[20px] rounded-[5px] text-[12px] flex justify-center items-center z-[1] itxn"
                      >
                        Debit
                        <div className="itxn_card_hover_overlay"></div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div
              className="flex justify-center items-center cursor-pointer w-[30px] h-[30px] rounded-[5px] bg-[#314D7E] hover:bg-[#506994]"
              onClick={() => onClose()}
            >
              <img src={closeIcon} className="w-4 h-4" />
            </div>
          </div>
          {txnHistorytype === "internalTxnHistory" ||
          type === "Internal transaction" ? (
            <InternalTransactionViewAll
              owner_account_id={
                OwnerDetail ? Owner_account_id : owner_account_id
              }
              owner_account_bank_acc_no={
                OwnerDetail ? Owner_account_number : owner_account_bank_acc_no
              }
              txnHistorytype={
                OwnerDetail ? "ownerDetailTxnHistory" : txnHistorytype
              }
              onClose={onClose}
              OwnerDetail={OwnerDetail}
              balanceType={balanceType}
              BeneAccId={BeneAccId}
            />
          ) : (
            <BeneTxnViewall
              bene_account_name={bene_account_name}
              bene_account_id={bene_account_id}
              onClose={onClose}
              typepop={"transactionHistorypopup"}
              Owner_account_id={Owner_account_id}
              Owner_account_number={Owner_account_number}
              OwnerDetail={OwnerDetail}
            />
          )}
        </div>
      </div>
    </>
  );
};

export default TransactionHistory;
