import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import "./index.css";
import { Box } from "@mui/material";
import ArrowPlay from "../../../assets/ArrowsIcons/arrow_right_blue_filled.svg";
import NotSelect from "../../../assets/CheckmarksIcons/not_selected_with_grey_circle.svg";
import Selected from "../../../assets/CheckmarksIcons/selected_with_green_circle.svg";
import { useNavigate } from "react-router-dom";
import { checkUsertoken } from "../../../redux/features/login/login";
import InternalDeclinePopup from "../../../containers/InternalTransactionMain/InternalTransactionSingleApproval/InternalDeclinePopup";
import InternalTransactionDetails from "../../../containers/InternalTransactionMain/InternalTransactionDetails";
import formatDateViewAll from "../../../utils/formatDateViewAll";
import BeneAccDetailPagePopup from "../../Accounts/BeneAccDetailPagePopup";
import ArrowRightBlue from "../../../assets/ArrowsIcons/arrow_right_blue.svg";
import InternalTxnDetailPagePopup from "../InternalTxnDetailPopup";
import { toast_position } from "../../../redux/features/Settings/Authorization";
import { toast } from "react-toastify";
import {
  resetITMultiApprovalError,
  resetITMultiRejectApprovalData,
} from "../../../redux/features/internalTransaction/internalTransactionSingleApproval";

const InternalTxnMultiApproval = ({
  filterOpen,
  data,
  setAllAccounts,
  setselectAllCard,
  selectedTransactions,
  setSelectedTransactions,
  setTotalAmount,
  setSelectedSendTransactions,
  selectedSendTransactions,
}) => {
  const navigate = useNavigate();
  const [beneAccDetailOpen, setBeneAccDetailOpen] = useState(false);
  const [deniedId, SetdeniedId] = useState(null);
  const [showTxnDetailPop, setShowTxnDetailPop] = useState(false);
  const [BeneTxnDetailsId, SetBeneTxnDetailsId] = useState("");
  const [datas, setDatas] = useState([]);
  const [txnId, setTxnId] = useState(null);
  const [showPopup, setShowPopup] = useState(false);
  const [isMobileResponsive, setIsMobileResponsive] = useState(
    window.innerWidth <= 800
  );
  const [widthCheck, setIswidthCheck] = useState(window.innerWidth <= 950);
  const [sideDrawerDisabled, setSideDrawerDisabled] = useState(false);
  const [showMultiTxnDetailPop, setShowMultiTxnDetailPop] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const dispatch = useDispatch();

  const multitxnApprovedata = data;
  const { beneTxnAccountData } = useSelector(
    (state) => state?.beneTxnViewAccData
  );
  const {
    internalTxnMultiApprovalError = null,
    internalTxnMultiRejectApprovalError = null,
  } = useSelector((state) => state.InternalTxnSingleApproval || {});

  console.log("error checking", internalTxnMultiApprovalError);
  console.log("error checking", internalTxnMultiRejectApprovalError);
  useEffect(() => {
    dispatch(resetITMultiApprovalError());
    dispatch(resetITMultiRejectApprovalData());
  }, [internalTxnMultiApprovalError, internalTxnMultiRejectApprovalError]);

  const handleClosePopup = () => {
    setShowPopup(false);
  };

  const updateAllAccounts = (updatedAccounts) => {
    setAllAccounts(updatedAccounts);
  };
  const openBeneTxnDetailModal = () => {
    setShowTxnDetailPop(true);
  };
  const closeBeneTxnDetailModal = () => {
    setShowTxnDetailPop(false);
  };

  const closeBeneAccountDetailModal = () => {
    setBeneAccDetailOpen(false);
  };
  const openMultiTxnDetailModal = () => {
    setShowMultiTxnDetailPop(true);
  };

  const closeInternalTxnDetailModal = () => {
    setShowMultiTxnDetailPop(false);
  };

  useEffect(() => {
    setDatas({
      ...datas,
      owner_account_balance_last_fetched_at_in_words:
        beneTxnAccountData?.transaction[0]
          ?.owner_account_balance_last_fetched_at_in_words,
      owner_account_balance:
        beneTxnAccountData?.transaction[0]?.owner_account_balance,
      owner_account_balance_last_fetched_at:
        beneTxnAccountData?.transaction[0]
          ?.owner_account_balance_last_fetched_at,
    });
  }, [beneTxnAccountData]);

  const getStatusColor = (status) => {
    switch (status) {
      case "pending":
        return "#30e5e4";
      case "approved":
        return "#4ddd37";
      case "denied":
      case "blocked":
        return "#ff5860";
      case "archived":
        return "#a3a3a3";
      default:
        return "#4ddd37";
    }
  };

  const SideDrawerOpen = async (id, txnId) => {
    SetBeneTxnDetailsId(id);
    setBeneAccDetailOpen(!beneAccDetailOpen);
    // await dispatch(beneTxnAccountDetail(txnId));
  };

  const handleCardClick = (account) => {
    if (account?.beneficiary_account_approval_status === "approved") {
      setselectAllCard(false);
      setSelectedTransactions((prevSelected) => {
        const cardAmount = Number(account?.amount);
        const isSelected = prevSelected.includes(account?.txn_id);

        if (isSelected) {
          setTotalAmount((prevAmount) => prevAmount - cardAmount);
          let data = selectedSendTransactions.filter((val) => {
            return val?.txn_id !== account?.txn_id;
          });
          setSelectedSendTransactions(data);
          return prevSelected.filter((id) => id !== account?.txn_id);
        } else {
          if (prevSelected.length < 20) {
            setTotalAmount((prevAmount) => prevAmount + cardAmount);
            setSelectedSendTransactions((prev) => [...prev, account]);
            return [...prevSelected, account?.txn_id];
          } else {
            return prevSelected;
          }
        }
      });
    }
  };
  useEffect(() => {
    const handleResize = () => {
      setIsMobileResponsive(window.innerWidth <= 800);
      setIswidthCheck(window.innerWidth <= 950);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [window.innerWidth]);

  return (
    <>
      {isMobileResponsive ? (
        <div className="flex m-auto flex-col w-auto">
          {data?.map((account, index) => (
            <Box
              key={index}
              className={`rounded-[10px] shadow-[ -5px -5px 11px #71717133 ] box-border col-span-1 h-[110px] W-[330px] my-[7px] mx-[15px] px-[15px] pt-[10px] relative hover:opacity-80`}
              style={{
                backgroundColor:
                  account?.beneficiary_account_approval_status === "pending"
                    ? "#00000099"
                    : selectedTransactions.includes(account?.txn_id)
                    ? "#4A6D4B"
                    : "#3A5686",
                cursor:
                  filterOpen ||
                  account?.beneficiary_account_approval_status === "pending"
                    ? "default"
                    : "pointer",
                opacity: filterOpen ? "0.1" : "",
              }}
              onClick={() => handleCardClick(account)}
            >
              <div className="overflow-hidden text-ellipsis whitespace-nowrap">
                <div
                  className="itViewAllName"
                  style={{
                    color: getStatusColor(
                      account?.beneficiary_account_approval_status
                    ),
                  }}
                >
                  {account?.beneficiary_account_approval_status ===
                  "pending" ? (
                    <span className="w-[203px] h-[22px] text-left font-normal text-[16px] opacity-[0.7]">
                      Account Approval Pending
                    </span>
                  ) : (
                    <>
                      <span
                        style={{
                          display: "inline-block",
                          fontSize: "16px",
                          paddingRight: "5px",
                          paddingTop: "5px",
                        }}
                      >
                        ₹{" "}
                        {Number(account?.amount)?.toLocaleString("en-IN", {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        })}
                      </span>

                      <span className="w-[16px] h-[16px] pt-[5px]">
                        {selectedTransactions.includes(account?.txn_id) ? (
                          <img className="" src={Selected} alt="" />
                        ) : (
                          <img className="" src={NotSelect} alt="" />
                        )}
                      </span>
                    </>
                  )}
                </div>

                <div className="flex justify-between items-center w-full text-[#F9F9F9] pt-2">
                  <span>
                    {account?.owner_account_bank_acc_no &&
                      account?.owner_account_bank_acc_no.substring(0, 4) +
                        " •••• " +
                        account?.owner_account_bank_acc_no.substring(
                          account?.owner_account_bank_acc_no.length - 4
                        )}
                  </span>
                  <img
                    src={ArrowRightBlue}
                    alt="Arrow"
                    className="mx-2 w-4 h-4"
                  />

                  <span className="text-right">
                    {account?.beneficiary_account_bank_acc_no &&
                      account?.beneficiary_account_bank_acc_no.substring(0, 4) +
                        " •••• " +
                        account?.beneficiary_account_bank_acc_no.substring(
                          account?.beneficiary_account_bank_acc_no.length - 4
                        )}
                  </span>
                </div>
                <div className="text-[#DADADA] text-[10px] font-semibold pt-[10px]">
                  {account?.created_on
                    ? formatDateViewAll(account?.created_on, false, true)
                    : "Not given"}
                </div>
              </div>

              <div
                onClick={(e) => {
                  e.stopPropagation();
                  setDatas(account);
                  if (!sessionStorage.getItem("q2p_token")) {
                    setBeneAccDetailOpen(false);
                    dispatch(checkUsertoken());
                    return;
                  }
                  if (
                    account?.beneficiary_account_approval_status === "pending"
                  ) {
                    setSideDrawerDisabled(false);
                    SideDrawerOpen(
                      "BeneficiaryTxnMultiApprovalPending",
                      account?.beneficiary_account_id,
                      account?.txn_id
                    );
                  } else {
                    setSideDrawerDisabled(false);
                    SideDrawerOpen(
                      "BeneficiaryTxnMultiApproval",
                      account?.beneficiary_account_id,
                      account?.txn_id
                    );
                  }
                }}
                className={`bg-[#f9f9f9] rounded-tl-[10px] rounded-br-[10px] h-[24px] w-[99px] absolute bottom-0 right-0 mb-0 flex gap-1  justify-center`}
                style={{
                  width:
                    account?.beneficiary_account_approval_status === "pending"
                      ? "115px"
                      : "99px",
                }}
              >
                <p
                  className=" text-[#1D3A6D] font-semibold text-[10px] whitespace-nowrap leading-[27px] opacity-100 "
                  onClick={() => {
                    if (
                      account?.beneficiary_account_approval_status === "pending"
                    ) {
                      navigate(
                        `/accounts/beneficiary_account/details/${account?.beneficiary_account_id}`,
                        {
                          state: { type: "txn_single_approval" },
                        }
                      );
                    } else if (
                      account?.beneficiary_account_approval_status ===
                      "approved"
                    ) {
                      navigate(
                        `/transaction/internal_transaction/details/${account?.txn_id}`
                      );
                    }
                  }}
                  style={{
                    cursor:
                      account?.beneficiary_account_approval_status ===
                        "pending" ||
                      account?.beneficiary_account_approval_status ===
                        "approved"
                        ? "pointer"
                        : "default",
                  }}
                >
                  {account?.beneficiary_account_approval_status === "pending"
                    ? "Approve account"
                    : " More details"}
                </p>
                <div className="w-[9px]  pt-2">
                  <img src={ArrowPlay} alt="" />
                </div>
              </div>
            </Box>
          ))}
        </div>
      ) : (
        <div className="itMultiApprovalViewAllCard">
          {showPopup && (
            <div className="fixed top-0 left-0 w-screen h-screen flex items-center justify-center z-[9998] bg-custom bg-opacity-80">
              <InternalDeclinePopup
                onClose={handleClosePopup}
                accountId={deniedId}
                allAccounts={multitxnApprovedata}
                updateAllAccounts={updateAllAccounts}
                status={"Transaction"}
              />
            </div>
          )}
          {showMultiTxnDetailPop && (
            <div className="fixed top-0 left-0 w-screen h-screen z-[9998] bg-custom-backdrop bg-opacity-80">
              <InternalTxnDetailPagePopup
                closeInternalTxnDetailModal={closeInternalTxnDetailModal}
                internal_txn_id={txnId}
                type={"internal_txn_detail_modal"}
                setShowInternalTxnDetailPop={setShowMultiTxnDetailPop}
                error_msg={errorMessage}
                setErrorMessage={setErrorMessage}
              />
            </div>
          )}
          {beneAccDetailOpen && (
            <div className="fixed top-0 left-0 w-screen h-screen z-[9998] bg-custom-backdrop bg-opacity-80">
              <BeneAccDetailPagePopup
                closeBeneAccountDetailModal={closeBeneAccountDetailModal}
                bene_account_id={BeneTxnDetailsId}
                type={"bene_account_detail_modal"}
              />
            </div>
          )}
          {data?.map((account, index) => (
            <Box
              key={index}
              className={`px-4 itMultiSelectViewAllCard relative hover:opacity-80 cursor-pointer`}
              style={{
                backgroundColor:
                  account?.beneficiary_account_approval_status === "pending"
                    ? "#1C212A"
                    : selectedTransactions.includes(account?.txn_id)
                    ? "#4A6D4B"
                    : "#3a5686",
                // cursor:
                //   filterOpen ||
                //   account?.beneficiary_account_approval_status === "pending"
                //     ? "default"
                //     : "pointer",
                cursor: "pointer",
                opacity: filterOpen ? "0.1" : "",
              }}
              onClick={() => handleCardClick(account)}
            >
              <div className="itViewAllUser cursor-pointer">
                <div
                  className="itViewAllName"
                  style={{
                    color: getStatusColor(
                      account?.beneficiary_account_approval_status
                    ),
                  }}
                >
                  {account?.beneficiary_account_approval_status ===
                  "pending" ? (
                    <span className="w-[203px] h-[22px] text-left font-normal text-[16px] opacity-[0.7] cursor-pointer">
                      Account Approval Pending
                    </span>
                  ) : (
                    <>
                      <span
                        style={{
                          display: "inline-block",
                          fontSize: "16px",
                          paddingRight: "5px",
                        }}
                      >
                        ₹{" "}
                        {Number(account?.amount)?.toLocaleString("en-IN", {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        })}
                      </span>

                      <span className="w-[17px] h-[17px]">
                        {selectedTransactions.includes(account?.txn_id) ? (
                          <img className="" src={Selected} alt="" />
                        ) : (
                          <img className="" src={NotSelect} alt="" />
                        )}
                      </span>
                    </>
                  )}
                </div>

                <div
                  className="itViewAllBankAcc"
                  style={{
                    opacity:
                      account?.beneficiary_account_approval_status === "pending"
                        ? "0.7"
                        : "1",
                    fontWeight:
                      account?.beneficiary_account_approval_status === "pending"
                        ? "100"
                        : "600",
                  }}
                >
                  <div className="flex justify-between items-center w-full text-[#F9F9F9]">
                    <span>
                      {" ••••  " +
                        account?.owner_account_bank_acc_no?.substring(
                          account?.owner_account_bank_acc_no?.length - 4
                        )}
                    </span>
                    <img
                      src={ArrowRightBlue}
                      alt="Arrow"
                      className="mx-2 w-4 h-4"
                    />
                    <span className="text-right">
                      {" ••••  " +
                        account?.beneficiary_account_bank_acc_no?.substring(
                          account?.beneficiary_account_bank_acc_no?.length - 4
                        )}
                    </span>
                  </div>
                </div>
                <div className="itViewAllAccountTitle cursor-pointer">
                  {" "}
                  {account?.created_on
                    ? formatDateViewAll(account?.created_on, false, true)
                    : "Not given"}
                </div>
              </div>

              <div
                onClick={(e) => {
                  e.stopPropagation();

                  setDatas(account);

                  if (!sessionStorage.getItem("q2p_token")) {
                    setBeneAccDetailOpen(false);
                    dispatch(checkUsertoken());
                    return;
                  }
                  if (
                    account?.beneficiary_account_approval_status === "pending"
                  ) {
                    setSideDrawerDisabled(false);
                    SideDrawerOpen(
                      // "BeneficiaryTxnMultiApprovalPending",
                      account?.beneficiary_account_id,
                      account?.txn_id
                    );
                  } else {
                    setSideDrawerDisabled(false);
                    openMultiTxnDetailModal();
                    setTxnId(account?.txn_id);
                  }
                }}
                className={`moreDetailsContainer absolute bottom-0 right-0 mb-0 flex flex-row `}
                style={{
                  width:
                    account?.beneficiary_account_approval_status === "pending"
                      ? "117px"
                      : "94px",
                }}
              >
                <p
                  className="text-[#1D3A6D] px-[8px] py-[5px] font-normal leading-[14px] text-[10px]"
                  style={{
                    cursor:
                      account?.beneficiary_account_approval_status ===
                        "pending" ||
                      account?.beneficiary_account_approval_status ===
                        "approved"
                        ? "pointer"
                        : "default",
                  }}
                >
                  {account?.beneficiary_account_approval_status === "pending"
                    ? "Approve account"
                    : " More details"}
                </p>
                <span className=" w-[10px] mt-[8px]">
                  <img src={ArrowPlay} alt="" />
                </span>
              </div>
            </Box>
          ))}
        </div>
      )}
    </>
  );
};

export default InternalTxnMultiApproval;
