import React, { useState, useEffect } from "react";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import { useNavigate } from "react-router-dom";
import { Button, Checkbox, Tooltip, tooltipClasses } from "@mui/material";
import SelectUser from "../../../assets/UserProfileIcons/select_account_orange.svg";
import EditAccount from "../../../assets/EditIcons/edit_orange.svg";
import BeneTxnAccountModal from "./BeneTxnAccountModal";
import BeneIcon from "../../../assets/CategoryIcons/beneficiary_category_default.svg";
import { useDispatch, useSelector } from "react-redux";
import "./index.css";
import AssociateInfo from "../../../assets/InfoIcons/info_filled.svg";
import unassignedImg from "../../../assets/ArrowsIcons/arrow_right_blue – 1.svg";
import arrowRight from "../../../assets/ArrowsIcons/arrow_right_orange.svg";
import notselectedRadio from "../../../assets/RadioIcons/radio_button_not_selected.svg";
import selectedRadio from "../../../assets/RadioIcons/radio_button_selected.svg";
import BeneAssociateAccount from "./BeneAssociateAccount";
import { beneLookUp } from "../../../redux/features/verifyPAN/verifyPAN";
import { beneBankLookUp } from "../../../redux/features/beneficiary/beneAllVerification/beneAllVerification";
import { formatAccountNumber } from "../../_utils/FormatAccountNumber";
import { checkUsertoken } from "../../../redux/features/login/login";
import IfscCodeMapper from "../../_utils/IfscLogoMapper";
import { resetToggle } from "../../../redux/features/beneficiary/beneAllVerification/beneAllVerification";
import { setshowsuccestoast } from "../../../redux/features/owner/ownerAllVerification/ownerAllVerification";
import { toast } from "react-toastify";
import { toast_position } from "../../../redux/features/Settings/Authorization";

const BeneTxnAccount = (props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const services = useSelector((state) => state.refreshToken.services);
  const [beneAccountId, setBeneAccountId] = useState(
    props.txnPayLoad?.beneAccountId ? props.txnPayLoad?.beneAccountId : null
  );
  const [accountName, setAccountName] = useState(
    props.txnPayLoad?.bankAccountName ? props.txnPayLoad?.bankAccountName : ""
  );
  const [accountNumber, setAccountNumber] = useState(
    props.txnPayLoad?.bankAccountNo ? props.txnPayLoad?.bankAccountNo : ""
  );
  const [panNumber, setPanNumber] = useState(
    props.txnPayLoad?.pan ? props.txnPayLoad?.pan : null
  );
  const [IFSC, setIFSC] = useState(
    props.txnPayLoad?.bankAccountIFSC ? props.txnPayLoad?.bankAccountIFSC : ""
  );
  const [bankLogo, setBankLogo] = useState(
    props.txnPayLoad?.bankLogo ? props.txnPayLoad?.bankLogo : ""
  );
  const [category, setCategory] = useState(
    props.txnPayLoad?.categories ? props.txnPayLoad?.categories?.split(",") : []
  );
  const [disableCategories, setDisableCategories] = useState(true);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [haveBankAssociate, setHaveBankAssociate] = useState(0);
  const [havePANAssociate, setHavePANAssociate] = useState(0);

  const unassignCategories = props.categories;

  const { beneLookData } = useSelector((state) => state.verify);

  const bankData = useSelector(
    (state) => state.beneAllVerification.beneLookData
  );

  useEffect(() => {
    if (props.txnPayLoad.selectedCategory) {
      props.BenEnableNextButton(false);
    } else {
      props.BenEnableNextButton(true);
    }
  }, [props.txnPayLoad.selectedCategory]);

  useEffect(() => {
    if (props?.activeStep === 0 && props?.selectedCategory === null) {
      props?.BenEnableNextButton(true);
    } else if (props?.activeStep === 0 && props?.selectedCategory !== null) {
      props?.BenEnableNextButton(false);
    }
  }, [props?.activeStep]);

  useEffect(() => {
    if (props.associateModalOpen) {
      document.querySelector("body").style.overflow = "hidden";
    } else {
      document.querySelector("body").style.overflow = "auto";
    }
  }, [props.associateModalOpen]);

  const handleUnassignNavigate = (id) => {
    navigate(`/accounts/beneficiary_account/edit/categories/${id}`);
  };

  const handleOpenModal = () => {
    dispatch(resetToggle());
    props.setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    props.setIsModalOpen(false);
  };

  const unassignlength = unassignCategories.filter(
    (unassignCategory) => !category?.includes(unassignCategory.name)
  );

  useEffect(() => {
    setHaveBankAssociate(bankData?.total_count || 0);
    setHavePANAssociate(beneLookData?.total_count || 0);
  }, [beneLookData, bankData, dispatch]);

  useEffect(() => {
    dispatch(beneLookUp({ pan_number: props?.txnPayLoad?.pan }));
  }, [props.txnPayLoad?.pan]);

  const handleAccountSelection = (
    accountName,
    accountNumber,
    ifsc,
    bankLogo,
    category,
    panNo,
    beneAccountId
  ) => {
    const categoriesArray = category?.split(",");
    setCategory(categoriesArray);
    setAccountName(accountName);
    setAccountNumber(accountNumber);
    setIFSC(ifsc);
    setBankLogo(bankLogo);
    setBeneAccountId(beneAccountId);
    setPanNumber(panNo);
    setSelectedCategory(null);
    dispatch(checkUsertoken());
    dispatch(beneLookUp({ pan_number: panNo }));
    dispatch(
      beneBankLookUp({
        ifsc: ifsc,
        bank_account_no: accountNumber,
      })
    );

    props.setTxnPayLoad({
      ...props.txnPayLoad,
      bankAccountName: accountName,
      beneAccountId: beneAccountId,
      bankAccountIFSC: ifsc,
      bankAccountNo: accountNumber,
      bankLogo: bankLogo,
      pan: panNo,
      selectedCategory: null,
      amountWords: "",
      amount: "",
      mergeCategory: null,
    });

    props?.setAmountInWords("");

    handleCloseModal();
  };

  const handleCategorySelect = (category) => {
    const categoryData = props.categories.find((cat) => cat.name === category);

    if (selectedCategory === category) {
      setSelectedCategory(null);
      props.handleCategorySelect(null);
      props.setTxnPayLoad({
        ...props.txnPayLoad,
        selectedCategory: null,
      });
    } else {
      setSelectedCategory(category);
      props.handleCategorySelect(categoryData);

      props.setTxnPayLoad({
        ...props.txnPayLoad,
        selectedCategory: categoryData,
      });
      props.setAdditionalData({});
      props?.setSelectedValue({
        from: "Select",
        to: "Select",
      });

      props?.setDateRangeEnabled(false);
      props?.setShowDatePicker(false);
      props?.setStartDate(null);
      props?.setEndDate(null);

      props?.setShowDatePickerCal(false);
      props?.setStartDateCal("");
      props?.setIsSingleDateSelected(false);

      props?.setFiles([]);
      props?.setShowImages("");

      props?.setQuantity("");

      props?.setSelectedValueMonth({
        month: "Select",
        year: "Select",
      });
    }
  };

  const handleArrowClick = () => {
    props.setAssociateModalOpen(true);
  };

  const handleAssociateCloseModal = () => {
    props.setAssociateModalOpen(false);
  };

  const [isMobile, setIsMobile] = useState(window.innerWidth <= 800);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 800);
      setWindowWidth(window.innerWidth);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [window.innerWidth]);

  return (
    <>
      <div className="beneContact_Main">
        <div
          className="dividecontact"
          style={{ width: isMobile ? `${windowWidth}px` : "" }}
        >
          <div className="w-1/2 h-fit contactright ">
            <div className="bg-[#CE720F] flex flex-col relative rounded-[30px]">
              <div className="bg-[#29487E] flex flex-col relative rounded-[30px]">
                <div className="py-5 h-full bg-[#506994] flex flex-col rounded-[30px]">
                  <div className="w-full flex flex-col px-8 gap-7">
                    <div
                      className={`flex justify-between ${
                        windowWidth < 500
                          ? "flex-col items-start"
                          : "flex-row items-center"
                      }`}
                    >
                      <div className="text-[#DADADA] font-semibold text-base">
                        {props?.title}
                      </div>
                      <div
                        className="text-[#DADADA] text-xs underline"
                        onClick={() => {
                          if (
                            services?.find(
                              (service) =>
                                service === "BUAccount" || service === "Admin"
                            ) !== undefined
                          ) {
                            navigate("/accounts/beneficiary_account/create");
                          }
                        }}
                        style={{
                          transition: "all 0.6s ease-in-out",
                          cursor:
                            services?.find(
                              (service) =>
                                service === "BUAccount" || service === "Admin"
                            ) === undefined
                              ? "not-allowed"
                              : "pointer",
                          opacity:
                            services?.find(
                              (service) =>
                                service === "BUAccount" || service === "Admin"
                            ) === undefined
                              ? "0.5"
                              : "1",
                        }}
                      >
                        Register a new account
                        <KeyboardArrowRightIcon />
                      </div>
                    </div>
                    <Button
                      className="w-full antialiased text-[16px]"
                      size="large"
                      sx={{
                        backgroundColor: "#2A487E",
                        color: "#FF7F33",
                        "&:hover": {
                          backgroundColor: "#2A487E",
                        },
                        textTransform: "none !important",
                        borderRadius: "10px",
                        display: "flex",
                        alignItems: "center",
                        fontFamily: "Open Sans",
                        fontWeight: "600",
                      }}
                      onClick={handleOpenModal}
                    >
                      {accountName ? (
                        <>
                          <Tooltip
                            title={accountName}
                            arrow
                            placement="bottom"
                            componentsProps={{
                              popper: {
                                sx: {
                                  [`& .${tooltipClasses.tooltip}`]: {
                                    backgroundColor: "black",
                                  },
                                  [`& .${tooltipClasses.arrow}`]: {
                                    color: "black",
                                  },
                                },
                              },
                            }}
                          >
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                overflow: "hidden",
                              }}
                            >
                              <span
                                style={{
                                  flex: "1",
                                  overflow: "hidden",
                                  textOverflow: "ellipsis",
                                  // whiteSpace: "nowrap",
                                  color: " #FF7F33",
                                  fontSize: "20px",
                                  textAlign: "left",
                                  font: " normal normal 600 16px/22px Open Sans",
                                  letterSpacing: " 0px",
                                  textTransform: "capitalize",
                                  opacity: "1",
                                }}
                              >
                                {accountName?.toLowerCase()}
                              </span>
                              <img
                                src={EditAccount}
                                alt="edit account"
                                style={{
                                  width: "15px",
                                  height: "15px",
                                  marginLeft: "10px",
                                }}
                              />
                            </div>
                          </Tooltip>
                        </>
                      ) : (
                        <>
                          <img
                            src={SelectUser}
                            alt="select user"
                            style={{
                              width: "20px",
                              height: "20px",
                              marginRight: "10px",
                            }}
                          />
                          Select an existing account
                        </>
                      )}
                    </Button>

                    {props.isModalOpen && (
                      <BeneTxnAccountModal
                        onClose={handleCloseModal}
                        onAccountSelect={handleAccountSelection}
                        accountNumber={accountNumber}
                        IFSC={IFSC}
                        category={category}
                        bankLogo={bankLogo}
                        setTxnPayLoad={props.setTxnPayLoad}
                        txnPayLoad={props.txnPayLoad}
                        accountModalSearch={props.accountModalSearch}
                        setAccountModalSearch={props.setAccountModalSearch}
                        filterPayload={props.filterPayload}
                        setFilterPayload={props.setFilterPayload}
                      />
                    )}
                  </div>
                </div>
                <div className="-mx-2">
                  <div className="w-full flex flex-col px-4 gap-6">
                    {accountNumber && IFSC && (
                      <div className="rounded-l-large flex w-full p-2 flex-col justify-center items-start">
                        <div className="flex px-4 flex-row justify-end w-full">
                          <div className="antialiased text-[#F9F9F9] text-xs w-full">
                            <p className="text-base">
                              {formatAccountNumber(accountNumber)}
                            </p>
                            <p className="pt-1 pb-2 text-xs text-[#dadada]">
                              Account number{" "}
                            </p>
                            <hr
                              style={{
                                width: "100%",
                                border: "1px solid #1E3A6D",
                              }}
                            />
                            <p className="pt-2 pb-1 text-base">{IFSC}</p>
                            <p className="text-xs text-[#dadada]">IFSC code</p>

                            <div className="flex items-center justify-between w-32 h-[41px] absolute right-0 bottom-0">
                              <div className="bg-[#ffffff] rounded-tl-[30px] rounded-br-[30px] flex items-center justify-around w-full h-full">
                                {
                                  <img
                                    src={require("../../../assets/IfscLogo/" +
                                      IfscCodeMapper(IFSC))}
                                    alt="bank"
                                    className="w-[70px] h-[18px]"
                                  />
                                }
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className="-mx-2">
                <div className="w-full flex flex-col px-4">
                  {(haveBankAssociate > 1 || havePANAssociate > 1) &&
                    accountNumber &&
                    IFSC && (
                      <div className="rounded-l-large flex w-full">
                        <div
                          className="w-full cursor-pointer rounded-[30px] flex items-center px-7 bg-[#CE720F] text-white"
                          onClick={handleArrowClick}
                        >
                          <div className="flex items-center w-full">
                            <img
                              src={AssociateInfo}
                              className="w-5 h-5 mr-2"
                              alt="associate info"
                            />
                            <div className="my-4 text-[14px]">
                              Active associated accounts found
                            </div>
                          </div>
                          <div className="ml-auto">
                            <img
                              className="w-6"
                              src={arrowRight}
                              alt="arrow next"
                            />
                          </div>
                        </div>
                      </div>
                    )}

                  {props.associateModalOpen && (
                    <BeneAssociateAccount
                      onAsscociateClose={handleAssociateCloseModal}
                      IFSC={IFSC}
                      accountNumber={accountNumber}
                      beneAccountId={beneAccountId}
                      panNumber={panNumber}
                      onAccountSelect={handleAccountSelection}
                      accountName={accountName}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className="dividecontact"
          style={{ width: isMobile ? `${windowWidth}px` : "" }}
        >
          {IFSC && category.length > 0 && (
            <div className="w-1/2 contactright">
              <div className="bg-[#506994] flex flex-col relative rounded-[32px]">
                <div className="flex flex-col">
                  <div className="bg-[#304B7D] text-[#DADADA] font-semibold text-base flex flex-row justify-between py-5 pb-1 px-8 rounded-t-[30px] 4xl:rounded-t-[30px] items-center">
                    <p className="text-center">Select Transaction Category</p>
                  </div>
                </div>
                <div
                  className="bg-[#304B7D] py-5 h-full flex flex-col rounded-b-4xl"
                  style={{ height: "320px" }}
                >
                  <div className="w-full flex flex-col overflow-y-auto h-96 pl-8 pr-6 custom-scroll-container">
                    <p className="text-[#DADADA] text-base font-semibold pb-2">
                      Assigned categories
                    </p>

                    {category.length === 0 || category[0] === "" ? (
                      <div className="text-xs text-[#DADADA] pb-4">
                        The beneficiary does not currently have any assigned
                        categories. Assign a category in order to proceed.
                      </div>
                    ) : (
                      <div className="text-justify flex flex-col gap-3 cursor-pointer">
                        {Array.isArray(category) &&
                          category.length > 0 &&
                          category.map((categories, index) => (
                            <Tooltip
                              key={index}
                              title={categories}
                              componentsProps={{
                                popper: {
                                  sx: {
                                    [`& .${tooltipClasses.tooltip}`]: {
                                      backgroundColor: "black",
                                    },
                                    [`& .${tooltipClasses.arrow}`]: {
                                      color: "black",
                                    },
                                  },
                                },
                              }}
                              arrow
                              PopperProps={{
                                style: {
                                  maxWidth: "none",
                                },
                              }}
                            >
                              <Button
                                key={index}
                                size="small"
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "space-between",
                                  backgroundColor:
                                    categories === selectedCategory
                                      ? "#4A6D4B"
                                      : "#506994",
                                  borderRadius: "20px",
                                  color: "#dadada",
                                  height: "58px",
                                  padding: "0 15px",
                                  fontSize: "14px",
                                  fontFamily: "Open Sans",
                                  fontWeight: "600",
                                }}
                                onClick={() => handleCategorySelect(categories)}
                              >
                                <p className="w-[15%] bg-white cateTxnParent cursor-pointer flex-shrink-0">
                                  <img
                                    className="w-9 h-9 cateTxnImage"
                                    src={
                                      category.logo_url
                                        ? category.logo_url
                                        : BeneIcon
                                    }
                                    alt="bene"
                                  />
                                </p>
                                <span
                                  className="pr-4 pl-4 categoryName"
                                  style={{ textTransform: "capitalize" }}
                                >
                                  {categories}
                                </span>
                                <div style={{ marginLeft: "auto" }}>
                                  <Checkbox
                                    icon={
                                      <img
                                        className="w-5 h-5"
                                        src={notselectedRadio}
                                        alt=""
                                      />
                                    }
                                    checkedIcon={
                                      <img
                                        className="w-5 h-5"
                                        src={selectedRadio}
                                        alt=""
                                      />
                                    }
                                    className="radioBT"
                                    checked={categories === selectedCategory}
                                    onChange={() =>
                                      handleCategorySelect(categories)
                                    }
                                  />
                                </div>
                              </Button>
                            </Tooltip>
                          ))}
                      </div>
                    )}

                    {unassignlength.length > 0 &&
                      unassignCategories.length > 0 &&
                      !category.includes("all") && (
                        <>
                          <p className="text-[#DADADA] text-base font-semibold py-2">
                            Unassigned categories
                          </p>
                          <div className="text-justify flex flex-col gap-3">
                            {unassignCategories
                              .filter(
                                (unassignCategory) =>
                                  !category.includes(unassignCategory.name)
                              )
                              .map((unassignedCategory, index) => {
                                const isDisabled = disableCategories;
                                return (
                                  <Tooltip
                                    key={index}
                                    title={unassignedCategory}
                                    componentsProps={{
                                      popper: {
                                        sx: {
                                          [`& .${tooltipClasses.tooltip}`]: {
                                            backgroundColor: "black",
                                          },
                                          [`& .${tooltipClasses.arrow}`]: {
                                            color: "black",
                                          },
                                        },
                                      },
                                    }}
                                    arrow
                                    PopperProps={{
                                      style: {
                                        maxWidth: "none",
                                      },
                                    }}
                                  >
                                    <Button
                                      key={index}
                                      size="small"
                                      style={{
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "space-between",
                                        backgroundColor: "#3A5686",
                                        borderRadius: "20px",
                                        color: "#FAFAFA",
                                        height: "58px",
                                        padding: "0 15px",
                                        fontSize: "14px",
                                        fontFamily: "Open Sans",
                                        fontWeight: "600",
                                        opacity: isDisabled ? 0.6 : 1,
                                        pointerEvents: isDisabled
                                          ? "none"
                                          : "auto",
                                      }}
                                    >
                                      <p className="w-[15%] bg-white cateTxnParent">
                                        <img
                                          className="w-9 h-9 cateTxnImage"
                                          src={BeneIcon}
                                          alt="bene"
                                        />
                                      </p>
                                      <span
                                        className="pr-4 pl-4 categoryName"
                                        style={{ textTransform: "capitalize" }}
                                      >
                                        {unassignedCategory.name}
                                      </span>
                                      <div style={{ marginLeft: "auto" }}></div>
                                    </Button>
                                  </Tooltip>
                                );
                              })}
                          </div>
                        </>
                      )}
                  </div>
                </div>
                {category.length > 0 && unassignlength.length > 0 && (
                  <div className="-mx-2">
                    <div className="w-full flex flex-col px-4 gap-6">
                      {accountNumber && IFSC && (
                        <div className="rounded-l-large flex w-full">
                          <div
                            className="w-full cursor-pointer rounded-[30px] flex items-center px-7 text-white"
                            onClick={() =>
                              !isMobile
                                ? handleUnassignNavigate(beneAccountId)
                                : toast.info("Coming Soon...", toast_position)
                            }
                          >
                            <div className="flex items-center w-full">
                              <div className="my-4 text-[14px]">
                                To assign an unassigned category
                                <span>&nbsp;click here</span>
                                &nbsp; &nbsp; &nbsp; &nbsp;
                              </div>
                            </div>
                            <div className="ml-auto">
                              <img
                                className="w-6"
                                src={unassignedImg}
                                alt="verified logo"
                              />
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                )}
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default BeneTxnAccount;
