import React, { useEffect, useState } from "react";
import "./index.css";
import { useParams, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  verifyThisPAN,
  beneLookUp,
  resetBeneData,
  viewAccountDetail,
} from "../../../../redux/features/verifyPAN/verifyPAN";
import BeneEditStepper from "../../../../components/BeneEditAccount/BeneEditStepper";
import BenePanEditModal from "../../../../components/BeneEditAccount/BenePanEditModel";
import { toast } from "react-toastify";
import BenePanDetails from "../../BeneficiaryAccount/BenePanDetails";
import { checkUsertoken } from "../../../../redux/features/login/login";
import { getRefreshTokenNew } from "../../../../redux/features/refreshToken/refreshToken";

const BeneEditPan = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const dispatch = useDispatch();
  const [nextButtonEnabled, BenEnableNextButton] = useState(true);
  const [showAction, setShowAction] = useState(false);
  const [test, setTest] = useState(true);
  const [openPopEdit, setOpenPopEdit] = useState(false);
  const [alreadyLinked, setAlreadyLinked] = useState(false);
  const [nonArchive, setNonArchive] = useState(false);
  const [isFocus, setIsFocus] = useState(false);
  const [accountType, setAccountType] = useState({ type: "", id: "" });
  const [lookupNext, setLookupNext] = useState(false);
  const [disableCreateNext, setDisableCreateNext] = useState(false);
  const [shutViewAll, setShutViewAll] = useState(false);
  const [isAchived, setIsArchived] = useState(false);
  const [isBlock, setIsBlock] = useState(false);
  const [isApproved, setIsApproved] = useState(false);
  const [isDennied, setIsDennied] = useState(false);
  const [isPending, setIsPending] = useState(false);
  const [verifiedPan, setVerifiedPan] = useState(null);
  const [newMessage, setNewMessage] = useState(false);
  const [isActive, setIsActive] = useState(false);
  const [hideCreate, setHideCreate] = useState(false);
  const [hideBlock, setHideBlock] = useState(false);
  const [previousBankNo, setPreviousBankNo] = useState("");
  const [isLinkedAccount, setIsLinkedAccount] = useState(false);
  const [achivedOpenPop, setAchivedOpenPop] = useState(false);
  const [unachivedOpenPop, setUnachivedOpenPop] = useState(false);
  const [showAccInfo, setShowAccInfo] = useState(false);
  const [showRejectTnxPopup, setRejectTxnPopup] = useState(false);
  const [payload, setPayload] = useState({
    bank_account: "",
    ifsc_code: "",
    contact_no: "",
    email_id: "",
    gstin: "",
    name: "",
    categories: "",
    panNumber: "",
    newGstValue: "",
  });

  const [updatedGst, setUpdatedGst] = useState({
    id: "",
    panNo: "",
    gstNo: "",
  });
  const [associatedPan, setAssociatedPan] = useState(true);

  const [GSTPanExist, setGSTPanExist] = useState(false);
  const [openPopEditCheck, setOpenPopEditCheck] = useState(false);

  const steps = ["Enter PAN & GST details"];
  const {
    verifyPAN,
    beneLookData,
    beneLookLoading,
    verifyPANisLoading,
    viewAccountDetailData,
    beneLookError,
    viewAccountDetailLoading,
  } = useSelector((state) => state.verify);
  const { services, isLoading } = useSelector((state) => state.refreshToken);
  const panRegex = /^[A-Z]{5}[0-9]{4}[A-Z]{1}$/;
  const specialRegex = /^[a-zA-Z0-9 ]*$/;

  const [allData, setAllData] = useState([]);
  const [page, setPage] = useState(1);

  useEffect(() => {
    if (!verifyPANisLoading) {
      if (verifyPAN?.status === true) {
        setVerifiedPan(true);
        dispatch(checkUsertoken());
        if (services?.includes("BUAccount") || services?.includes("Admin")) {
          dispatch(beneLookUp({ pan_number: payload.panNumber }));
        } else {
          navigate("/feature_not_assigned", {
            state: { unavailableService: "BUAccount" },
          });
        }

        setPayload({
          ...payload,
          legalName: verifyPAN?.pan_details?.full_name,
        });
      } else if (verifyPAN?.status === false) {
        setVerifiedPan(false);
        setPayload({
          ...payload,
          gstin: "",
        });

        toast.error(verifyPAN?.errors?.base?.[0], {
          position: "bottom-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      }
    }
  }, [verifyPAN]);

  useEffect(() => {
    let gstStoredData = sessionStorage.getItem("PAN GST Edit NewValue");
    if (!beneLookLoading && beneLookData?.status) {
      if (allData?.length === 0) {
        setAllData((allData) => [
          ...allData,
          ...beneLookData?.beneficiary_accounts,
        ]);
        dispatch(checkUsertoken());

        if (services?.includes("BUAccount") || services?.includes("Admin")) {
          dispatch(beneLookUp({ pan_number: payload.panNumber, page: page }));
        } else {
          navigate("/feature_not_assigned", {
            state: { unavailableService: "BUAccount" },
          });
        }

        if (beneLookData?.title?.records > allData?.length) {
          setTest(false);
        } else {
          setTest(true);
        }
      } else if (beneLookData?.title?.records > allData?.length) {
        setAllData((allData) => [
          ...allData,
          ...beneLookData?.beneficiary_accounts,
        ]);
        dispatch(checkUsertoken());
        if (services?.includes("BUAccount") || services?.includes("Admin")) {
          dispatch(beneLookUp({ pan_number: payload.panNumber, page: page }));
        } else {
          navigate("/feature_not_assigned", {
            state: { unavailableService: "BUAccount" },
          });
        }

        setPage((prevPage) => prevPage + 1);
        setTest(false);
      }
      allData?.map((val) => {
        if (val?.id == id) {
          setPayload({
            ...payload,
            gstin:
              gstStoredData != null ? gstStoredData : val?.gstin_detail || "",
          });
        }
      });
    }

    if (
      allData &&
      !beneLookLoading &&
      allData?.length === beneLookData?.title?.records
    ) {
      const hasId = allData.some((val) => val?.id === id);
      setAssociatedPan(hasId);
    } else if (!beneLookLoading && beneLookError && !allData) {
      BenEnableNextButton(false);
      setAssociatedPan(false);
    }

    if (haveLinkNew && !beneLookLoading) {
      setIsLinkedAccount(true);
    } else if (
      haveLinkNew === undefined ||
      (haveLinkNew === false && !beneLookLoading)
    ) {
      setNewMessage(false);
    }
  }, [beneLookData, id, beneLookError]);

  const handleNext = () => {};

  const handleBack = () => {
    if (nextButtonEnabled && !openPopEditCheck) {
      navigate(`/accounts/beneficiary_account/details/${id}`);
    } else if (openPopEditCheck) {
      setOpenPopEdit(true);
    } else {
      setOpenPopEdit(true);
    }
  };

  useEffect(() => {
    dispatch(checkUsertoken());
    const fetchPANData = async () => {
      let response = await dispatch(viewAccountDetail({ id: id }));
      if (response?.error?.message === "Request failed with status code 404") {
        navigate("*");
      }
    };
    fetchPANData();
  }, [dispatch, id, navigate]);

  let haveLinkNew = allData?.some((val) => {
    return val?.bank_acc_no === previousBankNo && val?.id !== id;
  });

  useEffect(() => {
    sessionStorage.setItem("Pan Gst Verification", JSON.stringify(updatedGst));
  }, [updatedGst]);

  useEffect(() => {
    setIsFocus(true);
    if (!viewAccountDetailLoading && viewAccountDetailData?.status === true) {
      setUpdatedGst({
        id: viewAccountDetailData?.beneficiary_accounts[0]?.id,
        panNo: viewAccountDetailData?.beneficiary_accounts[0]?.pan_no,
        gstNo: viewAccountDetailData?.beneficiary_accounts[0]?.gstin_detail,
      });
      setPayload({
        ...payload,
        panNumber: viewAccountDetailData?.beneficiary_accounts[0]?.pan_no,
        gstin: viewAccountDetailData?.beneficiary_accounts[0]?.gstin_detail,
      });
      dispatch(checkUsertoken());
      if (services?.includes("BUAccount") || services?.includes("Admin")) {
        dispatch(
          verifyThisPAN({
            pan_number: viewAccountDetailData?.beneficiary_accounts[0]?.pan_no,
          })
        );
      } else {
        navigate("/feature_not_assigned", {
          state: { unavailableService: "BUAccount" },
        });
      }
    }
  }, [viewAccountDetailData]);

  useEffect(() => {
    beneLookData?.beneficiary_accounts?.find((val) => {
      if (val?.id === id) {
        setGSTPanExist(val?.gstin_detail ? true : false);
      }
    });
  }, [beneLookData?.beneficiary_accounts]);

  useEffect(() => {
    dispatch(checkUsertoken());
    dispatch(getRefreshTokenNew());
  }, []);

  useEffect(() => {
    if (!isLoading && services != null) {
      if (services?.includes("BUAccount") || services?.includes("Admin")) {
        dispatch(checkUsertoken());
      } else {
        navigate("/feature_not_assigned", {
          state: { unavailableService: "BUAccount" },
        });
      }
    }
  }, [services, isLoading]);

  const handlePanChange = (e) => {
    const inputPan = e.target.value.toUpperCase();

    if (inputPan?.length < 10) {
      setOpenPopEditCheck(true);
    }
    if (panRegex.test(inputPan)) {
      setOpenPopEditCheck(true);
    }
    if (viewAccountDetailData?.beneficiary_accounts[0]?.pan_no === inputPan) {
      setOpenPopEditCheck(false);
    }

    if (!specialRegex.test(inputPan)) {
      return;
    }

    if (inputPan?.length > 10) {
      return;
    }
    if (!panRegex.test(inputPan) && inputPan?.length === 10) {
      setVerifiedPan(false);

      toast.error("Invalid PAN", {
        position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
      dispatch(resetBeneData());
      setPayload({
        ...payload,
        gstin: "",
      });
    } else if (inputPan?.length === 10) {
      dispatch(checkUsertoken());

      if (services?.includes("BUAccount") || services?.includes("Admin")) {
        dispatch(verifyThisPAN({ pan_number: inputPan }));
      } else {
        navigate("/feature_not_assigned", {
          state: { unavailableService: "BUAccount" },
        });
      }

      setAllData([]);
    } else {
      setVerifiedPan(null);
      BenEnableNextButton(true);
      setLookupNext(false);
      setAccountType({ type: "", id: "" });

      setShowAccInfo(false);
      sessionStorage.removeItem("PAN GST Edit NewValue");
    }

    setPayload({
      ...payload,
      panNumber: inputPan,
      gstin: "",
    });
    setAccountType({ type: "", id: "" });
    setIsLinkedAccount(false);
    setNewMessage(false);
    setIsArchived(false);
    setIsApproved(false);
    setIsDennied(false);
    setIsPending(false);
    setShowAction(false);
    setIsBlock(false);
    setDisableCreateNext(false);
    setIsActive(false);
    setHideBlock(false);
    setHideCreate(false);
    setNonArchive(false);
    setAlreadyLinked(false);
  };
  return (
    <div
      className="edit_pan_con_main"
      style={{
        height:
          unachivedOpenPop || achivedOpenPop || showRejectTnxPopup
            ? "100vh"
            : "fit-content",
        overflowY:
          openPopEdit ||
          achivedOpenPop ||
          unachivedOpenPop ||
          showRejectTnxPopup
            ? "hidden"
            : "",
        height:
          openPopEdit ||
          achivedOpenPop ||
          unachivedOpenPop ||
          showRejectTnxPopup
            ? "100vh"
            : "",
      }}
    >
      {openPopEdit && (
        <BenePanEditModal
          message2={"Are you sure you want to leave this page?"}
          message={"Leaving this page will not update the changes you made"}
          id={id}
          close={setOpenPopEdit}
        />
      )}
      <div className="main_edit_stepper_div">
        <BeneEditStepper
          setAccountType={setAccountType}
          setIsApproved={setIsApproved}
          setIsDennied={setIsDennied}
          setIsPending={setIsPending}
          setShowAction={setShowAction}
          setIsArchived={setIsArchived}
          type={"single"}
          steps={steps}
          activeStep={0}
          id={id}
          nextButtonEnabled={nextButtonEnabled}
          useFor="pan"
          email={viewAccountDetailData?.beneficiary_accounts[0]?.email_id}
          ifsc={viewAccountDetailData?.beneficiary_accounts[0]?.ifsc_code}
          bank={viewAccountDetailData?.beneficiary_accounts[0]?.bank_acc_no}
          contact={viewAccountDetailData?.beneficiary_accounts[0]?.contact_no}
          gstin={payload?.gstin}
          pan={payload.panNumber}
          handleBackPan={handleBack}
          disType={"pan"}
          name={payload?.legalName}
          haveLinkNew={haveLinkNew}
          beneLookError={beneLookError}
        />
      </div>
      <div className="edit_pan_details_all">
        <BenePanDetails
          showAccInfo={showAccInfo}
          allData={allData}
          setShowAccInfo={setShowAccInfo}
          associatedPan={associatedPan}
          test={test}
          gstin={payload?.gstin}
          panNumber={payload.panNumber}
          verifiedPan={verifiedPan}
          handlePanChange={handlePanChange}
          panDetails={verifyPAN?.pan_details}
          beneLookData={beneLookData}
          beneLookLoading={beneLookLoading}
          lookupNext={lookupNext}
          setLookupNext={setLookupNext}
          BenEnableNextButton={BenEnableNextButton}
          verifyPANisLoading={verifyPANisLoading}
          isAchived={isAchived}
          setIsArchived={setIsArchived}
          setIsApproved={setIsApproved}
          isApproved={isApproved}
          handleNext={handleNext}
          accountType={accountType}
          setAccountType={setAccountType}
          isDennied={isDennied}
          setIsDennied={setIsDennied}
          setIsPending={setIsPending}
          isPending={isPending}
          nextButtonEnabled={nextButtonEnabled}
          shutViewAll={shutViewAll}
          setShutViewAll={setShutViewAll}
          disableCreateNext={disableCreateNext}
          setDisableCreateNext={setDisableCreateNext}
          panLatestData={payload.panNumber}
          isFocus={isFocus}
          setIsFocus={setIsFocus}
          type={"editFlow"}
          showAction={showAction}
          setShowAction={setShowAction}
          previousId={id}
          setIsBlock={setIsBlock}
          isBlock={isBlock}
          isActive={isActive}
          setIsActive={setIsActive}
          setNewMessage={setNewMessage}
          newMessage={newMessage}
          beneLookError={beneLookError}
          setHideBlock={setHideBlock}
          setHideCreate={setHideCreate}
          hideBlock={hideBlock}
          hideCreate={hideCreate}
          setNonArchive={setNonArchive}
          nonArchive={nonArchive}
          previousBankNo={previousBankNo}
          setPreviousBankNo={setPreviousBankNo}
          isLinkedAccount={isLinkedAccount}
          setIsLinkedAccount={setIsLinkedAccount}
          unachivedOpenPop={unachivedOpenPop}
          setUnachivedOpenPop={setUnachivedOpenPop}
          achivedOpenPop={achivedOpenPop}
          setAchivedOpenPop={setAchivedOpenPop}
          alreadyLinked={alreadyLinked}
          setAlreadyLinked={setAlreadyLinked}
          haveLinkNew={haveLinkNew}
          setPayload={setPayload}
          payload={payload}
          setGSTPanExist={setGSTPanExist}
          GSTPanExist={GSTPanExist}
          showRejectTnxPopup={showRejectTnxPopup}
          setRejectTxnPopup={setRejectTxnPopup}
        />
      </div>
    </div>
  );
};

export default BeneEditPan;
