import React, { useEffect, useRef, useState } from "react";
import "./index.css";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import Footer from "../../../../components/LandingPage/Footer";
import { Box, CircularProgress, Typography } from "@mui/material";
import BackToTop from "../../../../assets/landingPage/TopArrow.svg";
import AbhayRangnekar from "../../../../assets/landingPage/AbhayRangnekar.png";
import MilindKothari from "../../../../assets/landingPage/MilindKothari.png";
import VijayAdvani from "../../../../assets/landingPage/VijayAdvani.png";
import AdityaKhanna from "../../../../assets/landingPage/AdityaKhanna.png";
import AjayMulgaokar from "../../../../assets/landingPage/AjayMulgaokar.png";
import AmitTambe from "../../../../assets/landingPage/AmitTambe.png";
import VaibhavJoshi from "../../../../assets/landingPage/VaibhavJoshi.png";
import SantoshNene from "../../../../assets/landingPage/SantoshNene.png";
import CloseIcon from "../../../../assets/CloseIcons/close_with_blue_square.svg";
import ArrowRight from "../../../../assets/landingPage/Arrowright.svg";
import VerificationArrow from "../../../../assets/ArrowsIcons/gst_verification_arrow.svg";
import ExpandingCards from "../../../../components/_utils/ExpandingCards/ExpandingCards";
import ConnectArrowLeft from "../../../../assets/landingPage/ConnectArrowLeft.svg";
import ConnectArrowRight from "../../../../assets/landingPage/ConnectArrowRight.svg";
import AkshaySirWeb from "../../../../assets/landingPage/AkshaySirWeb.jpg";
import AnkurSirWeb from "../../../../assets/landingPage/AnkurSirWeb.jpg";
import AkshaySirMob1 from "../../../../assets/landingPage/AkshaySirMob1.png";
import AnkurSirMob1 from "../../../../assets/landingPage/AnkurSirMob1.png";
import AshishSirWeb from "../../../../assets/landingPage/AshishSirWeb.jpg";
import AshishSirMob1 from "../../../../assets/landingPage/AshishSirMob1.png";
import Mission from "../../../../assets/landingPage/Mission.svg";
import Vision from "../../../../assets/landingPage/Vision.svg";
import Values from "../../../../assets/landingPage/Values.svg";

const Advisors = () => {
  const boardAdvisors = [
    {
      name: "Abhay Rangnekar",
      image: AbhayRangnekar,
      alt: "AbhayRangnekar",
      content: `Abhay Rangnekar founded Quest2travel.com in 2000 to develop a web-based platform enabling large organizations to manage end-to-end employee travel online in real-time, covering approvals, bookings (air, hotel, car, rail, etc.), and expense claims.\nQuest2travel secured its first client in 2007, broke even by 2010, cleared accumulated losses by 2013, and has remained profitable since-achieving growth without external investors or bank debt. This 100% ownership allowed Abhay to secure a strategic partnership and stake sale with Nasdaq-listed MakeMyTrip, finalized in September 2023.\nQuest2travel’s long-standing clients include top corporations in India, such as the Aditya Birla Group, Times of India Group, JSW Group, Airtel, HDFC Life, Tata Motors, SBI General, and RBI. Today, with over 400 clients, Quest2travel is a leader in corporate travel in India.\nIn addition, Abhay founded Quest2Pay (a pioneer in vendor payments via virtual corporate cards) in 2016 and Q2Pay Technologies in 2018.\nAbhay plays a key role in guiding strategy and advisory functions at Xpentra.`,
    },
    {
      name: "Milind Kothari",
      image: MilindKothari,
      alt: "MilindKothari",
      content: `Milind is the Managing Partner of BDO in India, a leading global professional services organization offering tax, accounting, assurance, digital services and advisory services. Milind leads the India firm of 300+ Partners & Directors and 9000+ people working across 12 strategic cities servicing clients from diverse industries worldwide.\nHe has over 40 years of experience in handling critical Tax, Transfer Pricing, Cross-Border Tax & Transaction Advisory engagements.\nHe is an empaneled author with Business World for monthly columns and op-eds on leadership, strategy, business philosophy and organizational culture. He is dedicated to delivering value to all stakeholders through providing exceptional client service with quality at the core.\nHis focus on furthering the use of technology in the accounting profession has led him to become one of the visionaries and pioneers in the tax-tech space.\nMilind is helping Xpentra in establishing robust financial controls and creating value.`,
    },
    {
      name: "Vijay Advani",
      image: VijayAdvani,
      alt: "VijayAdvani",
      content: `Vijay has over two decades of experience in investment management, financial market development, and strategic growth.\nAs CEO and Executive Chairman of Nuveen from 2017 to 2020, he led the firm’s strategic direction and mergers.\nPrior to this, he has served as Co-President of Franklin Templeton, focusing on growth initiatives, M&A, and investment management and spent 11 years at the World Bank, advising governments on developing financial markets and structuring financing solutions.\nCurrently, Vijay is a board member and trustee for Fidelity Mutual Funds, a senior advisor to Seviora Holdings and Neuberger Berman, and a former Chairman Emeritus of the USIBC. He also advises The Hive Incubator and serves on the board of the Global Impact Investing Network.\nIn his current role, Vijay is instrumental in guiding our global expansion strategy.`,
    },
  ];
  const strategicAdvisors = [
    {
      name: "Aditya Khanna",
      role: "Product",
      image: AdityaKhanna,
      alt: "AdityaKhanna",
      content: `Aditya has over 2 decades of experience helping CXOs enter markets and launch businesses, create new products & services (NPD), drive profitable growth and solve business problems ranging from efficiency to customer experience, engagement & retention. 
As P&L owner for various SBUs, he has demonstrated experience in building out capabilities as well as generating business. 
Aditya has run complex global programs including digital transformation and operating model redesign across markets like India, US, UK, Asia, LATAM, Australia and MENA.
He has expertise across BFSI, IT, Offshoring/GCCs, Automotive, Retail/eCommerce, and Advertising/Media.
In his current avatar, he is working as an advisor to multiple FinTechs and startups, helping them define their value propositions, build out their products and drive GTM.
Aditya is helping direct our product development and operating model transformation, GTM, and organizational transformation.`,
    },
    {
      name: "Ajay Mulgaokar",
      role: "Technology Operations",
      image: AjayMulgaokar,
      alt: "AjayMulgaokar",
      content: `Ajay is an experienced technology and business operations leader, with over 35 years of multi-faceted experience in building and running large Managed Services, global Technology and Operations businesses across HR / Human capital domain. 
He has held leadership roles at leading technology-first organizations such as Wipro, Aon Hewitt, Alight Solutions and IMR Global. He has managed multi-geography business teams with spans of up to 5000+ full-time employees. He has been instrumental in building domain capability and building strategic technology products for leading HCM platforms.
Ajay’s expertise lies in standing up and scaling technology and business operations centers (captive as well as 3rd party) globally. In his previous roles, he has helped expand business globally, acquiring new logos and growing existing relationships.
He is advising Xpentra on building at scale in an efficient, robust manner.`,
    },
    {
      name: "Amit Tambe",
      role: "Legal Council",
      image: AmitTambe,
      alt: "AmitTambe",
      content: `Amit Tambe is an independent legal practitioner based in Mumbai, specializing in corporate law with a focus on mergers and acquisitions, private equity and venture capital. Amit’s expertise also extends to navigating complex regulatory landscapes, ensuring seamless compliance across jurisdictions.

Prior to starting his independent practice, Amit was a partner at Trilegal looking after the mergers and advisory practice.
Amit’s advisory experience spans both domestic and multi-national players across industries ranging from fintechs/startups to Financial Services majors and conglomerates. A select list of his clients includes DEI Global (a Fairfax company), Flipkart, eBay, Eruditus Education, Godrej Group, Sumitomo Corporation, Deutsche Bank, Sequoia Capital, and Goldman Sachs. He has a proven track record of providing innovative legal solutions tailored to align with business objectives.

Amit helps provide strategic direction and guidance on regulatory as well as legal requirements for Xpentra.`,
    },
    {
      name: "Vaibhav Joshi",
      role: "Alliances",
      image: VaibhavJoshi,
      alt: "VaibhavJoshi",
      content: `Vaibhav is a BFSI and Fintech expert with more than 2 decades of experience. He has been conferred an honorary doctorate in Digital Banking and Fintech.
He has managed Digital Banking and business P&Ls, led large teams, and driven success through increased acquisitions, improved engagement, and enhanced customer retention.
He is a recipient of marquee awards, a thought leader and an international speaker. He was an early adopter of the Open Banking framework and has launched multiple fintechs. He was part of the team that launched Aadhar in the country and has worked extensively in Financial Inclusion.
He has served as the CEO of a leading fintech in the MSME space, as the Chief Digital Officer of a small finance bank and has created the Digital Banking business for a large private sector bank. Currently, he runs his own consultancy Tableturn tech, where he works with banks, NBFCs and Startups.
Vaibhav is helping  Xpentra across GTM and through partnerships, alliances.`,
    },
    {
      name: "Santosh Nene",
      role: "Asset Management",
      image: SantoshNene,
      alt: "SantoshNene",
      content: `A seasoned asset management industry veteran, Santosh has spent 20+ years setting up and growing AMC operations across entities in India.
As SVP and Head of PMS operations at ING, he handled business challenges ranging from regulatory compliance, risk management to  operational excellence. In this role, Santosh helped build a culture of service quality and customer orientation, while helping drive post merger integration of acquired business units.
Santosh has also helped build robust operational capabilities at firms such as UTI-Investor Services.
He is an acknowledged expert in fund operations and client management, with a thorough understanding of operations-centered statutory and compliance requirements for the asset management industry.
Building on his deep understanding of the challenges that the asset management industry faces, he is helping define and execute our value proposition for AMCs, wealth managers and fund houses in India.`,
    },
  ];
  const aboutSections = [
    {
      id: 1,
      profileWeb: AkshaySirWeb,
      profileMob: AkshaySirMob1,
      name: "Akshay Rangnekar",
      title: "Founder and Chief Executive Officer",
      description: `Akshay has always been passionate about technology, with an innate inclination to innovate and automate. After earning his bachelor’s degree in computer engineering at Cal Poly, he worked at a Silicon Valley startup for 2 years. He returned to India in 2016, recognizing India’s push towards digitization and technological transformation. There, he contributed to Quest2Pay (a pioneer in vendor payments via virtual corporate cards) and built GST and expense management solutions for Quest2Travel before founding Xpentra, a universal banking solution for enterprise customers. He designs core functionality, database structures, business logic, user interfaces, and process flows for both web and mobile platforms.`,
    },
    {
      id: 2,
      profileWeb: AnkurSirWeb,
      profileMob: AnkurSirMob1,
      name: "Ankur Rangnekar",
      title: "Co-founder and Chief Operating Officer",
      description: `Ankur, based out of Mumbai, co-founded Xpentra after heading to Bay Area and Singapore for advanced studies. The "Make in India" initiative and the possibilities offered by the Indian economy inspired him to return to the country. He takes care of operations, manages customer and partner relationships, and helps drive business development and strategic initiatives within the organization.`,
    },
    {
      id: 3,
      profileWeb: AshishSirWeb,
      profileMob: AshishSirMob1,
      name: "Ashish Wadekar",
      title: "Chief Technology Officer",
      description: `Ashish is a seasoned tech leader with a diverse international career. He began his journey in Pune and later moved to Zürich, where he led a successful ERP implementation for RitikaaWood, demonstrating his capability in managing complex software projects. Known for his innovative approach, Ashish consistently develops creative solutions to meet evolving business needs. Since joining Q2Pay in 2022, he has been instrumental in driving the company's technological vision, inspiring and leading teams with his expertise and passion.`,
    },
  ];

  const [isMobile, setIsMobile] = useState(window.innerWidth <= 1024);
  const [isVisible, setIsVisible] = useState(false);
  const [scrollPercent, setScrollPercent] = useState(0);
  const [openBoardAdvisorIndex, setOpenBoardAdvisorIndex] = useState(null);
  const [openStrategicAdvisorIndex, setOpenStrategicAdvisorIndex] =
    useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedCard, setSelectedCard] = useState(null);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [currentIndex1, setCurrentIndex1] = useState(0);
  const [isExpanded, setIsExpanded] = useState(false);
  const navigate = useNavigate();

  const { mobilestatus, webstatus } = useSelector(
    (state) => state.landingpagestate
  );

  const toggleContent = () => {
    setIsExpanded(!isExpanded);
  };

  const openModal = (card) => {
    setSelectedCard(card);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setSelectedCard(null);
  };

  const handleBoardOpenModal = (index) => {
    setOpenBoardAdvisorIndex(index);
  };

  const handleBoardCloseModal = () => {
    setOpenBoardAdvisorIndex(null);
  };

  const handleStrategicOpenModal = (index) => {
    setOpenStrategicAdvisorIndex(index);
  };

  const handleStrategicCloseModal = () => {
    setOpenStrategicAdvisorIndex(null);
  };

  const containerRef = useRef(null);
  const containerRef1 = useRef(null);

  const handleScroll = (containerRef, setIndex) => {
    if (containerRef.current) {
      const cards = containerRef.current.children;
      const containerScrollLeft = containerRef.current.scrollLeft;
      let closestIndex = 0;
      let closestDistance = Infinity;

      for (let i = 0; i < cards.length; i++) {
        const card = cards[i];
        const cardOffsetLeft = card.offsetLeft - containerScrollLeft;
        const distance = Math.abs(cardOffsetLeft);

        if (distance < closestDistance) {
          closestDistance = distance;
          closestIndex = i;
        }
      }
      setIndex(closestIndex);
    }
  };
  useEffect(() => {
    if (!containerRef.current) return;
    const element2 = containerRef.current;
    const handleScroll2 = () => handleScroll(containerRef, setCurrentIndex);
    element2.addEventListener("scroll", handleScroll2);

    return () => {
      element2.removeEventListener("scroll", handleScroll2);
    };
  }, [containerRef.current]);

  useEffect(() => {
    if (!containerRef1.current) return;
    const element1 = containerRef1.current;
    const handleScroll1 = () => handleScroll(containerRef1, setCurrentIndex1);
    element1.addEventListener("scroll", handleScroll1);

    return () => {
      element1.removeEventListener("scroll", handleScroll1);
    };
  }, [containerRef1.current]);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 1024);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [window.innerWidth]);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 20) {
        setIsVisible(true);
      } else {
        setIsVisible(false);
      }
    };
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  useEffect(() => {
    window.addEventListener("scroll", () => {
      let scrollTop = window.scrollY;
      let docHeight = document.documentElement.scrollHeight;
      let winHeight = window.innerHeight;
      let scrollPercentt = scrollTop / (docHeight - winHeight);
      let scrollPercentRounded = Math.round(scrollPercentt * 100);
      setScrollPercent(scrollPercentRounded);
    });
  }, [scrollPercent]);

  useEffect(() => {
    window.onload = function () {
      setTimeout(function () {
        window.scrollTo(0, 0);
      }, 50);
      setScrollPercent(0);
    };
  }, []);

  function CircularProgressWithLabel(props) {
    return (
      <Box sx={{ position: "relative", display: "inline-flex" }}>
        <CircularProgress
          size="59px"
          sx={{ color: "#31E5E5" }}
          variant="determinate"
          {...props}
        />
        <Box
          sx={{
            zIndex: 999,
            position: "absolute",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Typography
            variant="caption"
            component="div"
            sx={{ color: "text.secondary" }}
          >
            <img
              src={BackToTop}
              alt="back to top"
              width="50"
              style={{ right: "45px", position: "fixed", bottom: "36px" }}
            />
          </Typography>
        </Box>
      </Box>
    );
  }

  return (
    <>
      <div>
        {isVisible && (
          <div
            onClick={scrollToTop}
            className="fixed bottom-[25px] right-[40px] cursor-pointer z-20"
          >
            <CircularProgressWithLabel value={scrollPercent} />
          </div>
        )}

        {isMobile ? (
          <div
            className={`${isModalOpen ? "overflow-y-hidden" : ""} h-screen`}
            style={{
              scrollbarWidth: "none",
              msOverflowStyle: "none",
              WebkitOverflowScrolling: "touch",
            }}
          >
            <div
              style={{
                overflowY: mobilestatus && "hidden",
                height: "100vh",
                scrollbarWidth: "none",
                msOverflowStyle: "none",
                WebkitOverflowScrolling: "touch",
              }}
              className="bg-[#031f4f]"
            >
              <div className="p-[30px] bg-[#102C5D] flex flex-col gap-[15px] mt-[60px]">
                <div className="text-[16px] font-semibold text-[#F9F9F9]">
                  <span className="text-[#31E5E5]">Leadership</span> team
                </div>
                <div
                  ref={containerRef}
                  className="px-[10px] mx-auto flex gap-[20px] max-w-full overflow-x-auto"
                  style={{
                    scrollbarWidth: "none",
                    msOverflowStyle: "none",
                    WebkitOverflowScrolling: "touch",
                  }}
                >
                  {aboutSections.map((section, index) => (
                    <div
                      key={index}
                      className="rounded-[10px] bg-[#244277] px-[10px] py-[10px] w-[290px] flex-shrink-0 h-auto flex flex-col gap-[10px]"
                    >
                      <div>
                        <img
                          className="mx-auto w-[280px] rounded-t-[10px]"
                          src={section.profileMob}
                          alt="leader"
                        />
                      </div>
                      <div className="flex flex-col gap-[5px] text-[#F9F9F9] px-[10px] pt-[10px]">
                        <div className="text-[18px] font-semibold">
                          {section.name}
                        </div>
                        <div className="text-[12px] font-normal pb-[10px] border-b-[1px]">
                          {section.title}
                        </div>
                      </div>
                      <div className="px-[10px] text-[#F9F9F9]">
                        <div
                          className="text-[12px] font-normal overflow-y-auto"
                          // style={{ maxHeight: "300px" }}
                        >
                          {section.description}
                        </div>
                      </div>
                    </div>
                  ))}
                </div>

                <div className="max-w-[230px] mx-auto mt-[10px] flex gap-[10px] justify-center">
                  {aboutSections.map((_, index) => (
                    <div
                      key={index}
                      onClick={() => {
                        setCurrentIndex(index);
                        containerRef.current.children[index].scrollIntoView({
                          behavior: "smooth",
                          block: "nearest",
                          inline: "center",
                        });
                      }}
                      className={`rounded-[18px] cursor-pointer bg-white h-[4px] transition-all duration-500 ease-in-out ${
                        index === currentIndex
                          ? "w-[80px] opacity-100"
                          : "w-[40px] opacity-50"
                      }`}
                    ></div>
                  ))}
                </div>
              </div>

              <div className="bg-[#031F4F] w-full p-[30px]">
                <div
                  className="flex flex-col text-white mx-auto"
                  style={{ maxWidth: "1024px" }}
                >
                  <div className="bg-[#244277] border border-[#31E5E5] rounded-[15px] flex flex-col items-center w-full p-[20px]">
                    <div className="mb-5">
                      <img
                        src={Mission}
                        alt="mission"
                        className="w-[50] h-[50px]"
                      />
                    </div>
                    <div className="title-div text-base font-semibold mb-[5px]">
                      Our <span className="text-[#31E5E5]">mission</span>
                    </div>
                    <div className="text-white text-xs">
                      At Xpentra, our mission is to empower businesses by
                      providing seamless, integrated solutions that optimise
                      financial management and treasury operations. We aim to
                      give companies complete visibility and control over their
                      finances through a unified platform that simplifies
                      complex processes, improves decision-making, and drives
                      efficiency. By bridging the gap between multiple banking
                      systems and financial workflows, we help businesses stay
                      agile, efficient, and ready for the future.
                    </div>
                  </div>
                  <div className="flex items-center mx-auto rotate-90">
                    <img
                      src={ConnectArrowLeft}
                      alt="ConnectArrowLeft"
                      className="w-[20px] h-[20px]"
                    />
                  </div>
                  <div className="bg-[#244277] border border-[#31E5E5] rounded-[15px] flex flex-col items-center w-full p-[20px]">
                    <div className="mb-5">
                      <img
                        src={Vision}
                        alt="vission"
                        className="w-[50] h-[50px]"
                      />
                    </div>
                    <div className="title-div text-base font-semibold mb-[5px]">
                      Our <span className="text-[#31E5E5]">vision</span>
                    </div>
                    <div className="text-white text-xs">
                      Our vision is to transform how businesses make decisions
                      by providing a platform that simplifies and automates
                      financial operations, giving CXOs the real-time insights
                      they need to act quickly and confidently. We aim to
                      empower organisations with smarter tools that streamline
                      processes and enhance decision-making across all areas of
                      the business. We envision a future where businesses
                      operate with greater agility, efficiency, and clarity,
                      enabling them to achieve lasting success.
                    </div>
                  </div>
                  <div className="flex items-center mx-auto rotate-90">
                    <img
                      src={ConnectArrowLeft}
                      alt="ConnectArrowLeft"
                      className="w-[20px] h-[20px]"
                    />
                  </div>
                  <div className="bg-[#244277] border border-[#31E5E5] rounded-[15px] flex flex-col items-center w-full p-[20px]">
                    <div className="mb-[20px]">
                      <img
                        src={Values}
                        alt="values"
                        className="w-[50] h-[50px]"
                      />
                    </div>
                    <div className="title-div text-base font-semibold mb-[5px]">
                      Our <span className="text-[#31E5E5]">values</span>
                    </div>
                    <div className="text-white text-xs">
                      <div className="mb-[5px]">
                        •{" "}
                        <span
                          className="font-medium"
                          style={{
                            WebkitTextStroke: "0.3px white",
                            letterSpacing: "0.2px",
                          }}
                        >
                          Transparency
                        </span>
                        : We believe in open and clear communication, offering
                        complete visibility into every transaction and financial
                        process.
                      </div>
                      <div className="mb-[5px]">
                        •{" "}
                        <span
                          className="font-medium"
                          style={{
                            WebkitTextStroke: "0.3px white",
                            letterSpacing: "0.2px",
                          }}
                        >
                          Reliability
                        </span>
                        : Our platform is built to deliver consistent, secure,
                        and dependable performance for businesses managing their
                        financial operations.
                      </div>
                      <div className="mb-[5px]">
                        •{" "}
                        <span
                          className="font-medium"
                          style={{
                            WebkitTextStroke: "0.3px white",
                            letterSpacing: "0.2px",
                          }}
                        >
                          User-Centric
                        </span>
                        : We prioritise intuitive, easy-to-use solutions that
                        are tailored to meet the specific needs of each
                        business, ensuring a smooth experience.
                      </div>
                      <div className="mb-[5px]">
                        •{" "}
                        <span
                          className="font-medium"
                          style={{
                            WebkitTextStroke: "0.3px white",
                            letterSpacing: "0.2px",
                          }}
                        >
                          Security
                        </span>
                        : Xpentra places a strong emphasis on safeguarding all
                        data and transactions with industry- leading protocols
                        and fraud protection.
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="px-[30px] flex flex-col gap-2.5 text-[#F9F9F9] bg-[#031F4F]">
                <div className="h-full max-w-[1440px] w-full flex flex-col gap-[30px] justify-center mx-auto">
                  <div className="w-full flex flex-col justify-center lg:pl-[125px]">
                    <h3 className="text-[16px] font-semibold text-[#F9F9F9]">
                      Meet our <span className="text-[#31E5E5]">advisors</span>
                    </h3>
                    <p className="text-[12px] mt-5">
                      At Xpentra, we believe that innovation thrives when backed
                      by experience and expertise. While our journey has been
                      defined by cutting-edge technology and customer-centric
                      solutions, our growth is shaped by the wisdom and
                      strategic direction of industry leaders. Discover the
                      experts behind our success.
                    </p>
                  </div>
                </div>
              </div>

              <div className="px-[30px] py-[30px] flex flex-col gap-5 text-[#F9F9F9] bg-[#031F4F]">
                <div className="text-[16px] font-semibold text-[#F9F9F9]">
                  Board <span className="text-[#31E5E5]">advisors</span>
                </div>
                <div>
                  <div
                    ref={containerRef1}
                    className="mx-auto flex gap-[20px] max-w-full overflow-x-auto"
                    style={{
                      scrollbarWidth: "none",
                      msOverflowStyle: "none",
                      WebkitOverflowScrolling: "touch",
                    }}
                  >
                    {boardAdvisors.map((section, index) => (
                      <div
                        key={index}
                        className="rounded-[10px] bg-[#244277] w-[300px] flex-shrink-0 h-auto flex flex-col gap-[10px]"
                      >
                        <div>
                          <img
                            className="mx-auto rounded-t-[10px]"
                            src={section.image}
                            alt="leader"
                          />
                        </div>
                        <div className="flex flex-col gap-[5px] text-[#F9F9F9] px-[10px]">
                          <div className="text-[18px] font-semibold">
                            {section.name}
                          </div>
                        </div>
                        <div className="px-[10px] text-[#F9F9F9]">
                          <div
                            className="text-[12px] font-normal overflow-y-auto overflow-hidden text-ellipsis line-clamp-4"
                            onClick={toggleContent}
                          >
                            {section.content}
                          </div>
                        </div>
                        <div
                          className="w-[112px] h-9 rounded-[5px] p-2.5 mx-2.5 mb-2.5 bg-[#F9F9F9] flex justify-between"
                          onClick={() => openModal(section)}
                        >
                          <div className="text-[#031F4F] text-[12px] h-4 font-semibold">
                            Read More
                          </div>
                          <div className="h-3.5 w-3.5 my-auto">
                            <img src={ArrowRight} alt="arrowRight" />
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                  {isModalOpen && (
                    <div
                      className="fixed inset-0 bg-custom-backdrop bg-opacity-75 flex justify-center items-center z-50 overflow-hidden -bottom-2"
                      onClick={closeModal}
                    >
                      <div className="bg-[#244277] relative pb-[10px] sm:pb-[0px] rounded-[10px] w-[300px] sm:w-[80%] h-[80vh] sm:h-auto max-w-[600px] cursor-default overflow-hidden">
                        <div className="absolute right-[10px] top-[10px]">
                          <img
                            className="h-[30px] w-[30px]"
                            src={CloseIcon}
                            alt="close_with_blue_square"
                          />
                        </div>
                        <div
                          className="flex flex-col flex-shrink-0 sm:flex-row items-center h-[100vh] sm:h-[299px] overflow-auto sm:overflow-hidden"
                          style={{
                            scrollbarWidth: "none",
                            msOverflowStyle: "none",
                          }}
                        >
                          <div>
                            <img
                              className="rounded-full"
                              src={selectedCard.image}
                              alt={selectedCard.name}
                            />
                          </div>
                          <div className="p-[10px] sm:max-h-[299px] sm:w-[330px]">
                            <div className="text-[18px] font-bold w-full text-left pb-1.5 border-b-[1px]">
                              {selectedCard.name}
                            </div>
                            <div
                              className="mt-2.5 text-[12px] text-[#F9F9F9] h-max overflow-y-auto sm:h-[245px]"
                              style={{
                                paddingBottom: "10px",
                              }}
                            >
                              {selectedCard.content}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}

                  <div className="max-w-[230px] mx-auto mt-[10px] flex gap-[10px] justify-center">
                    {boardAdvisors.map((_, index) => (
                      <div
                        key={index}
                        onClick={() => {
                          setCurrentIndex1(index);
                          containerRef1.current.children[index].scrollIntoView({
                            behavior: "smooth",
                            block: "nearest",
                            inline: "center",
                          });
                        }}
                        className={`rounded-[18px] cursor-pointer bg-[#f9f9f9] h-[4px] transition-all duration-500 ease-in-out ${
                          index === currentIndex1
                            ? "w-[80px] opacity-100"
                            : "w-[40px] opacity-50"
                        }`}
                      ></div>
                    ))}
                  </div>
                </div>
              </div>

              <div
                className="px-[30px] py-[30px] flex flex-col gap-2.5 text-[#F9F9F9]"
                style={{
                  background:
                    "linear-gradient(270deg, #031F4F 0%, #1C3A6D 27%, #224074 78%, #031F4F 100%, #234176 100%) 0% 0% no-repeat padding-box",
                }}
              >
                <div className="text-[16px] font-semibold text-[#F9F9F9]">
                  Strategic <span className="text-[#31E5E5]">advisors</span>
                </div>
                <div>
                  <div
                    className="mx-auto flex gap-[20px] max-w-full overflow-x-auto"
                    style={{
                      scrollbarWidth: "none",
                      msOverflowStyle: "none",
                      WebkitOverflowScrolling: "touch",
                    }}
                  >
                    {strategicAdvisors.map((section, index) => (
                      <div
                        key={index}
                        className="rounded-[10px] bg-[#314D7E] w-[260px] flex-shrink-0 h-auto flex flex-col gap-[10px] border-[2px] border-[#31E5E5]"
                        onClick={() => openModal(section)}
                      >
                        <div className="border-b-[2px] border-b-[#31E5E5]">
                          <img
                            className="mx-auto w-[280px] rounded-t-[10px]"
                            src={section.image}
                            alt="leader"
                          />
                        </div>
                        <div className="flex justify-between px-[10px] pb-[10px]">
                          <div>
                            <div className="flex flex-col gap-[5px] text-[#F9F9F9]">
                              <div className="text-[18px] font-semibold">
                                {section.name}
                              </div>
                            </div>
                            <div className="text-[#F9F9F9]">
                              <div className="text-[12px] font-normal">
                                {section.role}
                              </div>
                            </div>
                          </div>
                          <div className="flex items-center">
                            <div className="rounded-[5px] flex justify-between items-center">
                              <div>
                                <img
                                  className="h-[20px] w-[20px]"
                                  src={VerificationArrow}
                                  alt="VerificationArrow"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                  {isModalOpen && (
                    <div
                      className="fixed inset-0 bg-custom-backdrop bg-opacity-75 flex justify-center items-center z-50 overflow-hidden -bottom-2"
                      onClick={closeModal}
                    >
                      <div className="bg-[#244277] relative pb-[10px] sm:pb-[0px] rounded-[10px] w-[300px] sm:w-[80%] h-[80vh] sm:h-auto max-w-[600px] cursor-default overflow-hidden">
                        <div className="absolute right-[10px] top-[10px]">
                          <img
                            className="h-[30px] w-[30px]"
                            src={CloseIcon}
                            alt="close_with_blue_square"
                          />
                        </div>
                        <div
                          className="flex flex-col flex-shrink-0 sm:flex-row items-center h-[80vh] sm:h-[299px] overflow-auto sm:overflow-hidden"
                          style={{
                            scrollbarWidth: "none",
                            msOverflowStyle: "none",
                          }}
                        >
                          <div>
                            <img
                              className="rounded-full"
                              src={selectedCard.image}
                              alt={selectedCard.name}
                            />
                          </div>
                          <div className="p-[10px] sm:max-h-[299px] sm:w-[330px]">
                            <div className="text-[18px] font-bold w-full text-left pb-1.5 border-b-[1px]">
                              {selectedCard.name}
                            </div>
                            <div
                              className="mt-2.5 text-[12px] text-[#F9F9F9] overflow-y-auto sm:h-[245px]"
                              style={{
                                paddingBottom: "10px",
                              }}
                            >
                              {selectedCard.content}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>

              <Footer />
            </div>
          </div>
        ) : (
          <div
            style={{
              overflow:
                !webstatus &&
                openBoardAdvisorIndex === null &&
                openStrategicAdvisorIndex === null
                  ? "scroll"
                  : "hidden",
              height: "100vh",
              scrollbarWidth: "none",
              msOverflowStyle: "none",
              WebkitOverflowScrolling: "touch",
            }}
          >
            <div className="bg-[#031f4f]">
              <div className="flex justify-center items-center mt-[30px]">
                <div className="flex flex-col text-white py-[30px] px-20 mx-auto max-w-[1024px]">
                  <div className="text-[28px] font-semibold text-[#f9f9f9] text-center pb-[20px] pt-[30px]">
                    <span className="text-[#31E5E5]">Leadership</span> team
                  </div>
                  <ExpandingCards data={aboutSections} />
                </div>
              </div>
              <div
                className="bg-transparent m-0 p-0 text-[28px] pt-[20px] pb-[30px] text-[#F9F9F9]"
                style={{
                  background:
                    "linear-gradient(270deg, #031F4F 0%, #1C3A6D 27%, #224074 78%, #031F4F 100%, #234176 100%) 0% 0% no-repeat padding-box",
                }}
              >
                <div
                  className="flex flex-row text-white py-[30px] mx-auto"
                  style={{ maxWidth: "1024px" }}
                >
                  <div className="bg-[#244277] border border-[#31E5E5] rounded-[15px] flex flex-col items-center w-full">
                    <div>
                      <img
                        src={Mission}
                        alt="mission"
                        className="w-[50] h-[50px] my-5"
                      />
                    </div>
                    <div className="title-div text-base font-semibold">
                      Our <span className="text-[#31E5E5]">mission</span>
                    </div>
                    <div className="text-white text-xs p-4 pt-[10px]">
                      At Xpentra, our mission is to empower businesses by
                      providing seamless, integrated solutions that optimise
                      financial management and treasury operations. We aim to
                      give companies complete visibility and control over their
                      finances through a unified platform that simplifies
                      complex processes, improves decision-making, and drives
                      efficiency. By bridging the gap between multiple banking
                      systems and financial workflows, we help businesses stay
                      agile, efficient, and ready for the future.
                    </div>
                  </div>
                  <div className="flex items-center">
                    <img
                      src={ConnectArrowLeft}
                      alt="ConnectArrowLeft"
                      className="w-[80px] h-[80px]"
                    />
                  </div>
                  <div className="bg-[#244277] border border-[#31E5E5] rounded-[15px] flex flex-col items-center w-full">
                    <div>
                      <img
                        src={Vision}
                        alt="vission"
                        className="w-[50] h-[50px] my-5"
                      />
                    </div>
                    <div className="title-div text-base font-semibold">
                      Our <span className="text-[#31E5E5]">vision</span>
                    </div>
                    <div className="text-white text-xs p-4 pt-[10px]">
                      Our vision is to transform how businesses make decisions
                      by providing a platform that simplifies and automates
                      financial operations, giving CXOs the real-time insights
                      they need to act quickly and confidently. We aim to
                      empower organisations with smarter tools that streamline
                      processes and enhance decision-making across all areas of
                      the business. We envision a future where businesses
                      operate with greater agility, efficiency, and clarity,
                      enabling them to achieve lasting success.
                    </div>
                  </div>
                  <div className="flex items-center">
                    <img
                      src={ConnectArrowRight}
                      alt="ConnectArrowRight"
                      className="w-[80px] h-[80px]"
                    />
                  </div>
                  <div className="bg-[#244277] border border-[#31E5E5] rounded-[15px] flex flex-col items-center w-full">
                    <div>
                      <img
                        src={Values}
                        alt="values"
                        className="w-[50] h-[50px] my-5"
                      />
                    </div>
                    <div className="title-div text-base font-semibold">
                      Our <span className="text-[#31E5E5]">values</span>
                    </div>
                    <div className="text-white text-xs p-4 pt-[10px]">
                      <div className="mb-2.5">
                        •
                        <span
                          className="font-medium"
                          style={{
                            WebkitTextStroke: "0.3px white",
                            letterSpacing: "0.2px",
                          }}
                        >
                          Transparency
                        </span>
                        : We believe in open and clear communication, offering
                        complete visibility into every transaction and financial
                        process.
                      </div>
                      <div className="mb-2.5">
                        •{" "}
                        <span
                          className="font-medium"
                          style={{
                            WebkitTextStroke: "0.3px white",
                            letterSpacing: "0.2px",
                          }}
                        >
                          Reliability
                        </span>
                        : Our platform is built to deliver consistent, secure,
                        and dependable performance for businesses managing their
                        financial operations.
                      </div>
                      <div className="mb-2.5">
                        •{" "}
                        <span
                          className="font-medium"
                          style={{
                            WebkitTextStroke: "0.3px white",
                            letterSpacing: "0.2px",
                          }}
                        >
                          User-Centric
                        </span>
                        : We prioritise intuitive, easy-to-use solutions that
                        are tailored to meet the specific needs of each
                        business, ensuring a smooth experience.
                      </div>
                      <div className="mb-2.5">
                        •{" "}
                        <span
                          className="font-medium"
                          style={{
                            WebkitTextStroke: "0.3px white",
                            letterSpacing: "0.2px",
                          }}
                        >
                          Security
                        </span>
                        : Xpentra places a strong emphasis on safeguarding all
                        data and transactions with industry- leading protocols
                        and fraud protection.
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="h-full max-w-[1024px] w-full flex justify-center mx-auto">
                <div className="flex flex-col justify-center">
                  <h3 className="text-center pt-[30px] text-[28px] font-semibold text-[#F9F9F9]">
                    Meet our <span className="text-[#31E5E5]">advisors</span>
                  </h3>
                  <p className="text-center text-sm text-[#F9F9F9] mx-auto max-w-[1024px] pt-2.5">
                    At Xpentra, we believe that innovation thrives when backed
                    by experience and expertise. While our journey has been
                    defined by cutting-edge technology and customer-centric
                    solutions, our growth is shaped by the wisdom and strategic
                    direction of industry leaders. Discover the experts behind
                    our success.
                  </p>
                  <p className="text-center text-2xl py-5 font-semibold text-[#F9F9F9]">
                    Board <span className="text-[#31E5E5]">advisors</span>
                  </p>
                </div>
              </div>
              <div
                className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-6 gap-[350px] mx-auto mb-[30px]"
                style={{ maxWidth: "1024px" }}
              >
                {boardAdvisors.map((boardAdvisor, index) => (
                  <div key={index}>
                    <div
                      className="bg-[#314D7E] rounded-t-2xl rounded-b-2xl shadow-lg hover:shadow-xl hover:scale-105 transition-transform duration-300 ease-in-out cursor-pointer"
                      style={{ width: "328px", height: "374px" }}
                      onClick={() => handleBoardOpenModal(index)}
                    >
                      <img
                        src={boardAdvisor.image}
                        className="h-80 w-full rounded-t-2xl"
                        alt={boardAdvisor.name}
                      />
                      <p className="text-[#f9f9f9] text-center py-[15px] text-base">
                        {boardAdvisor.name}
                      </p>
                    </div>
                    {openBoardAdvisorIndex === index && (
                      <>
                        <div className="fixed w-screen top-0 left-0 h-screen bg-custom-backdrop opacity-95 z-[2147483647]"></div>
                        <div
                          className="flex justify-center items-center m-auto top-1/2 left-1/2 fixed z-[2147483647]"
                          style={{ transform: "translate(-50%, -50%)" }}
                        >
                          <div
                            className="relative flex flex-col gap-4 bg-custom-appBackLight rounded-2xl"
                            style={{
                              width: "914px",
                              height: "auto",
                              paddingBottom: "30px",
                              paddingLeft: "30px",
                              paddingRight: "30px",
                            }}
                          >
                            <div className="flex justify-between items-center w-full">
                              <div style={{ width: "80%" }}></div>

                              <div className="flex justify-end w-[20%] mt-6 hover:opacity-70 hover:cursor-pointer">
                                <img
                                  src={CloseIcon}
                                  className="cursor-pointer w-[33px]"
                                  onClick={handleBoardCloseModal}
                                />
                              </div>
                            </div>
                            <div className="flex flex-row gap-6 -mt-2">
                              <div
                                className="flex-shrink-0 bg-[#314D7E] rounded-t-2xl rounded-b-2xl shadow-lg"
                                style={{ flexBasis: "35%", height: "90%" }}
                              >
                                <img
                                  src={boardAdvisor.image}
                                  className="h-[85%] w-full rounded-t-2xl"
                                  alt={boardAdvisor.alt}
                                />
                                <p className="text-[#f9f9f9] text-center py-[15px] text-base">
                                  {boardAdvisor.name}
                                </p>
                              </div>
                              <div className="flex flex-col justify-between text-[#f9f9f9] text-sm gap-y-4 leading-[1.5]">
                                {boardAdvisor.content
                                  .split("\n")
                                  .map((line, idx) => (
                                    <div key={idx}>{line}</div>
                                  ))}
                              </div>
                            </div>
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                ))}
              </div>

              <div
                className="py-5"
                style={{
                  background:
                    "linear-gradient(90deg, #031F4F 0%, #224074 50%, #031F4F 100%)",
                }}
              >
                <div className="flex flex-col justify-center">
                  <p className="text-center text-2xl font-semibold text-[#F9F9F9] pb-5">
                    Strategic <span className="text-[#31E5E5]">advisors</span>
                  </p>
                </div>
                <div
                  className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-6 gap-[210px] mx-auto"
                  style={{ maxWidth: "1024px" }}
                >
                  {strategicAdvisors.map((strategicAdvisor, index) => (
                    <div key={index}>
                      <div
                        className="bg-[#314D7E] rounded-t-[18px] rounded-b-2xl shadow-lg lg hover:shadow-xl hover:scale-105 transition-transform duration-300 ease-in-out cursor-pointer"
                        style={{
                          width: "188px",
                          height: "257px",
                          border: "2px solid #31E5E5",
                        }}
                        onClick={() => handleStrategicOpenModal(index)}
                      >
                        <img
                          src={strategicAdvisor.image}
                          className="h-70 w-full rounded-t-2xl"
                          alt={strategicAdvisor.alt}
                        />
                        <p
                          className="text-[#f9f9f9] text-center pt-[15px] text-sm"
                          style={{ borderTop: "2px solid #31E5E5" }}
                        >
                          {strategicAdvisor.name}
                        </p>
                        <p className="text-[#f9f9f9] text-center pb-[15px] text-xs">
                          {strategicAdvisor.role}
                        </p>
                      </div>
                      {openStrategicAdvisorIndex === index && (
                        <>
                          <div className="fixed w-screen top-0 left-0 h-screen bg-custom-backdrop opacity-95 z-[2147483647]"></div>
                          <div
                            className="flex justify-center items-center m-auto top-1/2 left-1/2 fixed z-[2147483647]"
                            style={{ transform: "translate(-50%, -50%)" }}
                          >
                            <div
                              className="relative flex flex-col gap-4 bg-custom-appBackLight rounded-2xl"
                              style={{
                                width: "914px",
                                height: "auto",
                                paddingBottom: "30px",
                                paddingLeft: "30px",
                                paddingRight: "30px",
                              }}
                            >
                              <div className="flex justify-between items-center w-full">
                                <div style={{ width: "80%" }}></div>

                                <div className="flex justify-end w-[20%] mt-5 hover:opacity-70 hover:cursor-pointer">
                                  <img
                                    src={CloseIcon}
                                    className="cursor-pointer w-[33px]"
                                    onClick={handleStrategicCloseModal}
                                  />
                                </div>
                              </div>
                              <div className="flex flex-row gap-6 -mt-4">
                                <div
                                  className="flex-shrink-0 bg-[#314D7E] rounded-t-2xl rounded-b-2xl shadow-lg"
                                  style={{ flexBasis: "35%" }}
                                >
                                  <img
                                    src={strategicAdvisor.image}
                                    className="h-auto w-full rounded-t-2xl"
                                    alt={strategicAdvisor.name}
                                  />
                                  <p className="text-[#f9f9f9] text-center pt-[15px] text-base">
                                    {strategicAdvisor.name}
                                  </p>
                                  <p className="text-[#f9f9f9] text-center pb-[15px] text-xs">
                                    {strategicAdvisor.role}
                                  </p>
                                </div>

                                <div className="flex flex-col justify-around text-[#f9f9f9] text-sm leading-[1.5] min-h-80 ">
                                  {strategicAdvisor.content
                                    .split("\n")
                                    .filter((line) => line.trim() !== "")
                                    .map((line, idx) => (
                                      <div key={idx}>
                                        {line}
                                        <br />
                                      </div>
                                    ))}
                                </div>
                              </div>
                            </div>
                          </div>
                        </>
                      )}
                    </div>
                  ))}
                </div>
              </div>
            </div>
            <Footer />
          </div>
        )}
      </div>
    </>
  );
};

export default Advisors;
