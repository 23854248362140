import React, { useState, useEffect, useRef } from "react";
import { Button, tooltipClasses, Tooltip, TextField } from "@mui/material";
import DatePickerComponent from "../../../_utils/Calendar";
import noaccount from "../../../../assets/Graphics/No transaction found.svg";
import dataLoading from "../../../../assets/LoaderIcons/Loader.gif";
import arrowRight from "../../../../assets/ArrowsIcons/arrow_right_white – 1.svg";
import CloseIcon from "../../../../assets/CloseIcons/close.svg";
import "react-toastify/dist/ReactToastify.css";
import {
  downloadOwnerStatement,
  getOwnerAccStatement,
  resetStatementData,
} from "../../../../redux/features/owner/ownerAllVerification/ownerAllVerification";
import { checkUsertoken } from "../../../../redux/features/login/login";
import arrowDown from "../../../../assets/ArrowsIcons/arrow_down_white.svg";
import CheckIcon from "../../../../assets/CheckmarksIcons/check_mark_white.svg";
import { debounce } from "lodash";
import FormatAmount from "../../../../components/_utils/FormatAmount/FormatAmount";
import "./index.css";
import { useDispatch, useSelector } from "react-redux";
import filterIcon from "../../../../assets/FilterSearchIcons/filter.svg";
import download_statement_icon from "../../../../assets/GeneralIcons/download_statement.svg";
import PaginationComponent from "../../../_utils/PaginationComponent";
import { formatAccountNumber } from "../../../../components/_utils/FormatAccountNumber/index";

const OwnerViewStatementModal = (props) => {
  const dropdownOptions = [50, 100];
  const [showDatePicker, setShowDatePicker] = useState(false);
  const [selectedType, setSelectedType] = useState("PDF");
  const [dataTable, setDataTable] = useState([]);
  const [startDate, setStartDate] = useState(new Date(new Date().setDate(1)));
  const [currentPage, setCurrentPage] = useState(1);
  const [currentPageText, setCurrentPageText] = useState("01");
  const [amountLimit, setAmountLimit] = useState({
    min: "",
    max: "",
  });
  const [isAmtFilterApplied, setIsAmtFilterApplied] = useState(false);
  const [endDate, setEndDate] = useState(new Date());
  const [selectStatement, setSelectStatement] = useState(false);
  const [showStatementType, setShowStatementType] = useState(false);
  const [showAmtFilterDiv, setShowAmtFilterDiv] = useState(false);
  const [selectStatementType, setSelectStatementType] = useState("Type");
  const [pageSize, setPageSize] = useState(dropdownOptions[0]);
  const [selectedValue, setSelectedValue] = useState(dropdownOptions[0]);
  const { ownerAccStatementData, ownerAccStatementLoading } = useSelector(
    (state) => state.ownerAllVerification
  );
  const dispatch = useDispatch();
  const typeDivRef = useRef(null);
  const amtDivRef = useRef(null);
  const fileTypeRef = useRef(null);
  // const calenderRef = useRef(null);

  useEffect(() => {
    handleFetch();
  }, [currentPage, selectStatementType, pageSize]);

  useEffect(() => {
    if (ownerAccStatementData) {
      setDataTable(ownerAccStatementData);
    }
  }, [ownerAccStatementData]);

  const handleFetch = () => {
    dispatch(checkUsertoken());
    dispatch(
      getOwnerAccStatement({
        id: props.statementId,
        start_date: formatDate(startDate),
        end_date: formatDate(endDate),
        page_no: currentPage,
        credit_debit: selectStatementType,
        start_amount: amountLimit.min,
        end_amount: amountLimit.max,
        page_size: pageSize,
      })
    );
  };

  const handleClose = () => {
    props.onClose();
    dispatch(resetStatementData());
  };

  const handleSelect = (value) => {
    setCurrentPage(1);
    setSelectedValue(value);
    setPageSize(value);
  };

  const handlePagination = (page) => {
    setCurrentPage(page);
  };

  const handlepage = debounce((page) => {
    if (Number(page) <= dataTable?.title?.total_pages && page !== "00") {
      let value = page.substring(1);
      if (currentPage != value) {
        setCurrentPage(Number(value));
        setCurrentPageText(page);
      }
    } else if (page > dataTable?.title?.total_pages) {
      setCurrentPage(dataTable?.title?.total_pages);
      setCurrentPageText("0" + dataTable?.title?.total_pages);
    } else {
      setCurrentPage(1);
      setCurrentPageText("01");
    }
  }, 100);

  const handleBlur = () => {
    if (currentPageText.length === 0 || currentPageText === "0") {
      setCurrentPageText(currentPage > 1 ? currentPage : "01");
    }
  };

  const handleConfirm = () => {
    setCurrentPage(1);
    setCurrentPageText("01");
    setShowDatePicker(false);
    handleFetch();
  };

  useEffect(() => {
    if (!showDatePicker) {
      if (!endDate) {
        setEndDate(startDate);
      }
    }
  }, [showDatePicker]);

  const handleCancel = () => {
    setShowDatePicker(false);
    setStartDate(new Date(new Date().setDate(1)));
    setEndDate(new Date());
  };

  const formatDate = (date) => {
    return `${date.getDate()} ${date.toLocaleString("default", {
      month: "short",
    })}, ${date.getFullYear()}`;
  };

  useEffect(() => {
    const handleDropdown = (e) => {
      if (typeDivRef.current && !typeDivRef.current.contains(e.target)) {
        setShowStatementType(false);
      }

      if (amtDivRef.current && !amtDivRef.current.contains(e.target)) {
        setShowAmtFilterDiv(false);
      }
    };

    document.addEventListener("click", handleDropdown);

    return () => {
      document.removeEventListener("click", handleDropdown);
    };
  }, [typeDivRef, amtDivRef]);

  useEffect(() => {
    const handleType = (e) => {
      if (fileTypeRef.current && !fileTypeRef.current.contains(e.target)) {
        setSelectStatement(false);
      }
    };

    document.addEventListener("click", handleType);
  }, [fileTypeRef]);

  // useEffect(() => {
  //   const handleCalender = (e) => {
  //     if (calenderRef.current && !calenderRef.current.contains(e.target)) {
  //       setShowDatePicker(false);
  //     }
  //   };

  //   document.addEventListener("mousedown", handleCalender);
  // }, [calenderRef]);

  const formatDateandTime = (date, showtime) => {
    let hours = date.getHours();
    const ampm = hours >= 12 ? "pm" : "am";
    hours %= 12;
    hours = hours || 12;
    const minutes = date.getMinutes();
    if (showtime) {
      return `${date.getDate()}-${
        date.getMonth() + 1
      }-${date.getFullYear()}, ${hours}:${minutes} `;
    } else {
      return `${date.getDate()}-${date.getMonth() + 1}-${date.getFullYear()}`;
    }
  };

  const handleDateChange = (dates) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
  };

  const handleToastClick = async () => {
    // toast.info("Currently in Development", toast_position);

    try {
      const response = await dispatch(
        downloadOwnerStatement({
          id: props.statementId,
          formatType: selectedType.toLowerCase(),
          start_date: formatDate(startDate),
          end_date: formatDate(endDate),
          // page_no: currentPage,
          credit_debit: selectStatementType,
          start_amount: amountLimit.min,
          end_amount: amountLimit.max,
        })
      );
    } catch (error) {
      console.error(error);
    }
  };

  const handleClearAmts = () => {
    setAmountLimit((prev) => ({
      ...prev,
      min: "",
      max: "",
    }));
    setIsAmtFilterApplied(false);
  };

  const handleAmtFilterApply = () => {
    if (Object.values(amountLimit).includes("")) return;

    setIsAmtFilterApplied(true);
    setShowAmtFilterDiv(false);
    handleFetch();
  };

  const handleAmtInput = (e) => {
    const { name, value } = e.target;

    const regex = /^\d*\.?\d{0,2}$/;

    if (regex.test(value)) {
      setAmountLimit((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    }
  };

  const handleEnterKey = (e) => {
    if (
      amountLimit.min !== "" &&
      amountLimit.max !== "" &&
      Number(amountLimit.max) >= Number(amountLimit.min) &&
      e.key === "Enter"
    ) {
      handleAmtFilterApply();
    }
  };

  return (
    <>
      <div className="fixed  top-0 left-0 w-screen h-screen  z-[9998]"></div>
      <div className="flex justify-center items-center m-auto fixed z-[9999] p-3">
        <div
          className="h-fit w-70% flex flex-col py-3  bg-[#1E3A6D] rounded-3xl relative"
          style={{
            width: "67vw",
            maxWidth: "1024px",
            height: "622px",
          }}
        >
          <div
            style={{
              position: "absolute",
              zIndex: "999",
              width: "100%",
              borderRadius: "0 0 20px 20px",
              bottom: "0",
              padding: "0 20px",
            }}
            className=" bg-[#244277] h-[50px]"
          >
            <PaginationComponent
              handleSelect={handleSelect}
              selectedValue={selectedValue}
              data={ownerAccStatementData}
              pageSize={pageSize}
              dropdownOptions={dropdownOptions}
              handlePagination={handlePagination}
              currentPage={currentPage}
            />
          </div>

          <div className="flex justify-between items-center mx-4 px-1 py-2 h-[75px]">
            <div>
              <span
                style={{
                  color: "#F9F9F9",
                }}
              >
                {" "}
                Statement for account :{" "}
              </span>
              <span className="statement_header">
                {formatAccountNumber(props?.allData?.bank_acc_no)}
              </span>{" "}
              <span
                style={{
                  color: "#F9F9F9",
                }}
              >
                | IFSC Code :{" "}
              </span>
              <span className="statement_header">
                {" "}
                {props?.allData?.ifsc_code}
              </span>
              <p className="statement_text my-0.5">
                As on {formatDate(new Date())} - Statement update depends on
                availability from the bank
              </p>
            </div>
            <div
              className="flex justify-between h-[36px] w-[320px]"
              style={{
                cursor: "pointer",
              }}
            >
              <div
                onClick={handleToastClick}
                className=" flex bg-[#F05A6A] rounded-[5px] h-[36px] w-[270px]"
              >
                <img
                  className="downloadStatement-icon"
                  src={download_statement_icon}
                  alt="download statement"
                />
                <a
                  // href={dataLoading}
                  // download={`statement.${selectedType}`}
                  className="py-2 px-2 h-[36px] w-[155px] download_statement"
                >
                  Download Statement
                </a>
                <div
                  style={{
                    borderRight: "1px solid #ffffff",
                    margin: "5px 0px",
                  }}
                ></div>
                <div
                  ref={fileTypeRef}
                  className="statement-type"
                  onClick={(e) => e.stopPropagation()}
                >
                  <Button
                    className="pdf-main-btn"
                    onClick={() => {
                      setSelectStatement(!selectStatement);
                    }}
                    style={{
                      height: selectStatement ? "188px" : "36px",
                      outline: selectStatement ? "1px solid #E25869" : "",
                    }}
                  >
                    <div
                      style={{
                        height: "36px",
                        width: "85px",
                      }}
                      className="bank_high_to_low_select_select"
                    >
                      <span
                        style={{
                          fontSize: "12px",
                          paddingLeft: "10px",
                          color: "#F9F9F9",
                        }}
                      >
                        {selectedType}
                      </span>
                      <img
                        src={arrowDown}
                        alt="Status Icon"
                        style={{
                          height: "12px",
                          width: "12px",
                          transform: selectStatement ? "rotate(180deg)" : "",
                        }}
                        className="status_icon-img"
                      />
                    </div>
                    <div
                      style={{
                        position: "absolute",
                        width: "60px",
                        top: "36px",
                        borderBottom: "1px solid #102C5D",
                      }}
                    ></div>
                    <div
                      style={{
                        padding: "8px",
                        top: "26px",
                        width: "85px",
                      }}
                      className="bank_high_to_low_select_scroll"
                    >
                      <Button
                        sx={{
                          width: "70px",
                          backgroundColor: selectedType == "PDF" && "#6D3034",
                        }}
                        className="statement_select_scroll_com"
                        onClick={(e) => {
                          e.stopPropagation();
                          setSelectedType("PDF");
                          setSelectStatement(false);
                        }}
                      >
                        PDF
                        <div
                          style={{
                            width: "70px",
                            display: selectedType == "PDF" ? "flex" : "none",
                            textAlign: "right",
                            justifyContent: "end",
                            paddingRight: "5px",
                          }}
                        >
                          <img
                            style={{
                              height: "13px",
                              width: "11px",
                            }}
                            src={CheckIcon}
                            alt="check"
                          />
                        </div>
                      </Button>
                      <Button
                        sx={{
                          width: "70px",
                          backgroundColor: selectedType == "XLSX" && "#6D3034",
                        }}
                        className="statement_select_scroll_com"
                        onClick={(e) => {
                          e.stopPropagation();
                          setSelectedType("XLSX");

                          setSelectStatement(false);
                        }}
                      >
                        XLSX
                        <div
                          style={{
                            paddingRight: "5px",
                            width: "70px",
                            display: selectedType == "XLSX" ? "flex" : "none",
                            textAlign: "right",
                            justifyContent: "end",
                          }}
                        >
                          <img
                            style={{
                              height: "13px",
                              width: "11px",
                            }}
                            src={CheckIcon}
                            alt="check"
                          />
                        </div>
                      </Button>

                      <Button
                        sx={{
                          width: "70px",
                          backgroundColor: selectedType == "TSV" && "#6D3034",
                        }}
                        className="statement_select_scroll_com"
                        onClick={(e) => {
                          e.stopPropagation();
                          setSelectedType("TSV");

                          setSelectStatement(false);
                        }}
                      >
                        TSV
                        <div
                          style={{
                            paddingRight: "5px",
                            width: "70px",
                            display: selectedType == "TV" ? "flex" : "none",
                            textAlign: "right",
                            justifyContent: "end",
                          }}
                        >
                          <img
                            style={{
                              height: "13px",
                              width: "11px",
                            }}
                            src={CheckIcon}
                            alt="check"
                          />
                        </div>
                      </Button>
                      <Button
                        sx={{
                          width: "70px",
                          backgroundColor: selectedType == "CSV" && "#6D3034",
                        }}
                        className="statement_select_scroll_com"
                        onClick={(e) => {
                          e.stopPropagation();
                          setSelectedType("CSV");

                          setSelectStatement(false);
                        }}
                      >
                        CSV
                        <div
                          style={{
                            paddingRight: "5px",
                            width: "70px",
                            display: selectedType == "CSV" ? "flex" : "none",
                            textAlign: "right",
                            justifyContent: "end",
                          }}
                        >
                          <img
                            style={{
                              height: "13px",
                              width: "11px",
                            }}
                            src={CheckIcon}
                            alt="check"
                          />
                        </div>
                      </Button>
                    </div>
                  </Button>
                </div>
              </div>
              <div className="hover:opacity-70 hover:cursor-pointer h-[36px] ml-2 w-[36px] bg-[#314D7E] rounded-[5px] ">
                <img
                  className="h-[27px] w-[27px] pt-2 pl-2"
                  onClick={handleClose}
                  src={CloseIcon}
                  alt="close"
                />
              </div>
            </div>
          </div>
          <div className="flex h-[52px] mt-1 bg-[#506994]">
            <div
              className="flex justify-between h-[52px] px-5 py-2"
              style={{
                width: "50%",
              }}
            >
              <div>
                {/* <p className="statement_text_value">{dataTable?.owner_account_details?.name}</p> */}
                <p className="statement_text_value">{props?.allData?.name}</p>
                <p className="statement_placeholder">Account name</p>
              </div>

              <div>
                <p className="statement_text_value ">
                  {props?.allData?.account_type
                    .toLowerCase()
                    .replace(/^./, (char) => char.toUpperCase())}
                </p>
                {/* <p className="statement_text_value">
                  {dataTable?.owner_account_details?.account_type}
                </p> */}
                <p className="statement_placeholder text-right">Account type</p>
              </div>
            </div>
            {/* <div className="flex justify-between bg-[#314D7E] w-[341px] h-[52px] px-5 py-2">
              <div>
                <p className="statement_text_value">
                  {formatAccountNumber(props?.allData?.bank_acc_no)}
                </p>
                <p className="statement_placeholder">Account number</p>
              </div>
              <div>
                <p className="statement_text_value">
                  {props?.allData?.ifsc_code}
                </p>
                <p className="statement_placeholder text-right">IFSC code</p>
              </div>
            </div> */}
            <div className="border-[1px] border-[#0C2858] w-[1px] h-[32px] my-2.5 bg-[#314D7E]"></div>
            <div
              className="flex justify-between h-[52px] px-5 py-2"
              style={{
                width: "50%",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-start",
                }}
              >
                {/* <p className="statement_text_value">{dataTable?.owner_account_details?.bank_name}</p> */}
                <p className="statement_text_value">
                  {props?.allData?.bank_name}
                </p>
                <p className="statement_placeholder text-right">Bank name</p>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-end",
                }}
              >
                <p className="statement_text_value">
                  {dataTable?.owner_account_details?.branch || "NA"}
                </p>
                <p className="statement_placeholder">Branch</p>
              </div>
            </div>
          </div>
          <div className="flex h-[56px] mb-1">
            <div className=" w-[341px] h-[56px] px-5 py-4">
              <p className="total_entry">
                Total entries:{" "}
                <span className="total_entry">
                  {dataTable?.total_count || 0}
                </span>
              </p>
            </div>
            <div className=" w-[341px] h-[56px] px-5 py-3">
              <div
                className="statement_calender_main_div  flex flex-col items-center  bg-[#506994] cursor-pointer"
                style={{
                  width: !showDatePicker ? "300px" : "298px",
                  height: !showDatePicker ? "39px" : "37px",
                  border: "1px solid #ff5860",
                  borderRadius: showDatePicker ? "10px 10px 0px 0px" : "5px",
                }}
              >
                <div
                  onClick={() => {
                    setShowDatePicker((prev) => !prev);
                  }}
                  className="flex flex-row items-center justify-between  bg-[#314D7E]  cursor-pointer"
                  style={{
                    width: "298.5px",
                    height: "37px",
                    borderRadius: showDatePicker ? "10px 10px 0px 0px" : "5px",
                  }}
                >
                  <div className="flex justify-center items-center"></div>
                  <div
                    className="statement_calendar_date_heading_div cursor-pointer"
                    style={{
                      borderTopLeftRadius: showDatePicker ? "7px" : "none",
                      borderLeft: showDatePicker ? "1px solid #ff5860" : "none",
                      paddingTop: showDatePicker ? "0" : "10px",
                    }}
                  >
                    <div
                      style={{
                        paddingTop: showDatePicker ? "5px" : "0px",
                      }}
                      className="statement_calendar_date_select_left"
                    >
                      {startDate ? formatDate(startDate) : "Select from date"}
                    </div>
                  </div>
                  <img src={arrowRight} className="h-[15px] w-[15px]" />
                  <div
                    className="statement_calendar_date_select_div cursor-pointer"
                    style={{
                      paddingTop: showDatePicker ? "0" : "10px",
                      borderTopRightRadius: showDatePicker ? "7px" : "none",
                      borderRight: showDatePicker
                        ? "1px solid #ff5860"
                        : "none",
                    }}
                  >
                    <div
                      className="statement_calendar_date_select_right"
                      style={{
                        paddingTop: showDatePicker ? "5px" : "0px",
                        marginLeft: endDate ? "0px" : "10px",
                      }}
                    >
                      {endDate ? formatDate(endDate) : "Select to date"}
                    </div>
                  </div>
                </div>
                {showDatePicker && (
                  <DatePickerComponent
                    owner={true}
                    startDate={startDate}
                    endDate={endDate}
                    handleDateChange={handleDateChange}
                    handleCancel={handleCancel}
                    handleConfirm={handleConfirm}
                    showDatePicker={showDatePicker}
                    inline
                    startDateClassName="selected-start-date"
                    endDateClassName="selected-end-date"
                  />
                )}
              </div>
            </div>
          </div>
          <div className="table-div-container mb-1">
            <p className="label-div-trans">Transaction date and time</p>
            <div className="border-mid-div"></div>
            <p className="label-div-val">Value date</p>
            <div className="border-mid-div"></div>
            <p className="label-div-ref">Bank reference no.</p>
            <div className="border-mid-div"></div>
            <p className="label-div-narr">Narration</p>
            <div className="border-mid-div"></div>
            {/* <p className="label-div-type">Type</p> */}
            <div ref={typeDivRef} className="balance_type_statement">
              <div
                className="type-main-btn"
                onClick={() => {
                  setShowStatementType(!showStatementType);
                  setShowAmtFilterDiv(false);
                }}
                style={{
                  height: showStatementType
                    ? "158px"
                    : dataTable?.owner_account_details?.statement.length > 0
                    ? "45px"
                    : "51px",
                }}
              >
                <div
                  style={{
                    width: "93px",
                    height: "51px",
                    backgroundColor: "#506994",
                    cursor: "pointer",
                    display: "flex",
                    justifyContent: "space-evenly",
                    alignItems: "center",
                  }}
                  className="bank_high_to_low_select_select"
                >
                  <span
                    style={{
                      fontSize: "12px",
                    }}
                  >
                    {selectStatementType}
                  </span>
                  <img
                    src={arrowDown}
                    alt="Status Icon"
                    style={{
                      height: "12px",
                      width: "12px",
                      marginRight: "3px",
                      transform: showStatementType ? "rotate(180deg)" : "",
                    }}
                    className="status_icon-img"
                  />
                </div>
                <div
                  style={{
                    position: "absolute",
                    width: "60px",
                    top: "51px",
                    marginLeft: "10px",
                    border: "1px solid #102C5D",
                  }}
                ></div>
                <div
                  style={{
                    padding: "8px",
                    top: "40px",
                    width: "80px",
                  }}
                  className="bank_high_to_low_select_scroll"
                >
                  <Button
                    sx={{
                      width: "65px",
                      backgroundColor:
                        selectStatementType == "Type" && "#3A5686",
                    }}
                    className="statement_select_scroll_com"
                    onClick={(e) => {
                      e.stopPropagation();
                      setSelectStatementType("Type");
                      setShowStatementType(false);
                      setCurrentPage(1);
                      setCurrentPageText("01");
                    }}
                  >
                    All
                    <div
                      style={{
                        width: "65px",
                        display:
                          selectStatementType == "Type" ? "flex" : "none",
                        textAlign: "right",
                        justifyContent: "end",
                        paddingRight: "5px",
                      }}
                    >
                      <img
                        style={{
                          height: "13px",
                          width: "11px",
                        }}
                        src={CheckIcon}
                        alt="check"
                      />
                    </div>
                  </Button>
                  <Button
                    sx={{
                      width: "65px",
                      backgroundColor:
                        selectStatementType == "Credit" && "#3A5686",
                    }}
                    className="statement_select_scroll_com"
                    onClick={(e) => {
                      e.stopPropagation();
                      setSelectStatementType("Credit");
                      setShowStatementType(false);
                      setCurrentPage(1);
                      setCurrentPageText("01");
                    }}
                  >
                    Credit
                    <div
                      style={{
                        paddingRight: "5px",
                        width: "65px",
                        display:
                          selectStatementType == "Credit" ? "flex" : "none",
                        textAlign: "right",
                        justifyContent: "end",
                      }}
                    >
                      <img
                        style={{
                          height: "13px",
                          width: "11px",
                        }}
                        src={CheckIcon}
                        alt="check"
                      />
                    </div>
                  </Button>
                  <Button
                    sx={{
                      width: "65px",
                      backgroundColor:
                        selectStatementType == "Debit" && "#3A5686",
                    }}
                    className="statement_select_scroll_com"
                    onClick={(e) => {
                      e.stopPropagation();
                      setSelectStatementType("Debit");
                      setShowStatementType(false);
                      setCurrentPage(1);
                      setCurrentPageText("01");
                    }}
                  >
                    Debit
                    <div
                      style={{
                        paddingRight: "5px",
                        width: "65px",
                        display:
                          selectStatementType == "Debit" ? "flex" : "none",
                        textAlign: "right",
                        justifyContent: "end",
                      }}
                    >
                      <img
                        style={{
                          height: "13px",
                          width: "11px",
                        }}
                        src={CheckIcon}
                        alt="check"
                      />
                    </div>
                  </Button>
                </div>
              </div>
            </div>
            <div className="border-mid-div"></div>
            {/* <p className="label-div-amt">Amount</p> */}
            {/* TODO: see if showsStatement to be changed for the amount */}
            <div ref={amtDivRef} className="amtDiv_type">
              <div
                className="amount-main-btn"
                onClick={(e) => {
                  e.stopPropagation();
                  setShowAmtFilterDiv(!showAmtFilterDiv);
                  setShowStatementType(false);
                }}
                style={{
                  height: showAmtFilterDiv
                    ? "257px"
                    : dataTable?.owner_account_details?.statement.length > 0
                    ? "45px"
                    : "51px",
                  cursor: "default",
                }}
              >
                <div
                  style={{
                    height: "51px",
                    backgroundColor: "#506994",
                    display: "flex",
                    justifyContent: "space-evenly",
                    alignItems: "center",
                    cursor: "pointer",
                    borderRight: "1px solid #1d3a6d",
                    zIndex: "9999",
                  }}
                  className="bank_high_to_low_select_select"
                >
                  <span
                    style={{
                      fontSize: "12px",
                      // paddingLeft: "10px",
                    }}
                  >
                    Amount
                  </span>
                  {isAmtFilterApplied ? (
                    <img
                      src={filterIcon}
                      alt="Status Icon"
                      style={{
                        height: "12px",
                        width: "12px",
                      }}
                      className="status_icon-img"
                    />
                  ) : (
                    <img
                      src={arrowDown}
                      alt="Status Icon"
                      style={{
                        height: "12px",
                        width: "12px",

                        transform: showAmtFilterDiv ? "rotate(180deg)" : "",
                      }}
                      className="status_icon-img"
                    />
                  )}
                </div>

                <div
                  style={{
                    position: "absolute",
                    width: "130px",
                    marginLeft: "10px",
                    top: "50px",
                    border: "1px solid #102C5D",
                  }}
                ></div>
                <div
                  onClick={(e) => e.stopPropagation()}
                  className="amount-inputs-div"
                >
                  <div className="input-container">
                    <label for="min">Minimum</label>
                    <div
                      style={{
                        position: "relative",
                      }}
                    >
                      <p
                        style={{
                          position: "absolute",
                          left: "8px",
                          top: "5px",
                          fontSize: "12px",
                          display: amountLimit.min === "" ? "none" : "block",
                        }}
                      >
                        ₹
                      </p>

                      <input
                        id="min"
                        onChange={handleAmtInput}
                        type="number"
                        value={amountLimit.min}
                        name="min"
                        placeholder="Enter amount"
                        onKeyDown={handleEnterKey}
                      />
                    </div>
                  </div>

                  <div className="input-container">
                    <label for="max">Maximum</label>
                    <div
                      style={{
                        position: "relative",
                      }}
                    >
                      <p
                        style={{
                          position: "absolute",
                          left: "8px",
                          top: "5.5px",
                          fontSize: "12px",
                          display: amountLimit.max === "" ? "none" : "block",
                        }}
                      >
                        ₹
                      </p>

                      <input
                        id="max"
                        onChange={handleAmtInput}
                        type="number"
                        value={amountLimit.max}
                        name="max"
                        placeholder="Enter amount"
                        onKeyDown={handleEnterKey}
                      />
                    </div>
                  </div>
                  <p
                    style={{
                      marginTop: "10px",
                      marginBottom: "10px",
                    }}
                  >
                    * Specify the amount to filter by.
                  </p>
                  <div className="inputs-div-btns">
                    <button
                      className={`clear-button ${
                        amountLimit.min === "" || amountLimit.max === ""
                          ? "disabled"
                          : ""
                      }`}
                      onClick={handleClearAmts}
                    >
                      Clear
                    </button>

                    <button
                      onClick={handleAmtFilterApply}
                      className={`apply-button ${
                        amountLimit.min === "" ||
                        amountLimit.max === "" ||
                        Number(amountLimit.min) >= Number(amountLimit.max)
                          ? "disabled"
                          : ""
                      }`}
                    >
                      Apply
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className="border-mid-div"></div>
            <p className="label-div-clamt">Closing Balance</p>
          </div>
          {ownerAccStatementLoading ? (
            <div className="loader-container m-auto justify-center items-center flex">
              <img className="w-[100px] h-[100px]" src={dataLoading} alt="" />
            </div>
          ) : dataTable?.owner_account_details?.statement.length > 0 ? (
            <div className="h-[354px] max-h-[354px] overflow-auto mb-[40px]">
              {dataTable?.owner_account_details?.statement.map((item, i) => {
                return (
                  <>
                    <div className="table-data-container my-0.5">
                      <p className="table-label-div-transaction">
                        {formatDateandTime(
                          new Date(item.transaction_date),
                          true
                        )}
                      </p>
                      <p className="table-label-div-value">
                        {formatDateandTime(new Date(item.value_date))}
                      </p>
                      <p className="table-label-div-ref ">
                        {item?.bank_reference}
                      </p>
                      <p className="table-label-div-narr">{item.narration}</p>
                      <p className="table-label-div-type">
                        {item.credit_debit}
                      </p>
                      <p
                        style={{
                          color:
                            item.credit_debit == "Debit"
                              ? "#F05A6A"
                              : "#4DE658",
                          textAlign: "right",
                        }}
                        className="table-label-div-amt"
                      >
                        ₹ <FormatAmount price={item.amount} />
                      </p>
                      <p
                        className="table-label-div-cl-amt"
                        style={{
                          textAlign: "right",
                        }}
                      >
                        ₹ <FormatAmount price={item.closing_balance} />
                      </p>
                    </div>
                    <div
                      style={{
                        display:
                          i ==
                          dataTable?.owner_account_details?.statement?.length -
                            1
                            ? "none"
                            : "block",
                      }}
                      className="border-sec mx-4"
                    ></div>
                  </>
                );
              })}
            </div>
          ) : (
            <div className="flex flex-col items-center justify-center m-auto">
              <img
                src={noaccount}
                alt="No accounts found"
                className="statement_noacc_found"
              />
              <p className="no-beneaccount-text2">
                No data found for the applied filter
              </p>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default OwnerViewStatementModal;
