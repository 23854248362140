import React, { useEffect, useState } from "react";
import ErrorPage from "../../../../../assets/Graphics/Error page graphic.svg";
import DeclinedRed from "../../../../../assets/ErrorIcons/error_with_white_outlined_circle.svg";
import WhitedashBoard from "../../../../../assets/GeneralIcons/dashboard.svg";
import "./index.css";
import { Tooltip, tooltipClasses } from "@mui/material";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import FormatAmount from "../../../../../components/_utils/FormatAmount/FormatAmount";
import ArrowDownCircle from "../../../../../assets/ArrowsIcons/arrow_down_blue_white.svg";
import ArrowRightBlue from "../../../../../assets/ArrowsIcons/arrow_right_blue.svg";
import { formatAccountNumber } from "../../../../../components/_utils/FormatAccountNumber";

const IntenralMultiApprovalTxnError = () => {
  const { internalTxnMultiApprovalError, internalTxnMultiRejectApprovalError } =
    useSelector((state) => state.internalTxnViewall);
  const { internalTxnViewAllAccData } = useSelector(
    (state) => state?.internalTxnViewall
  );
  //   console.log(internalTxnViewAllAccData.transcation);

  const [ErrorPageData, setErrorPageData] = useState([]);
  const [activeTab, setActiveTab] = useState(true);
  const [isMobileResponsive, setIsMobileResponsive] = useState(
    window.innerWidth <= 800
  );

  const findCommonNames = (arr1, arr2) => {
    let type = arr2?.some((item) => typeof item === "string");
    if (type) {
      return arr2;
    } else {
      const arr2Map = new Map(arr2?.map((item) => [item?.id, item?.errors]));

      return arr1
        .filter((item) => arr2Map?.has(item.txn_id))
        .map((item) => ({
          ...item,
          errors: arr2Map?.get(item.txn_id),
        }));
    }
  };
  const filterErrorTxn = findCommonNames(
    ErrorPageData,
    JSON.parse(sessionStorage?.getItem("selectedDataError"))
  );
  useEffect(() => {
    const storedSelectedApproval = JSON.parse(
      sessionStorage?.getItem("selectedData")
    );

    if (storedSelectedApproval) {
      setErrorPageData(storedSelectedApproval?.selectedSendTransactions);
    } else {
      setErrorPageData([]);
    }
  }, []);
  useEffect(() => {
    const handleResize = () => {
      setIsMobileResponsive(window.innerWidth <= 800);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [window.innerWidth]);

  const renderAccount = (account, index, isErrorPage) =>
    isMobileResponsive ? (
      <>
        <div className="w-full h-[30px] bg-[#DADADA]">
          <div className="flex justify-between items-center px-[15px] pt-1">
            <p className="h-[22px] font-semibold text-[14px] text-[#4DDD37] opacity-100">
              ₹ {<FormatAmount price={account?.amount} />}
            </p>
            <span className="font-sans text-[11px] text-[#707070] opacity-100">
              Amount
            </span>
          </div>
        </div>
        <div className="flex flex-col gap-[10px]">
          <div className="flex flex-col items-start gap-[10px] pt-[10px] px-[15px]">
            <div>
              <Tooltip
                componentsProps={{
                  popper: {
                    sx: {
                      [`& .${tooltipClasses.tooltip}`]: {
                        backgroundColor: (theme) => theme.palette.common.black,
                      },
                    },
                  },
                }}
                title={account?.owner_account_bank_acc_no}
              >
                <p className="w-[167px] h-[22px] text-left font-sans font-medium text-[14px] text-[#1D1D1D]">
                  {formatAccountNumber(account?.owner_account_bank_acc_no)}
                </p>
              </Tooltip>
              <span className="flex text-left font-sans text-[11px] text-[#707070] opacity-100">
                Source account number
              </span>
            </div>
          </div>
          <div className="relative border-b border-[#DADADA]">
            <img
              src={ArrowDownCircle}
              alt="Arrow Down"
              className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-[30px] h-[30px]"
            />
          </div>

          <div className="flex flex-col justify-center items-start gap-[10px] pt-[10px] pb-[15px] px-[15px]">
            <div>
              <Tooltip
                componentsProps={{
                  popper: {
                    sx: {
                      [`& .${tooltipClasses.tooltip}`]: {
                        backgroundColor: (theme) => theme.palette.common.black,
                      },
                    },
                  },
                }}
                title={account?.beneficiary_account_bank_acc_no}
              >
                <p className="whitespace-nowrap overflow-ellipsis overflow-hidden first-line:capitalize lowercase w-[167px] h-[22px] text-left font-sans font-medium text-[14px] text-[#1D1D1D]">
                  {formatAccountNumber(
                    account?.beneficiary_account_bank_acc_no
                  )}
                </p>
              </Tooltip>
              <span className="flex text-left font-sans text-[11px] text-[#707070] opacity-100">
                Destination account number
              </span>
            </div>
          </div>
        </div>

        {isErrorPage
          ? index < ErrorPageData.length - 1 && (
              <div
              // style={{
              //   border: "1px solid #DADADA",
              //   height: "0px",
              //   width: "411px",
              //   opacity: "100",
              // }}
              ></div>
            )
          : index < ErrorPageData.length - 1 && (
              <div
              // style={{
              //   border: "1px solid #DADADA",
              //   height: "0px",
              //   width: "411px",
              //   opacity: "100",
              // }}
              ></div>
            )}
      </>
    ) : (
      <>
        <div className="w-full h-8 bg-[#DADADA] flex items-center">
          <div className="flex justify-between px-[30px] w-full">
            <p className="font-semibold text-sm text-[#4DDD37]">
              ₹ {<FormatAmount price={account?.amount} />}
            </p>
            <span className="font-sans text-[11px] text-[#707070] opacity-100">
              Amount
            </span>
          </div>
        </div>

        <div className="flex my-[15px] justify-between px-[30px]">
          <div className="flex justify-start items-center gap-[10px] w-full">
            <div>
              <Tooltip
                componentsProps={{
                  popper: {
                    sx: {
                      [`& .${tooltipClasses.tooltip}`]: {
                        backgroundColor: (theme) => theme.palette.common.black,
                      },
                    },
                  },
                }}
                title={account?.owner_account_bank_acc_no}
              >
                <p className="w-[167px] h-[22px] text-left font-sans font-semibold text-[16px] leading-[22px] tracking-normal text-[#1D1D1D]">
                  {formatAccountNumber(account?.owner_account_bank_acc_no)}
                </p>
              </Tooltip>
              <span className="flex items-start font-sans text-[12px] leading-[17px] tracking-normal text-[#707070] opacity-100">
                Source account number
              </span>
            </div>
          </div>

          <img
            src={ArrowRightBlue}
            alt="ArrowRightBlue"
            className="h-[18px] w-[18px] self-center"
          />

          <div className="flex justify-end items-start gap-[10px] w-full">
            <div className="text-right">
              <Tooltip
                componentsProps={{
                  popper: {
                    sx: {
                      [`& .${tooltipClasses.tooltip}`]: {
                        backgroundColor: (theme) => theme.palette.common.black,
                      },
                    },
                  },
                }}
                title={account?.beneficiary_account_bank_acc_no}
              >
                <p className="w-[167px] h-[22px] text-right font-sans font-semibold text-[16px] leading-[22px] tracking-normal text-[#1D1D1D]">
                  {formatAccountNumber(
                    account?.beneficiary_account_bank_acc_no
                  )}
                </p>
              </Tooltip>
              <span className="flex items-start ml-3 font-sans text-[12px] leading-[17px] tracking-normal text-[#828282] opacity-100">
                Destination account number
              </span>
            </div>
          </div>
        </div>

        {isErrorPage
          ? index < ErrorPageData.length - 1 && (
              <div
              // style={{
              //   marginTop: "10px",
              //   border: "1px solid #DADADA",
              //   height: "0px",
              //   width: "411px",
              //   opacity: "100",
              // }}
              ></div>
            )
          : index < ErrorPageData.length - 1 && (
              <div
              // style={{
              //   marginTop: "10px",
              //   border: "1px solid #DADADA",
              //   height: "0px",
              //   width: "411px",
              //   opacity: "100",
              // }}
              ></div>
            )}
      </>
    );
  return (
    <>
      {isMobileResponsive ? (
        <div
          className=" bg-[#754545]"
          style={{
            height: "100vh",
          }}
        >
          <div className="flex flex-col justify-center items-center">
            <img
              className="h-[177px] w-[250px] mt-5"
              src={ErrorPage}
              alt="ErrorPage"
            />
            <h2 className="text-center text-[22px] font-semibold text-[#F9F9F9]">
              Oops,
            </h2>
            <p className="text-center pt-[1px] text-[15px] font-semibold text-[#F9F9F9] opacity-100">
              {internalTxnMultiApprovalError
                ? "Transaction approval failed"
                : internalTxnMultiRejectApprovalError
                ? "Transaction rejection failed"
                : ""}
            </p>
            <span className="w-[298px] text-center text-[12px] pt-[4px] pb-[20px] font-normal text-[#F9F9F9]">
              An error occurred with the following transactions, causing the
              {internalTxnMultiApprovalError ? "approval" : "rejection"} of all
              the transactions to fail.
            </span>
          </div>
          <div
            className=""
            style={{
              height: "calc(100vh - 300px)",
            }}
          >
            <div
              className=" flex justify-between h-[46px]"
              style={{
                background: "#985555",
              }}
            >
              <div
                className="w-[50%] h-full opacity-100 flex justify-center items-center cursor-pointer"
                style={{
                  backgroundColor: activeTab === true ? "#985555" : "#864C4C",
                }}
                onClick={() => {
                  setActiveTab(true);
                }}
              >
                <h4 className="w-[72px] h-[16px] text-left text-[12px] font-semibold  text-[#F9F9F9]">
                  Error details
                </h4>
              </div>
              <div
                className="w-[50%] h-full opacity-100 flex justify-center items-center cursor-pointer"
                style={{
                  backgroundColor: activeTab === false ? "#985555" : "#864c4c",
                }}
                onClick={() => {
                  setActiveTab(false);
                }}
              >
                <h4 className="w-[111px] h-[16px] text-left text-[12px] font-semibold text-[#F9F9F9]">
                  Transaction details
                </h4>
              </div>
            </div>
            <div
              className=" bg-[#F9F9F9]"
              style={{
                height: "calc(100vh - 414px)",
              }}
            >
              {activeTab === false ? (
                <div
                  className="flex pb-[20px]"
                  style={{
                    overflowY: ErrorPageData.length > 4 ? "scroll" : "hidden",
                    overflowX: "hidden",
                    height: "calc(100vh - 430px)",
                    paddingBottom: "20px",
                  }}
                >
                  <div className="w-full mt-[15px]">
                    {Array.isArray(filterErrorTxn) &&
                    filterErrorTxn.length > 0 ? (
                      filterErrorTxn.some(
                        (error) =>
                          typeof error === "string" &&
                          error.includes(
                            "No transactions found for transaction ids"
                          )
                      ) ? (
                        ErrorPageData?.map((account, i) =>
                          renderAccount(account, i, true)
                        )
                      ) : (
                        filterErrorTxn.map((account, i) => {
                          return renderAccount(account, i);
                        })
                      )
                    ) : (
                      <p>No transactions available.</p>
                    )}
                  </div>
                </div>
              ) : (
                <div
                  className="h-full pt-[15px] flex flex-col"
                  style={{
                    overflow: "hidden",
                    width: "100%",
                    overflowY: "auto",
                    paddingTop: "15px",
                    paddingBottom: "20px",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  {filterErrorTxn?.map((val, i) => {
                    return (
                      <div key={i}>
                        {val?.errors ? (
                          <>
                            {typeof val.errors[0]?.reason === "string" ? (
                              <div className="multiItInner_error_msg_title">
                                {i === 0 ? (
                                  <>
                                    <h4>No transactions found.</h4>
                                    <p>{val.errors[0].reason}</p>
                                  </>
                                ) : null}
                              </div>
                            ) : (
                              <div className="w-full">
                                <div className="w-full h-[26px] bg-[#DADADA]s">
                                  <div className="multiItInner_error_msg_nameMob">
                                    <h4>
                                      {formatAccountNumber(
                                        val?.owner_account_bank_acc_no
                                      )}
                                    </h4>
                                    <img
                                      src={ArrowRightBlue}
                                      alt="ArrowRightBlue"
                                      className="h-[18px] w-[18px] self-center"
                                    />

                                    <h6>
                                      {formatAccountNumber(
                                        val?.beneficiary_account_bank_acc_no
                                      )}
                                    </h6>
                                  </div>
                                </div>

                                {val?.errors.map((error, index) => (
                                  <div
                                    className="px-[15px] pt-3 text-[14px] font-normal text-[#1D1D1D] pb-[15px]"
                                    key={index}
                                    style={{
                                      borderBottom:
                                        val.errors.length - 1 === index
                                          ? ""
                                          : "1px solid #dadada",
                                    }}
                                  >
                                    <h4 className="">
                                      {error?.reason || error}
                                    </h4>
                                    <p className="w-[330px] h-[16px] text-[12px] font-normal text-[#707070] pt-[3px] ">
                                      Error message
                                    </p>
                                  </div>
                                ))}
                              </div>
                            )}
                          </>
                        ) : (
                          <div className="pl-[15px] pt-1 text-[14px] font-normal text-[#1D1D1D] pb-[15px]">
                            <h4>No transactions found.</h4>
                            <p className="w-[330px] h-[16px] text-[12px] font-normal text-[#707070] pt-[3px]">
                              Error message
                            </p>
                          </div>
                        )}
                      </div>
                    );
                  })}
                </div>
              )}
            </div>
            <div className="  relative h-[105px] rounded-tl-[20px] bg-[#985555] top-[-24px] rounded-tr-[20px]">
              <Link to={"/transaction/internal_transaction/dashboard"}>
                <div className="flex flex-col gap-1 justify-center items-center h-full">
                  <img
                    className="w-[33px] h-[33px]"
                    src={WhitedashBoard}
                    alt=""
                  />
                  <p className="w-[63px] h-[32px]  text-center text-[12px] font-semibold  text-[#F9F9F9]">
                    Go to dashboard
                  </p>
                </div>
              </Link>
            </div>
          </div>
        </div>
      ) : (
        <div className="min-h-screen bg-[#754545]">
          <div className="flex justify-center">
            <div className="flex flex-row max-w-screen-lg justify-center">
              <div className="flex-1 pt-28">
                <div className="flex min-w-screen mx-5 justify-center items-center">
                  <div className="p-6 flex flex-col items-center justify-center text-center text-white text-2xl">
                    <img
                      className="w-[424px] h-[300px]"
                      src={ErrorPage}
                      alt="ErrorPage"
                    />
                    <div className="pt-[1px] flex flex-col justify-center items-center">
                      <p className="w-[159px] h-[76px] text-left font-sans font-bold text-[56px] leading-[76px] tracking-normal text-[#FAFAFA] opacity-100">
                        Oops,
                      </p>
                      <span className="h-[43px] text-left font-sans font-semibold text-[28px] leading-[43px] tracking-normal text-[#FAFAFA] opacity-100">
                        {internalTxnMultiApprovalError
                          ? "Transaction approval failed"
                          : internalTxnMultiRejectApprovalError
                          ? "Transaction rejection failed"
                          : ""}
                      </span>
                      <span className="w-[470px] h-[27px] text-center  mt-[5px] font-sans text-[20px] leading-[27px] tracking-normal text-white opacity-100">
                        An error occurred with the following transactions,
                        <br />
                        causing the
                        {internalTxnMultiApprovalError
                          ? "approval"
                          : "rejection"}
                        of all the transactions to fail.
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="flex-1 pt-[145px] pb-2">
                <div className="flex flex-col h-[554px] w-[472px] mx-5 bg-[#FFFFFF] rounded-[30px] items-center justify-between">
                  <div className="h-[40px] w-[472px] flex justify-center items-center bg-[#985555] rounded-tl-[27px] rounded-tr-[27px]">
                    <div
                      className="multiIt_error_swap_tab_left"
                      style={{
                        backgroundColor:
                          activeTab === true ? "#985555" : "#864c4c",
                      }}
                      onClick={() => {
                        setActiveTab(true);
                      }}
                    >
                      <h4>Error details</h4>
                    </div>
                    <div
                      className="multiIt_error_swap_tab_right"
                      style={{
                        backgroundColor:
                          activeTab === false ? "#985555" : "#864c4c",
                      }}
                      onClick={() => {
                        setActiveTab(false);
                      }}
                    >
                      <h4>Transaction details</h4>
                    </div>
                  </div>
                  {activeTab === false ? (
                    <div className="h-[335px] w-[472px] overflow-y-auto">
                      <div
                        className="w-full mt-5"
                        style={{ height: "fit-content" }}
                      >
                        {Array.isArray(filterErrorTxn) &&
                        filterErrorTxn.length > 0 ? (
                          filterErrorTxn.some(
                            (error) =>
                              typeof error === "string" &&
                              error.includes(
                                "No transactions found for transaction ids"
                              )
                          ) ? (
                            ErrorPageData?.map((account, i) =>
                              renderAccount(account, i, true)
                            )
                          ) : (
                            filterErrorTxn.map((account, i) => {
                              return renderAccount(account, i);
                            })
                          )
                        ) : (
                          <p>No transactions available.</p>
                        )}
                      </div>
                    </div>
                  ) : (
                    <div
                      className="h-[335px] w-[472px] "
                      style={{
                        overflowY: "auto",
                        marginTop: "25px",
                        paddingBottom: "20px",
                        display: "flex",
                        flexDirection: "column",
                      }}
                    >
                      {filterErrorTxn?.map((val, i) => {
                        return (
                          <div key={i}>
                            {val?.errors ? (
                              <>
                                {typeof val.errors[0]?.reason === "string" ? (
                                  <div className="multiItInner_error_msg_title">
                                    {i === 0 ? (
                                      <>
                                        <h4>No transactions found.</h4>
                                        <p>{val.errors[0].reason}</p>
                                      </>
                                    ) : null}
                                  </div>
                                ) : (
                                  <div className="multiItInner_error_msg">
                                    <div className="multiItInner_error_msg_name">
                                      <h4>
                                        {formatAccountNumber(
                                          val?.owner_account_bank_acc_no
                                        )}
                                      </h4>
                                      <img
                                        src={ArrowRightBlue}
                                        alt="ArrowRightBlue"
                                        className="h-[18px] w-[18px] self-center"
                                      />

                                      <h6>
                                        {formatAccountNumber(
                                          val?.beneficiary_account_bank_acc_no
                                        )}
                                      </h6>
                                    </div>

                                    {val?.errors.map((error, index) => (
                                      <div
                                        className="multiItInner_error_msg_title"
                                        key={index}
                                        style={{
                                          borderBottom:
                                            val.errors.length - 1 === index
                                              ? ""
                                              : "1px solid #dadada",
                                        }}
                                      >
                                        <h4>{error?.reason || error}</h4>
                                        <p>Error message</p>
                                      </div>
                                    ))}
                                  </div>
                                )}
                              </>
                            ) : (
                              <div className="multiItInner_error_msg_title">
                                <h4>No transactions found.</h4>
                                <p>Error message</p>
                              </div>
                            )}
                          </div>
                        );
                      })}
                    </div>
                  )}
                  <div className="flex justify-center items-center h-[136px] w-[472px] bg-[#985555] rounded-bl-[27px] rounded-br-[27px]">
                    <Link to={"/transaction/internal_transaction/dashboard"}>
                      <div className="w-[62px] flex justify-center items-center flex-col gap-[4px]  h-[76px]">
                        <img
                          className="w-[40px]  h-[40px] "
                          src={WhitedashBoard}
                          alt=""
                        />
                        <p className="w-[62px] h-[32px] text-center font-semibold text-[12px] leading-[15px] tracking-[0px] text-[#FFFFFF] opacity-100">
                          Go to dashboard
                        </p>
                      </div>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default IntenralMultiApprovalTxnError;
