import React, { useEffect, useState } from "react";
import "./index.css";
import successImage from "../../../assets/NewImg.png";
import createTransaction from "../../../assets/create_transaction.png";
import viewDetails from "../../../assets/view_details.png";
// import ArrowDown from "../../../assets/ArrowsIcons/arrow_down_blue_box.svg";
import ArrowDown from "../../../assets/ArrowsIcons/arrow_right_blue.svg";
import dashboard from "../../../assets/Dashboard.png";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { checkUsertoken } from "../../../redux/features/login/login";
import FormatAmount from "../../../components/_utils/FormatAmount/FormatAmount";
import { formatAccountNumber } from "../../../components/_utils/FormatAccountNumber";
import IfscCodeMapper from "../../../components/_utils/IfscLogoMapper";
import { beneInternalTxnSuccessDetail } from "../../../redux/features/internalTransaction/internalTransactionCreate";
import { resetViewAllAcc } from "../../../redux/features/beneficiary/beneAllVerification/beneAllVerification";
import pending_yellow from "../../../assets/PendingIcons/pending_yellow.svg";

const InternalTxnSuccessPage = () => {
  const [isMobileView, setIsMobileView] = useState(window.innerWidth <= 1024);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { id } = useParams();
  const transaction = useSelector((state) => state.beneTransactionAccounts);

  const {
    beneInternalTxnSuccessData,
    beneInternalTxnSuccessDataError,
    beneInternalTxnSuccessLoading,
  } = useSelector((state) => state?.internalTransactionCreate);

  useEffect(() => {
    if (
      beneInternalTxnSuccessDataError == "Request failed with status code 404"
    ) {
      navigate("*");
    }
  }, [beneInternalTxnSuccessDataError]);

  useEffect(() => {
    const handleResize = () => {
      setIsMobileView(window.innerWidth <= 1024);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    dispatch(checkUsertoken());
    dispatch(beneInternalTxnSuccessDetail(id));
  }, [dispatch, id]);

  const viewDetailsHandler = () => {
    navigate(`/transaction/internal_transaction/details/${id}`);
  };

  const createAccountHandler = () => {
    navigate("/transaction/internal_transaction/create");
    dispatch(resetViewAllAcc());
  };

  const dashboardHandler = () => {
    navigate("/transaction/internal_transaction/dashboard");
  };

  return (
    <>
      {!isMobileView && (
        <div className="min-h-screen bg-[#457549]">
          <div className="flex justify-center">
            <div className="flex flex-row max-w-screen-lg justify-center">
              <div className="flex-1 pt-48">
                <div className="flex min-w-screen mx-5 justify-center items-center bg-[#457549]">
                  <div className="p-6 flex flex-col items-center justify-center text-center text-white text-2xl">
                    <img
                      src={successImage}
                      width={"100%"}
                      height={"100%"}
                      alt=""
                      srcSet=""
                    />
                    <div className="pt-7">
                      <p className="text-xl">
                        {beneInternalTxnSuccessData?.transaction[0]
                          .lifecycle_status === "settled"
                          ? "Internal transaction settled successfully!"
                          : beneInternalTxnSuccessData?.transaction[0]
                            .lifecycle_status === "pending"
                            ? "Internal transaction created successfully and pending."
                            : beneInternalTxnSuccessData?.transaction[0]
                              .lifecycle_status === "approved"
                              ? <>
                                <p className="txnMessage_top">
                                  Internal transaction has been created and approved.
                                </p>
                                <p className="txnMessage_bottom">
                                  It will be auto settled once sufficient balance is
                                  available in the source account.
                                </p>
                              </>
                              : ""}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="flex-1 pt-32">
                <div className="flex flex-col mx-5 mb-5 bg-white rounded-t-[32px] rounded-b-[38px] items-center justify-between">
                  <div className="w-full flex flex-col gap-1  pb-2">
                    <div className="flex flex-col font-bold pt-5 pb-3 px-7">
                      <div className="text-bold text-2xl text-[#27B882] w-full text-center pb-2">
                        <p
                          className="w-96"
                          style={{
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            whiteSpace: " nowrap",
                            textTransform: "capitalize",
                          }}
                        >
                          ₹
                          <FormatAmount
                            price={
                              beneInternalTxnSuccessData?.transaction[0]
                                ?.amount == undefined
                                ? 0
                                : beneInternalTxnSuccessData?.transaction[0]
                                  ?.amount
                            }
                          />
                        </p>
                      </div>
                    </div>

                    <div className="px-7 w-full content-center">
                      <div className="mx-0 dashedLine"></div>
                    </div>
                    <div className="successDetailText"></div>

                    <div className="pl-[30px]">
                      <div>
                        <p className="text-left font-normal text-[15px] leading-[22px] tracking-normal text-black opacity-100">
                          {formatAccountNumber(
                            beneInternalTxnSuccessData?.transaction[0]
                              ?.owner_account_bank_acc_no
                          )}
                        </p>
                        <span className="w-[134px] text-left font-normal text-[12px] leading-[17px] tracking-normal text-[#828282] opacity-100">
                          Source account number
                        </span>
                      </div>
                      <div className="mr-[30px] mt-[12px] h-[2px] bg-[#DADADA]"></div>
                      <div className="flex justify-between pt-4">
                        <div>
                          <p className="w-[97px] h-[22px] text-left font-normal text-[15px] leading-[22px] tracking-normal text-black opacity-100">
                            {
                              beneInternalTxnSuccessData?.transaction[0]
                                ?.owner_account_ifsc_code
                            }
                          </p>
                          <span className="w-[54px] h-[17px] text-left font-normal text-[12px] leading-[17px] tracking-normal text-[#828282] opacity-100">
                            IFSC code
                          </span>
                        </div>
                        <div className="flex flex-row w-2/6 h-5 mt-3 justify-center">
                          <img
                            src={require("../../../assets/IfscLogo/" +
                              IfscCodeMapper(
                                beneInternalTxnSuccessData?.transaction[0]
                                  ?.owner_account_ifsc_code
                              ))}
                            alt="bank"
                          />
                        </div>
                      </div>
                    </div>

                    <div className="flex justify-between pl-[30px] pr-[30px] pt-2">
                      <div className="w-[188px] h-0 border border-[#DADADA] opacity-100"></div>
                      <div className="w-[20px] h-[20px] relative top-[-10px]">
                        <img className="rotate-90" src={ArrowDown} alt="" />
                      </div>
                      <div className="w-[188px] h-0 border border-[#DADADA] opacity-100"></div>
                    </div>

                    <div className="pl-[30px] mb-[30px]">
                      <div>
                        <p className="text-left font-normal text-[15px] leading-[22px] tracking-normal text-black opacity-100">
                          {formatAccountNumber(
                            beneInternalTxnSuccessData?.transaction[0]
                              ?.beneficiary_account_bank_acc_no
                          )}
                        </p>
                        <span className="w-[134px] text-left font-normal text-[12px] leading-[17px] tracking-normal text-[#828282] opacity-100">
                          Destination account number
                        </span>
                      </div>
                      <div className="mr-[30px] mt-[12px] h-[2.5px] bg-[#DADADA]"></div>
                      <div className="flex justify-between pt-4">
                        <div>
                          <p className="w-[97px] h-[22px] text-left font-normal text-[15px] leading-[22px] tracking-normal text-black opacity-100">
                            {
                              beneInternalTxnSuccessData?.transaction[0]
                                ?.beneficiary_account_ifsc_code
                            }
                          </p>
                          <span className="w-[54px] h-[17px] text-left font-normal text-[12px] leading-[17px] tracking-normal text-[#828282] opacity-100">
                            IFSC code
                          </span>
                        </div>
                        <div className="flex flex-row w-2/6 h-5 mt-3 justify-center">
                          <img
                            src={require("../../../assets/IfscLogo/" +
                              IfscCodeMapper(
                                beneInternalTxnSuccessData?.transaction[0]
                                  ?.beneficiary_account_ifsc_code
                              ))}
                            alt="bank"
                          />
                        </div>
                      </div>
                      <div className="mr-[30px] mt-[12px] h-[2.5px] bg-[#DADADA]"></div>
                      {beneInternalTxnSuccessData?.transaction[0]
                        .lifecycle_status === "pending" ? (
                        <div className="flex justify-between pt-4">
                          <div>
                            <p className="w-[97px] h-[22px] text-left font-normal text-[15px] leading-[22px] tracking-normal text-black opacity-100">
                              Pending
                            </p>
                            <span className="w-[54px] h-[17px] text-left font-normal text-[12px] leading-[17px] tracking-normal text-[#828282] opacity-100">
                              Transaction status
                            </span>
                          </div>
                          <div className="flex flex-row w-2/6 h-[30px] mt-3 justify-center">
                            <img src={pending_yellow} alt="pending" />
                          </div>
                        </div>
                      ) : (
                        <></>
                      )}
                    </div>
                  </div>
                  <div className="w-full divide-x-2  cursor-pointer text-white rounded-4xl bg-[#55985C] flex justify-evenly items-center p-4">
                    <div
                      className="flex flex-col space-y-1 w-2/6 h-3/4  items-center justify-center text-center "
                      onClick={createAccountHandler}
                    >
                      <div className="w-3/6 flex justify-center">
                        <img
                          width={"60%"}
                          height={"60%"}
                          src={createTransaction}
                          alt=""
                          srcSet=""
                        />
                      </div>
                      <p className="text-xs">
                        Create
                        <br />
                        transaction
                      </p>
                    </div>
                    <div
                      className="flex flex-col w-2/6 h-3/4 items-center justify-center text-center "
                      onClick={viewDetailsHandler}
                    >
                      <div className="w-3/6 flex justify-center">
                        <img
                          width={"60%"}
                          height={"60%"}
                          src={viewDetails}
                          alt=""
                          srcSet=""
                        />
                      </div>
                      <p className="text-xs">
                        View
                        <br />
                        details
                      </p>
                    </div>
                    <div
                      onClick={dashboardHandler}
                      className="flex flex-col space-y-1 w-2/6 h-3/4  items-center justify-center text-center  "
                    >
                      <div className="w-3/6 flex justify-center">
                        <img
                          width={"60%"}
                          height={"60%"}
                          src={dashboard}
                          alt=""
                          srcSet=""
                        />
                      </div>
                      <p className="text-xs">
                        Go to <br /> dashboard
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      {isMobileView && (
        <div className=" bg-[#457549] ">
          <div className="flex flex-col max-w-screen-lg justify-center">
            <div className="flex flex-col justify-center items-center pt-[28px] pb-[10px] h-auto lg:h-[281px]">
              <div className="w-full">
                <img src={successImage} alt="Transaction Success" />
              </div>
              <p className="text-lg py-6 text-white">
                {beneInternalTxnSuccessData?.transaction[0]
                  .lifecycle_status === "settled"
                  ? "Internal transaction settled successfully!"
                  : beneInternalTxnSuccessData?.transaction[0]
                    .lifecycle_status === "pending"
                    ? "Internal transaction created successfully and pending."
                    : beneInternalTxnSuccessData?.transaction[0]
                      .lifecycle_status === "approved"
                      ? <>
                        <p className="txnMessage_top">
                          Internal transaction has been created and approved.
                        </p>
                        <p className="txnMessage_bottom">
                          It will be auto settled once sufficient balance is
                          available in the source account.
                        </p>
                      </>
                      : ""}
              </p>
            </div>
            <div className="w-full bg-white pb-[92px]">
              <div className="w-full flex flex-col gap-1 pr-17px pl-17px pb-2">
                <div className="flex flex-col font-bold pt-5 pb-3 px-7">
                  <div className="text-bold text-2xl text-[#27B882] w-full text-center pb-2">
                    <p
                      className="w-full"
                      style={{
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        whiteSpace: " nowrap",
                        textTransform: "capitalize",
                      }}
                    >
                      ₹
                      <FormatAmount
                        price={
                          beneInternalTxnSuccessData?.transaction[0]?.amount ==
                            undefined
                            ? 0
                            : beneInternalTxnSuccessData?.transaction[0]?.amount
                        }
                      />
                    </p>
                  </div>
                </div>

                <div className="px-7 w-full content-center">
                  <div className="mx-0 dashedLine"></div>
                </div>
                <div className="successDetailText"></div>

                <div className="pl-[30px]">
                  <div>
                    <p className="text-left font-normal text-[15px] leading-[22px] tracking-normal text-black opacity-100">
                      {formatAccountNumber(
                        beneInternalTxnSuccessData?.transaction[0]
                          ?.owner_account_bank_acc_no
                      )}
                    </p>
                    <span className="w-[134px] text-left font-normal text-[12px] leading-[17px] tracking-normal text-[#828282] opacity-100">
                      Source account number
                    </span>
                  </div>
                  <div className="flex justify-between pt-4">
                    <div>
                      <p className="w-[97px] h-[22px] text-left font-normal text-[15px] leading-[22px] tracking-normal text-black opacity-100">
                        {
                          beneInternalTxnSuccessData?.transaction[0]
                            ?.owner_account_ifsc_code
                        }
                      </p>
                      <span className="w-[54px] h-[17px] text-left font-normal text-[12px] leading-[17px] tracking-normal text-[#828282] opacity-100">
                        IFSC code
                      </span>
                    </div>
                    <div className="flex flex-row justify-end w-2/6 h-5 mt-3 mr-8">
                      <img
                        src={require("../../../assets/IfscLogo/" +
                          IfscCodeMapper(
                            beneInternalTxnSuccessData?.transaction[0]
                              ?.owner_account_ifsc_code
                          ))}
                        alt="bank"
                      />
                    </div>
                  </div>
                </div>

                <div className="flex justify-between pl-[30px] pr-[30px] pt-2 translate-y-[5px]">
                  <div className="w-[40vw] h-0 border border-[#A0A0A0] opacity-100"></div>
                  <div className="w-[20px] h-[20px] relative top-[-10px]">
                    <img className="rotate-90" src={ArrowDown} alt="" />
                  </div>
                  <div className="w-[40vw] h-0 border border-[#A0A0A0] opacity-100"></div>
                </div>

                <div className="pl-[30px]">
                  <div>
                    <p className="text-left font-normal text-[15px] leading-[22px] tracking-normal text-black opacity-100">
                      {formatAccountNumber(
                        beneInternalTxnSuccessData?.transaction[0]
                          ?.beneficiary_account_bank_acc_no
                      )}
                    </p>
                    <span className="w-[134px] text-left font-normal text-[12px] leading-[17px] tracking-normal text-[#828282] opacity-100">
                      Destination account number
                    </span>
                  </div>
                  <div className="flex justify-between pt-4">
                    <div>
                      <p className="w-[97px] h-[22px] text-left font-normal text-[15px] leading-[22px] tracking-normal text-black opacity-100">
                        {
                          beneInternalTxnSuccessData?.transaction[0]
                            ?.beneficiary_account_ifsc_code
                        }
                      </p>
                      <span className="w-[54px] h-[17px] text-left font-normal text-[12px] leading-[17px] tracking-normal text-[#828282] opacity-100">
                        IFSC code
                      </span>
                    </div>
                    <div className="flex flex-row justify-end w-2/6 h-5 mt-3 mr-8">
                      <img
                        src={require("../../../assets/IfscLogo/" +
                          IfscCodeMapper(
                            beneInternalTxnSuccessData?.transaction[0]
                              ?.beneficiary_account_ifsc_code
                          ))}
                        alt="bank"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="w-full divide-x-2  cursor-pointer text-white rounded-t-lg bg-[#55985C] flex justify-evenly items-center p-4 bottom-0 fixed">
              <div
                className="flex flex-col space-y-1 w-2/6 h-3/4  items-center justify-center text-center "
                onClick={createAccountHandler}
              >
                <div className="w-3/6 flex justify-center">
                  <img
                    width={"60%"}
                    height={"60%"}
                    src={createTransaction}
                    alt=""
                    srcSet=""
                  />
                </div>
                <p className="text-xs">
                  Create
                  <br />
                  transaction
                </p>
              </div>
              <div
                className="flex flex-col w-2/6 h-3/4 items-center justify-center text-center "
                onClick={viewDetailsHandler}
              >
                <div className="w-3/6 flex justify-center">
                  <img
                    width={"60%"}
                    height={"60%"}
                    src={viewDetails}
                    alt=""
                    srcSet=""
                  />
                </div>
                <p className="text-xs">
                  View
                  <br />
                  details
                </p>
              </div>
              <div
                onClick={dashboardHandler}
                className="flex flex-col space-y-1 w-2/6 h-3/4  items-center justify-center text-center  "
              >
                <div className="w-3/6 flex justify-center">
                  <img
                    width={"60%"}
                    height={"60%"}
                    src={dashboard}
                    alt=""
                    srcSet=""
                  />
                </div>
                <p className="text-xs">
                  Go to <br /> dashboard
                </p>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default InternalTxnSuccessPage;
