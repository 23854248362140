import React, { useState, useRef, useEffect } from "react";
import "./index.css";
import { Button } from "@mui/material";
import cancel from "../../../assets/CloseIcons/close_with_white_circle.svg";
import search from "../../../assets/FilterSearchIcons/search_white.svg";
import enter_ifsc from "../../../assets/Graphics/Enter in search bar, to fetch.svg";
import dataLoading from "../../../assets/LoaderIcons/Loader.gif";
import notFound from "../../../assets/Graphics/No transaction found.svg";
import { checkUsertoken } from "../../../redux/features/login/login";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { toast_position } from "../../../redux/features/Settings/Authorization";
import { beneVerifyThisGST } from "../../../redux/features/beneficiary/beneAllVerification/beneAllVerification";
import { useLocation, useNavigate } from "react-router-dom";
import SettingGSTDetails from "./GSTPage";
import { resetSelectedGst } from "../../../redux/features/verifyPAN/verifyPAN";
import Validation from "../../../utils/validation";

const GSTVerification = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const inputRef = useRef("");
  const [gstNo, setGstNo] = useState("");
  const [gstLoading, setGstLoading] = useState(false);
  const [gstNotFound, setGstNotFound] = useState(false);
  const [showGstDetails, SetShowGstDetails] = useState(false);
  const services = useSelector((state) => state.refreshToken.services);
  const { state } = useLocation();

  const handleGST = (e) => {
    const GstValue = e.target.value.toUpperCase();
    const updateData = GstValue.replace(/[^A-Z0-9]/g, "");
    if (updateData.length <= 15) {
      setGstNo(updateData);
    }
    if (updateData.length == 0) {
      setGstLoading(false);
      setGstNotFound(false);
      SetShowGstDetails(false);
    }
    const cursorPosition = e.target.selectionStart;
    setTimeout(() => {
      e.target.focus();
      e.target.setSelectionRange(cursorPosition, cursorPosition);
    }, 0);
  };

  const { selectedGstData } = useSelector((state) => state.verify);

  useEffect(() => {
    if (services && !services?.includes("VGSTIN")) {
      navigate("/feature_not_assigned");
    }
    (async () => {
      if (state !== null) {
        setGstNo(`${state}`);
        // can't directly call handleGstFetch() here, state update won't be reflected until render completes. uhhhh
        await handleArbitraryGstFetch(state);
      }
    })();
    if (selectedGstData) {
      SetShowGstDetails(true);
      setGstNo(selectedGstData);
    }
    return () => {
      dispatch(resetSelectedGst());
    };
  }, []);

  const handleGstFetch = async () => {
    setGstLoading(true);
    if (!Validation.validateGstWithRegex(gstNo)) {
      toast.error("Invalid GSTIN!", toast_position);
      setGstLoading(false);
      setGstNotFound(true);
    } else {
      dispatch(checkUsertoken());
      const response = await dispatch(
        beneVerifyThisGST({ search_string: gstNo })
      );
      if (response?.payload?.status === true) {
        SetShowGstDetails(true);
        setGstLoading(false);
        setGstNotFound(false);
      } else if (response?.payload?.status === false) {
        setGstNotFound(true);
        setGstLoading(false);
        toast.error(`${response?.payload?.errors?.base[0]}`, toast_position);
      }
    }
  };

  const handleArbitraryGstFetch = async (gstNo) => {
    setGstLoading(true);
    if (!Validation.validateGstWithRegex(gstNo)) {
      toast.error("Invalid GSTIN!", toast_position);
      setGstLoading(false);
      setGstNotFound(true);
    } else {
      dispatch(checkUsertoken());
      const response = await dispatch(
        beneVerifyThisGST({ search_string: gstNo })
      );
      if (response?.payload?.status === true) {
        SetShowGstDetails(true);
        setGstLoading(false);
        setGstNotFound(false);
      } else if (response?.payload?.status === false) {
        setGstNotFound(true);
        setGstLoading(false);
        toast.error(`${response?.payload?.errors?.base[0]}`, toast_position);
      }
    }
  };
  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      handleGstFetch();
    }
  };

  useEffect(() => {
    document.addEventListener("keydown", handleKeyDown);
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [gstNo]);

  const shouldSearchBarBeHidden = selectedGstData !== null || state !== null;

  const gstSearchDiv = (
    <div className="gst-search">
      <div className="gst-search-1024">
        <div className="gst-input-div">
          <input
            ref={inputRef}
            value={gstNo}
            onChange={handleGST}
            placeholder="Enter GST number"
          />
          {gstNo.length === 0 ? (
            <img
              alt="search"
              src={search}
              onClick={() => inputRef.current.focus()}
            />
          ) : (
            <img
              alt="cancel"
              src={cancel}
              onClick={() => {
                setGstNo("");
                setGstLoading(false);
                setGstNotFound(false);
                SetShowGstDetails(false);
              }}
            />
          )}
        </div>
        <Button
          className="gst-fetch-button"
          disabled={gstNo.length !== 15}
          onClick={handleGstFetch}
          style={{
            color: gstNo.length === 15 ? "#1d3a6d" : "#F9F9F9",
            background: gstNo.length === 15 ? "#f9f9f9" : "#707070",
          }}
        >
          Fetch Details
        </Button>
      </div>
    </div>
  );
  return (
    <div className="gst-main-div">
      {shouldSearchBarBeHidden ? <></> : gstSearchDiv}
      {gstLoading ? (
        <>
          <div className="gst-loading-loader">
            <img src={dataLoading} alt="dataLoading" />
          </div>
        </>
      ) : gstNotFound ? (
        <>
          <div className="gst-not-found">
            <img src={notFound} alt="notfound" />
            <p>
              No data found.
              <br /> Please check the GST number entered above.
            </p>
          </div>
        </>
      ) : showGstDetails ? (
        <>
          <SettingGSTDetails gstNo={gstNo} setGstNotFound={setGstNotFound} hideSearch={shouldSearchBarBeHidden} />
        </>
      ) : (
        <>
          <div className="gst-default-img">
            <img src={enter_ifsc} alt="bgDefault" />
            <p>
              Enter GST number in the search bar, to fetch the verified GST
              details.
            </p>
          </div>
        </>
      )}
    </div>
  );
};

export default GSTVerification;
