import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Button, Checkbox, Tooltip, tooltipClasses } from "@mui/material";
import SelectUser from "../../../assets/CreateTransactionIcons/Select account.png";
import EditAccount from "../../../assets/CreateTransactionIcons/editAccount.png";
import BeneTxnAccountModal from "../../BeneTransaction/BeneTxnAccount/BeneTxnAccountModal";
import { useDispatch, useSelector } from "react-redux";
import "./index.css";
import { beneLookUp } from "../../../redux/features/verifyPAN/verifyPAN";
import {
  beneBankLookUp,
  resetViewAllAcc,
} from "../../../redux/features/beneficiary/beneAllVerification/beneAllVerification";
import { formatAccountNumber } from "../../_utils/FormatAccountNumber";
import { checkUsertoken } from "../../../redux/features/login/login";
import IfscCodeMapper from "../../_utils/IfscLogoMapper";
import OwnerAccountPopUp from "../../BeneTransaction/OwnerAccountPopUp";
import { toast_position } from "../../../redux/features/Settings/Authorization";
import { toast } from "react-toastify";

const InternalTxnSelectAccount = (props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [beneAccountId, setBeneAccountId] = useState(
    props.txnPayLoad?.beneAccountId ? props.txnPayLoad?.beneAccountId : null
  );
  const [accountName, setAccountName] = useState(
    props.txnPayLoad?.bankAccountName ? props.txnPayLoad?.bankAccountName : ""
  );
  const [accountNumber, setAccountNumber] = useState(
    props.txnPayLoad?.bankAccountNo ? props.txnPayLoad?.bankAccountNo : ""
  );
  const [panNumber, setPanNumber] = useState(
    props.txnPayLoad?.pan ? props.txnPayLoad?.pan : null
  );
  const [IFSC, setIFSC] = useState(
    props.txnPayLoad?.bankAccountIFSC ? props.txnPayLoad?.bankAccountIFSC : ""
  );
  const [bankLogo, setBankLogo] = useState(
    props.txnPayLoad?.bankLogo ? props.txnPayLoad?.bankLogo : ""
  );
  const [category, setCategory] = useState(
    props.txnPayLoad?.categories ? props.txnPayLoad?.categories?.split(",") : []
  );

  const [selectedCategory, setSelectedCategory] = useState(null);
  const [haveBankAssociate, setHaveBankAssociate] = useState(0);
  const [havePANAssociate, setHavePANAssociate] = useState(0);

  const unassignCategories = props.categories;

  const { beneLookData } = useSelector((state) => state.verify);

  const bankData = useSelector(
    (state) => state.beneAllVerification.beneLookData
  );
  const { beneViewAllAccLoading, beneViewAllAccData, beneViewAllAccError } =
    useSelector((state) => state.beneAllVerification);
  const { isTransCateLoading, beneTransCategories, isTransCateError } =
    useSelector((state) => state.beneAllVerification);

  useEffect(() => {
    if (props?.activeStep === 0 && props?.selectedCategory === null) {
      props?.BenEnableNextButton(true);
    } else if (props?.activeStep === 0 && props?.selectedCategory !== null) {
      props?.BenEnableNextButton(false);
    }
  }, [props?.activeStep]);

  const handleUnassignNavigate = (id) => {
    navigate(`/accounts/beneficiary_account/edit/categories/${id}`);
  };

  const handleOpenModal = () => {
    props.setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    props.setIsModalOpen(false);
  };

  useEffect(() => {
    setHaveBankAssociate(bankData?.total_count || 0);
    setHavePANAssociate(beneLookData?.total_count || 0);
  }, [beneLookData, bankData, dispatch]);

  const handleAccountSelection = (
    accountName,
    accountNumber,
    ifsc,
    bankLogo,
    category,
    panNo,
    beneAccountId
  ) => {
    const categoriesArray = category?.split(",");
    setCategory(categoriesArray);
    setAccountName(accountName);
    setAccountNumber(accountNumber);
    setIFSC(ifsc);
    setBankLogo(bankLogo);
    setBeneAccountId(beneAccountId);
    setPanNumber(panNo);
    setSelectedCategory(null);
    dispatch(checkUsertoken());
    dispatch(beneLookUp({ pan_number: panNo }));
    dispatch(
      beneBankLookUp({
        ifsc: ifsc,
        bank_account_no: accountNumber,
      })
    );

    props.setTxnPayLoad({
      ...props.txnPayLoad,
      bankAccountName: accountName,
      beneAccountId: beneAccountId,
      bankAccountIFSC: ifsc,
      bankAccountNo: accountNumber,
      bankLogo: bankLogo,
      pan: panNo,
      selectedCategory: beneTransCategories?.categories?.filter((fil) => {
        return fil.name === "Internal";
      })[0],
      mergeCategory: beneTransCategories?.categories?.filter((fil) => {
        return fil.name === "Internal";
      })[0]?.configuration?.data,
    });
    handleCloseModal();
  };

  const handleArrowClick = () => {
    props.setAssociateModalOpen(true);
  };

  const handleAssociateCloseModal = () => {
    props.setAssociateModalOpen(false);
  };

  useEffect(() => {
    if (
      !beneViewAllAccLoading &&
      beneViewAllAccData?.beneficiary_accounts.length == 1
    ) {
      handleAccountSelection(
        beneViewAllAccData?.beneficiary_accounts[0]?.name,
        beneViewAllAccData?.beneficiary_accounts[0]?.bank_acc_no,
        beneViewAllAccData?.beneficiary_accounts[0]?.ifsc_code,
        beneViewAllAccData?.beneficiary_accounts[0]?.bank_name,
        beneViewAllAccData?.beneficiary_accounts[0]?.category,
        beneViewAllAccData?.beneficiary_accounts[0]?.pan_no,
        beneViewAllAccData?.beneficiary_accounts[0]?.id
      );
    }
    if (
      beneViewAllAccData?.beneficiary_accounts?.length === 0 &&
      beneViewAllAccData?.total_count === 0
    ) {
      toast.error("Invalid Destination Account", toast_position);
    }
  }, [beneViewAllAccData, beneViewAllAccLoading, beneViewAllAccError]);

  return (
    <>
      <div className="beneContact_Main">
        <div className="dividecontact">
          <div
            className="w-1/2 h-fit contactright"
            // style={{ border: "2px solid yellow" }}
          >
            <div className="bg-[#CE720F] flex flex-col relative rounded-[30px]">
              <div
                className="bg-[#29487E] flex flex-col relative rounded-[30px]"
                // style={{ border: "2px solid red" }}
              >
                <div className="py-5 h-full  flex flex-col rounded-[30px] bg-[#506994] ">
                  <div className="w-full flex flex-col px-8 gap-7">
                    <div className="flex items-center justify-between">
                      <div className="text-[#DADADA] font-semibold text-base">
                        {props?.title}
                      </div>
                    </div>
                    <Button
                      className="w-full antialiased text-[16px]"
                      size="large"
                      sx={{
                        backgroundColor: "#2A487E",
                        color: "#FF7F33",
                        "&:hover": {
                          backgroundColor: "#2A487E",
                        },
                        textTransform: "none !important",
                        borderRadius: "10px",
                        display: "flex",
                        alignItems: "center",
                        fontFamily: "Open Sans",
                        fontWeight: "600",
                      }}
                      onClick={handleOpenModal}
                    >
                      {accountName ? (
                        <>
                          <Tooltip
                            title={accountName}
                            arrow
                            placement="bottom"
                            componentsProps={{
                              popper: {
                                sx: {
                                  [`& .${tooltipClasses.tooltip}`]: {
                                    backgroundColor: "black",
                                  },
                                  [`& .${tooltipClasses.arrow}`]: {
                                    color: "black",
                                  },
                                },
                              },
                            }}
                          >
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                overflow: "hidden",
                              }}
                            >
                              <span
                                style={{
                                  flex: "1",
                                  overflow: "hidden",
                                  textOverflow: "ellipsis",
                                  whiteSpace: "nowrap",
                                  color: " #FF7F33",
                                  fontSize: "20px",
                                  textAlign: "left",
                                  font: " normal normal 600 16px/22px Open Sans",
                                  letterSpacing: " 0px",
                                  textTransform: "capitalize",
                                  opacity: "1",
                                }}
                              >
                                {accountName?.toLowerCase()}
                              </span>
                              <img
                                src={EditAccount}
                                alt="edit account"
                                style={{
                                  width: "15px",
                                  height: "15px",
                                  marginLeft: "10px",
                                }}
                              />
                            </div>
                          </Tooltip>
                        </>
                      ) : (
                        <>
                          <img
                            src={SelectUser}
                            alt="select user"
                            style={{
                              width: "20px",
                              height: "20px",
                              marginRight: "10px",
                            }}
                          />
                          Select a destination account
                        </>
                      )}
                    </Button>

                    {props.isModalOpen && (
                      // <BeneTxnAccountModal
                      //   onClose={handleCloseModal}
                      //   onAccountSelect={handleAccountSelection}
                      //   accountNumber={accountNumber}
                      //   IFSC={IFSC}
                      //   category={category}
                      //   bankLogo={bankLogo}
                      //   setTxnPayLoad={props.setTxnPayLoad}
                      //   txnPayLoad={props.txnPayLoad}
                      //   accountModalSearch={props.accountModalSearch}
                      //   setAccountModalSearch={props.setAccountModalSearch}
                      //   filterPayload={props.filterPayload}
                      //   setFilterPayload={props.setFilterPayload}
                      // />
                      <OwnerAccountPopUp
                        setOpenOwner={props.setIsModalOpen}
                        setIsNeft={props?.setIsNeft}
                        setIsImps={props?.setIsImps}
                        setIsRtgs={props?.setIsRtgs}
                        txnPayLoad={props?.txnPayLoad}
                        setTxnPayLoad={props?.setTxnPayLoad}
                        BenEnableNextButton={props?.BenEnableNextButton}
                        flow={"internalTxn"}
                      />
                    )}
                  </div>
                </div>

                {/* ------------------- */}
                <div className="-mx-2">
                  <div className="w-full flex flex-col px-4 gap-6">
                    {accountNumber && IFSC && (
                      <div className="rounded-l-large flex w-full p-2 flex-col justify-center items-start">
                        <div className="flex px-4 flex-row justify-end">
                          <div className="antialiased text-[#F9F9F9] text-xs">
                            <p className="text-base">
                              {formatAccountNumber(accountNumber)}
                            </p>
                            <p className="pt-1 pb-2 text-xs text-[#dadada]">
                              Account number{" "}
                            </p>
                            <hr
                              style={{
                                width: "410px",
                                border: "1px solid #1E3A6D",
                              }}
                            />
                            <p className="pt-2 pb-1 text-base">{IFSC}</p>
                            <p className="text-xs text-[#dadada]">IFSC code</p>

                            <div className="flex items-center justify-between ml-[314px] w-32 h-[41px] mt-[-25px]">
                              <div className="bg-[#ffffff] rounded-tl-[30px] rounded-br-[30px] flex items-center justify-around w-full h-full mt-[16px] mr-[2px]">
                                {
                                  <img
                                    src={require("../../../assets/IfscLogo/" +
                                      IfscCodeMapper(IFSC))}
                                    alt="bank"
                                    className="w-[70px] h-[18px]"
                                  />
                                }
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="dividecontact invisible">
          <div
            className="w-1/2 contactright"
            // style={{ border: "2px solid green" }}
          >
            <div
              className="bg-[#506994] flex flex-col gap-5 pt-[20px] pl-[30px] relative rounded-[32px] h-[175px]"
              // style={{ border: "2px solid red" }}
            >
              <h4 className="w-[241px] text-left font-semibold text-[16px] leading-[22px] font-open-sans tracking-normal text-[#F9F9F9] opacity-100">
                Set Up Auto Transfer (Optional)
              </h4>
              <p className="w-[387px] h-[34px] text-left font-normal text-[12px] leading-[17px] font-open-sans tracking-normal text-[#DADADA] opacity-100">
                Auto transfer allows you to schedule the transaction for a
                single time or set it up to repeat at your chosen frequency.
              </p>
              <button
                className="w-[412px] flex  justify-around items-center h-[39px] bg-[#2A487E] rounded-[10px] opacity-100"
                onClick={() => {
                  toast.info("Feature coming soon", toast_position);
                }}
              >
                <p className="text-left font-normal text-[14px] leading-[19px] font-open-sans tracking-normal text-white opacity-100">
                  Click here to set up auto transfer
                </p>
                <div className="w-[18px] h-[18px] flex items-center justify-center bg-[#1D3A6D] rounded-[2px]">
                  <img
                    src={require("../../../assets/Beneficiary Icons/arrowright.png")}
                    alt="Description"
                    className="w-[10px] h-[10px]"
                  />
                </div>
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default InternalTxnSelectAccount;
